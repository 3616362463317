import React, { Fragment, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { Divider, Grid, Typography, Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactDOMServer from 'react-dom/server';
import XLSX from 'xlsx';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import Workbook from "react-excel-workbook";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { isProductPackaged } from "../../CartMaterial/utils/isProductPackaged";
import { RenderPackage } from "./RenderPackage.tsx";
import { usePackagedProducts } from "../../CartMaterial/utils/packagedProducts";
import GetCookie from "../../Common/Functions/GetCookie.js";
import RenderItem from "./RenderItem";
import moment from "moment";

const useStyles = makeStyles({
    padding60: {
        padding: 60
    },
    marginTop20: {
        marginTop: 20
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    textAlightRight: {
        textAlign: "right"
    },
    bold: {
        fontWeight: "bold"
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            fontSize: 12
        }
    },
    csvButton: {
        "color": "#3f51b5 !important",
        "border": "1px solid rgba(63, 81, 181, 0.5)",
        "padding": "8px 13px",
        "fontFamily": "Roboto",
        "fontStyle": "normal",
        "borderRadius": 5,
        "cursor": "pointer",
        "&:hover": {
            border: "1px solid #3f51b5",
            backgroundColor: "rgba(63, 81, 181, 0.04)",
            textDecoration: "none"
        }
    },
    fontSize11: {
        fontSize: 11
    }
});
type Props = {
    exportData: [],
    exportDataCSV: []
}
const TripListFinanceTable = (props:Props) => {
    const { exportData, exportDataCSV } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));

    const packagedProducts = usePackagedProducts();
    const trip_info = useSelector(store => store.menu.trip_info);
    const user = useSelector(store => store.user.user);
    const providers = useSelector(store => store.trip.providers);
    const manual_providers = useSelector(store => store.trip.manual_providers);
    const list_currency = useSelector(store => store.base.currency_list);
    const currency = useSelector(store => store.trip.currency);
    const language = useSelector(store => store.header.tmp_language);
    const big_cart = useSelector(store => store.menu.big_cart);
    const trip_end_date = useSelector(store => store.trip.end_date);
    const manual_cart = useSelector(store => store.trip.manual_cart);
    const data_trip = useSelector(store => store.trip.data_trip);
    const finance_charges = useSelector(store => store.menu.finance_charges);
    const finance_purchase = useSelector(store => store.menu.finance_purchase);
    const owner_id = useState(user ? user.client : null);
    const quotation_code = JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code;

    const id = trip_info.trip_reference;
    // const columns = [
    //     t("global.date"),
    //     t("menu.trip_list.product"),
    //     t("filters.providers"),
    //     t("global.description"),
    //     t("menu.trip_list.reference"),
    //     t("cart.edit_booking.booking_status"),
    //     t("menu.trip_list.amount_purchase"),
    //     t("menu.trip_list.rate"),
    //     t("menu.trip_list.amount"),
    //     t("menu.trip_list.amount_selling")
    // ];
    let columns = [
        t("global.date"),
        t("menu.trip_list.product"),
        t("filters.providers"),
        t("global.description"),
        t("menu.trip_list.reference"),
        t("cart.edit_booking.booking_status"),
        // t("menu.trip_list.amount_purchase"),
        // t("menu.trip_list.amount_purchase_forecast"),
        // t("menu.trip_list.rate"),
        // t("menu.trip_list.amount_purchase_real"),
        // t("menu.trip_list.rate"),
        // t("menu.trip_list.amount"),
        // t("menu.trip_list.amount_selling")
    ];
    if (finance_charges.length !== 0) {
        columns.push(t("menu.trip_list.amount_purchase_forecast"));
        columns.push(t("menu.trip_list.rate"));
        columns.push(t("menu.trip_list.amount_purchase_forecast"));
        columns.push(t("menu.trip_list.amount_purchase_real"));
    } else {
        columns.push(t("menu.trip_list.amount_purchase"));
        columns.push(t("menu.trip_list.rate"));
        columns.push(t("menu.trip_list.amount"));
    }
    columns.push(t("menu.trip_list.amount_selling"));
    columns.push(t('menu.trip_list.is_paid'));

    const exportDataFile = () => {
        let price_column = [t("menu.file_nb"), t("global.date"), t("menu.trip_list.product"), t("cars.filter.provider"), t("global.description"), t("global.room"), t("menu.trip_list.reference"), t("cart.edit_booking.booking_status"), t("menu.trip_list.amount_purchase"), t("global.in"), t("menu.trip_list.rate"), t("menu.trip_list.amount"), t("global.in"), , t("menu.trip_list.amount_selling"), t("global.in")];
        let adults = 0;
        let children = 0;
        for (let i = 0; i < data_trip.travelers.length; i++) {
            if (moment.utc(trip_end_date).diff(moment.utc(data_trip.travelers[i].birth_date), "y") >= 12) {
                adults++;
            } else {
                children++;
            }
        }
        let tmp_data = (
            <table id="tableData">
                <tr>
                    <th>REFERENCE</th>
                    <th>DATE DEPART / REGION</th>
                    <th>AGENCE</th>
                    <th>NOMS CLIENTS</th>
                    <th>NB PAXS</th>
                    <th>VENDEUR</th>
                </tr>
                <tr>
                    <td>{trip_info.trip_reference}</td>
                    <td>{moment(data_trip.start_date).format("DD/MM/YYYY")}</td>
                    <td rowSpan={2}>{trip_info.target_client.name}</td>
                    <td rowSpan={2}>{data_trip.contact_traveler_detail.first_name + " " + data_trip.contact_traveler_detail.last_name}</td>
                    <td rowSpan={2}>
                        {
                            adults > 0 && (
                                <p>adt : {adults}</p>
                            )
                        }
                        {
                            children > 0 && (
                                <p>Enf - 12 ans : {children}</p>
                            )
                        }
                    </td>
                    <td rowSpan={2}>{trip_info.user_ta !== null ? trip_info.user_ta.first_name + " " + trip_info.user_ta.last_name : trip_info.user_to !== null ? trip_info.user_to.first_name + " " + trip_info.user_to.last_name : ''}</td>
                </tr>
                <tr>
                    <td>{trip_info.accounting_reference !== undefined && trip_info.accounting_reference !== null ? trip_info.accounting_reference : ""}</td>
                    <td>{data_trip.destination.data.name}</td>
                </tr>
                <tr>
                    <td colSpan={6}>{trip_info.name}</td>
                </tr>
                <tr/>
                <tr/>
                <tr>
                    {
                        price_column.map((column, index) => {
                            return (
                                <th key={index}>{column}</th>
                            );
                        })
                    }
                </tr>
                {
                    exportData.map((data, index) => {
                        return (
                            <tr key={index}>
                                <td>{data.id}</td>
                                <td>{data.date}</td>
                                <td>{data.product}</td>
                                <td>{data.provider}</td>
                                <td>{data.description}</td>
                                <td>{data.room_name}</td>
                                <td>{data.reference}</td>
                                <td>{data.booking_status}</td>
                                <td>{data.amount_1.replace(",", ".")}</td>
                                <td>{data.rate}</td>
                                <td>{data.amount_2.replace(",", ".")}</td>
                                <td>{data.amount_3.replace(",", ".")}</td>

                            </tr>
                        );
                    })
                }
                <tr/>
                <tr/>
                <tr>
                    <td colSpan={2}>SUIVI DOSSIER</td>
                    <td/>
                    <td>MEMO CV</td>
                    <td/>
                    <td colSpan={2}>REMARQUES</td>
                </tr>
                <tr>
                    <td>CONFIRMATION</td>
                    <td/>
                    <td/>
                    <td>ROADBOOK DIGITAL</td>
                    <td/>
                    <td rowSpan={13}/>
                </tr>
                <tr>
                    <td>ACHATS PGI</td>
                    <td/>
                    <td/>
                    <td>ROADBOOK PHHYSIQUE</td>
                    <td/>
                </tr>
                <tr>
                    <td>FACTURE PGI</td>
                    <td/>
                    <td/>
                    <td>VOLS</td>
                    <td/>
                </tr>
                <tr>
                    <td>SUIVI CO  PGI</td>
                    <td/>
                    <td/>
                    <td>VOUCHERS HOTELS</td>
                    <td/>
                </tr>
                <tr>
                    <td>APIS</td>
                    <td/>
                    <td/>
                    <td>VOUCHERS ACTIVITES</td>
                    <td/>
                </tr>
                <tr>
                    <td>ROBOT F4</td>
                    <td/>
                    <td/>
                    <td>VOITURE</td>
                    <td/>
                </tr>
                <tr>
                    <td>NUM CLIENT F5</td>
                    <td/>
                    <td/>
                    <td>ONSPOT</td>
                    <td/>
                </tr>
                <tr>
                    <td>DATE EMISSION</td>
                    <td/>
                    <td/>
                    <td>CARNET ACCOMPAGNT</td>
                    <td/>
                </tr>
                <tr>
                    <td>TABLEAU COTATIONS "OUI" :</td>
                    <td/>
                    <td/>
                    <td>GUIDES</td>
                    <td/>
                </tr>
                <tr>
                    <td>AJOUT D'EXCURSIONS</td>
                    <td/>
                    <td/>
                    <td>ETIQUETTES BAGAGES</td>
                    <td/>
                </tr>
                <tr>
                    <td>MISE A JOUR FT</td>
                    <td/>
                    <td/>
                    <td>GOODIES</td>
                    <td/>
                </tr>
                <tr>
                    <td>FACTURE COMPL. PGI</td>
                    <td/>
                    <td/>
                    <td>FICHIERS PGI + MAIL</td>
                    <td/>
                </tr>
            </table>
        );
        let workbook = XLSX.utils.book_new();
        let string_data = ReactDOMServer.renderToStaticMarkup(tmp_data);
        let template = document.createElement('template');
        string_data = string_data.trim();
        template.innerHTML = string_data;
        let dataToExport = template.content.firstChild;
        let ws1 = XLSX.utils.table_to_sheet(dataToExport);
        XLSX.utils.book_append_sheet(workbook, ws1, id.toString());
        XLSX.writeFile(workbook, id + " " + t("menu.trip_finance_file_name") + ".xlsx");
        recordExport(`${id} ${t("menu.trip_finance_file_name")}.xlsx`);
    };
    const recordExport = (name:string) => {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/add_export_historic/?additional_info=${name}`
            }).catch((error) => {
                console.log('error record export:', error);
            });
        }
    };
    return (
        <Fragment>
            <Divider/>
            <Grid container style={{ marginTop: 20 }}>
                <Grid item container direction={"row"} justifyContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <Typography variant={"h4"} className={classes.genericText} style={{ textAlign: "center" }}>{t("menu.trip_list.buy_detail")}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction={"row"} justifyContent={!downDesktop ? "flex-end" : "center"} alignItems={"center"} spacing={1} style={{ marginBottom: 20, marginTop: 10 }}>
                    {
                        exportDataCSV.length !== 0 && (
                            <Grid item>
                                <CSVLink
                                    data={exportDataCSV}
                                    filename={ id + " " + t("menu.trip_finance_file_name") + ".csv" }
                                    className={`${classes.csvButton} ${classes.fontSize11}`}
                                    target={"_blank"}
                                    onClick={() => recordExport(`${id} ${t("menu.trip_finance_file_name")}.csv`)}
                                >Csv</CSVLink>
                            </Grid>
                        )
                    }
                    {
                        quotation_code !== "visiteurs" && exportData.length !== 0 && (
                            <Grid item>
                                <Workbook
                                    filename={ id + " " + t("menu.trip_finance_file_name") + ".xlsx"}
                                    element={
                                        <a className={`${classes.csvButton} ${classes.fontSize11}`} onClick={() => recordExport(`${id} ${t("menu.trip_finance_file_name")}.xlsx`)}>Excel</a>
                                    }>
                                    
                                        {
                                            finance_charges.length !== 0 ?
                                            <Workbook.Sheet data={exportData} name={ id.toString() }>
                                                <Workbook.Column label={t("menu.file_nb")} value={"id"}/>
                                                <Workbook.Column label={t("global.date")} value={"date"}/>
                                                <Workbook.Column label={t("menu.trip_list.product")} value={"product"}/>
                                                <Workbook.Column label={t("filters.providers")} value={"provider"}/>
                                                <Workbook.Column label={t("global.description")} value={"description"}/>
                                                <Workbook.Column label={t("global.room")} value={"room_name"}/>
                                                <Workbook.Column label={t("menu.trip_list.reference")} value={"reference"}/>
                                                <Workbook.Column label={t("cart.edit_booking.booking_status")} value={"booking_status"}/>
                                                <Workbook.Column label={t("menu.trip_list.amount_purchase_forecast")} value={"amount_1"}/>
                                                <Workbook.Column label={t("menu.trip_list.rate")} value={"rate"}/>
                                                <Workbook.Column label={t("menu.trip_list.amount_purchase_forecast")} value={"amount_2"}/>
                                                <Workbook.Column label={t("menu.trip_list.amount_purchase_real")} value={"amount_4"}/>
                                                <Workbook.Column label={t("menu.trip_list.amount_selling")} value={"amount_3"}/>
                                            </Workbook.Sheet>
                                            :
                                            <Workbook.Sheet data={exportData} name={ id.toString() }>
                                                <Workbook.Column label={t("menu.file_nb")} value={"id"}/>
                                                <Workbook.Column label={t("global.date")} value={"date"}/>
                                                <Workbook.Column label={t("menu.trip_list.product")} value={"product"}/>
                                                <Workbook.Column label={t("filters.providers")} value={"provider"}/>
                                                <Workbook.Column label={t("global.description")} value={"description"}/>
                                                <Workbook.Column label={t("global.room")} value={"room_name"}/>
                                                <Workbook.Column label={t("menu.trip_list.reference")} value={"reference"}/>
                                                <Workbook.Column label={t("cart.edit_booking.booking_status")} value={"booking_status"}/>
                                                <Workbook.Column label={t("menu.trip_list.amount_purchase")} value={"amount_1"}/>
                                                <Workbook.Column label={t("menu.trip_list.rate")} value={"rate"}/>
                                                <Workbook.Column label={t("menu.trip_list.amount")} value={"amount_2"}/>
                                                <Workbook.Column label={t("menu.trip_list.amount_selling")} value={"amount_3"}/>
                                            </Workbook.Sheet>
                                        }
                                </Workbook>
                            </Grid>
                        )
                    }
                    {
                        quotation_code === "visiteurs" && exportData.length !== 0 && (
                            <Grid>
                                <a className={`${classes.csvButton} ${classes.fontSize11}`} onClick={exportDataFile}>Excel</a>
                            </Grid>
                        )
                    }
                </Grid>
                {
                    !downDesktop && (
                        <TableContainer component={Paper}>
                            <Table id={"buy-detail"} size="small" className={classes.genericText}>
                                <TableHead>
                                    <TableRow className={classes.paddingCell}>
                                        {
                                            columns.map((column, column_index) => {
                                                return (
                                                    <TableCell align={"left"} key={column_index}>
                                                        {column}
                                                    </TableCell>
                                                );
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        manual_cart &&
                                        <RenderPackage cart={packagedProducts}/>
                                    }
                                    {
                                        big_cart.map((item:any, item_index:number) => {
                                            if (!item.is_optional && !isProductPackaged({ product: item, stackInfos: data_trip?.stack_info ?? null })) {
                                                return (
                                                    <RenderItem
                                                        key={item_index}
                                                        item={item}
                                                        providers={providers}
                                                        manual_providers={manual_providers}
                                                        language={language}
                                                        list_currency={list_currency}
                                                        owner_id={owner_id}
                                                        user={user}
                                                        currency={currency}
                                                    />
                                                );
                                            }
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
            </Grid>
        </Fragment>
    );
};
export default TripListFinanceTable;
