const initial_state = {
    flight_groups: [
        {
            is_km: true,
            travelers: [],
            journey: [
                {
                    start_date: null,
                    origin: null,
                    destination: null,
                    class_type: 0
                },
                {
                    start_date: null,
                    origin: null,
                    destination: null,
                    class_type: 0
                }
            ],
            company: [],
            direct_flight: false,
            one_stop: false,
            private_price: (JSON.parse(localStorage.getItem("config")) !== null && JSON.parse(localStorage.getItem("config")).quotation_code === "visiteurs"),
            public_price_ndc: false,
            private_price_ndc: false,
            public_price: false,
            flexible_date: false,
            passenger_open: false,
            included_luggage: (JSON.parse(localStorage.getItem("config")) !== null && JSON.parse(localStorage.getItem("config")).quotation_code === "tropicalementvotre"),
            standard_price: false,
            ndc_price: false,
            journey_type: 1, //1: round trip, 2: multi destination, 3: one way, 4: manual
            error: [],
            input_focus: null,
            provider: null,
            manual_request: "",
            manual_luggage: null,
            is_honeymoon: false
        }
    ],
    previous_flight_groups: [],
    airport_list: [],
    provider_list: [],
    cart: [],
    cart_updated: false,
    prev_class: [],
    index_group: null,
    copy_index_group: null,
    redirection: null,
    update_trip_date: false,
    option_control: false,
    refresh: null,
    first_render: true,
    multi_dest_filled: false,
    open_date_pickers: [[]]
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.flight !== undefined) {
                Object.keys(action.payload.flight).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.flight[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initial_state;
            return new_state;
        }
        case "SMALL_FLUSH": {
            new_state = initial_state;
            return new_state;
        }
        case "FLIGHT_OPTION_CONTROL_RECALC": {
            new_state.option_control = action.payload;
            return new_state;
        }
        case "UPDATE_TRIP_DATES_FROM_FLIGHT": {
            new_state.update_trip_date = true;
            return new_state;
        }
        case "CLOSE_UPDATE_TRIP_DATES_FROM_FLIGHT": {
            new_state.update_trip_date = false;
            new_state.option_control = false;
            return new_state;
        }
        case "CHECK_TRIP_DATES_FROM_FLIGHT": {
            if (new_state.option_control) {
                new_state.update_trip_date = true;
            }
            return new_state;
        }

        case "FLIGHT_SET_GROUP_INDEX": {
            new_state.index_group = action.payload.index_group;
            return new_state;
        }
        case "FLIGHT_SET_GROUP_COPY_INDEX": {
            new_state.copy_index_group = action.payload.index_group;
            return new_state;
        }
        case "FLIGHT_SET_INPUT_FOCUS": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.input_focus = action.payload.focus;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_FLIGHT_GROUPS": {
            new_state.flight_groups = action.payload.flight_groups;
            return new_state;
        }
        case "FLIGHT_RESET_GROUP" : {
            let flight_groups = new_state.flight_groups.slice();
            new_state.flight_groups = new_state.flight_groups = [].concat([], action.payload.flight_group);
            return new_state;
        }
        case "FLIGHT_RESET_TRAVELERS" : {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_group = [];
            flight_groups.forEach((group) => {
                let elem = Object.assign({}, group);
                elem.travelers = [];
                copy_flight_group.push(elem);
            });
            new_state.flight_groups = copy_flight_group;
            return new_state;
        }
        case "FLIGHT_UPDATE_FLIGHT_GROUPS_AT_INDEX": {
            new_state.flight_groups = action.payload.flight_groups;
            return new_state;
        }
        case "FLIGHT_ADD_FLIGHT_GROUP": {
            new_state.flight_groups = [].concat(new_state.flight_groups, action.payload.flight_group);
            return new_state;
        }
        case "FLIGHT_ADD_FLIGHT_JOURNEY": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey = [].concat(copy_flight_group.journey, action.payload.journey);
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_MANUAL_JOURNEY": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.group_index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey = action.payload.journey;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_ADD_FLIGHT_JOURNEY_FROM_ITINERARY": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey = action.payload.journey;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SUB_FLIGHT_JOURNEY": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    //copy_flight_group.journey = copy_flight_group.journey.slice(0, action.payload.key);
                    copy_flight_group.journey = copy_flight_group.journey.filter((elem, index_elem) => {
                        return index_elem !== action.payload.key;
                    });
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_ONEWAY_JOURNEY": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey = copy_flight_group.journey.slice(0, 1);
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_ROUNDTRIP_JOURNEY": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey = copy_flight_group.journey.slice(0, 2);
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SWAP_MULTIDEST_JOURNEY": {
            const { drag_index, hover_index, group_index } = action.payload;

            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_group = Object.assign({}, flight_groups[group_index]);
            let copy_journeys = copy_flight_group.journey.slice();
            let temp_journey = copy_journeys[drag_index];
            //keep dates
            let old_date = copy_journeys[hover_index].start_date;
            let new_date = copy_journeys[drag_index].start_date;

            copy_journeys[drag_index] = copy_journeys[hover_index];
            copy_journeys[hover_index] = temp_journey;

            copy_journeys[hover_index].start_date = old_date;
            copy_journeys[drag_index].start_date = new_date;

            copy_flight_group.journey = copy_journeys;
            flight_groups[group_index] = copy_flight_group;
            new_state.flight_groups = flight_groups;
            return new_state;
        }
        case "FLIGHT_DELETE_FLIGHT_GROUPS_AT_INDEX" : {
            let flight_groups = new_state.flight_groups.slice();
            flight_groups = flight_groups.filter((elem, index_elem) => {
                return index_elem !== action.payload.index;
            });
            new_state.flight_groups = flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_FLIGHT_CART" : {
            new_state.cart = action.payload.flight;
            if (new_state.refresh !== null) {
                let cart = [...new_state.cart];
                Object.keys(new_state.refresh).map(key => {
                    for (let i = 0; i < cart.length; i++) {
                        if (cart[i].id === parseInt(key)) {
                            let item = { ...cart[i] };
                            item.price_change = new_state.refresh[key];
                            cart[i] = item;
                            break;
                        }
                    }
                });
                new_state.cart = cart;
            }
            return new_state;
        }
        case "FLIGHT_SET_MANUAL_MARGIN_EDIT" : {
            let cart = new_state.cart.slice();
            let copy_cart = [];
            cart.map((item, item_index) => {
                if (item_index === action.payload.index) {
                    let copy_item = Object.assign({}, item);
                    copy_item.edit_margin = action.payload.edit;
                    item = copy_item;
                }
                copy_cart.push(item);
            });
            new_state.cart = copy_cart;
            return new_state;
        }
        case "FLIGHT_ADD_TO_CART": {
            new_state.cart = [].concat(new_state.cart, action.payload.flight);
            return new_state;
        }
        case "FLIGHT_REPLACE_CART": {
            new_state.cart = new_state.cart.map((item) => {
                if (item.id === action.payload.from.id) {
                    return action.payload.to;
                }
                return item;
            });
            return new_state;
        }
        case 'FLIGHT_UPDATE_CART': {
            let cart = [...new_state.cart];
            for (let i = 0; i < cart.length; i++) {
                action.payload.map(item => {
                    item.map(new_flight => {
                        if (cart[i].id === new_flight.id) {
                            cart[i] = { ...new_flight };
                        }
                    });
                });
            }
            new_state.cart = cart;
            return new_state;
        }
        case "FLIGHT_EDIT_CART": {
            let cart = new_state.cart.slice();
            let copy_cart = [];
            cart.map((flight, flight_index) => {
                if (flight_index === action.payload.index) {
                    if (Array.isArray(action.payload.item) === true) {
                        flight = action.payload.item[0];
                    } else {
                        flight = action.payload.item;
                    }
                }
                copy_cart.push(flight);
            });
            new_state.cart = copy_cart;
            return new_state;
        }
        case "FLIGHT_EDIT_CART_BY_ID":{
            let cart = new_state.cart.slice();
            let copy_cart = [];

            cart.map((flight) => {
                if (flight.id === action.payload.id) {
                    copy_cart.push(action.payload);
                } else {
                    copy_cart.push(flight);
                }
            });
            new_state.cart = copy_cart;
            return new_state;
        }
        case "FLIGHT_EDIT_STATUS": {
            let cart = new_state.cart.slice();
            let copy_cart = [];

            cart.map((flight) => {
                if (flight.id === action.payload.data.id) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.booking_status = action.payload.data.booking_status;
                    flight = copy_flight;
                }
                copy_cart.push(flight);
            });
            new_state.cart = copy_cart;
            return new_state;
        }
        case "FLIGHT_REMOVE_FLIGHT_FROM_CART": {
            let copy_cart = new_state.cart.slice();
            copy_cart.splice(action.payload.index, 1);
            new_state.cart = copy_cart;
            return new_state;
        }
        case "FLIGHT_REMOVE_FLIGHT_FROM_CART_BY_ID": {
            let copy_cart = new_state.cart.slice();
            let index = null;
            for (let i = 0; i < copy_cart.length; i++) {
                if (copy_cart[i].id === action.payload) {
                    index = i;
                }
            }
            if (index !== null) {
                copy_cart.splice(index, 1);
            }
            new_state.cart = copy_cart;
            return new_state;
        }
        case "FLIGHT_SET_JOURNEY_TYPE" : {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    if (action.payload.type === 3) {
                        copy_flight_group.journey = [{ ...copy_flight_group.journey[0] }];
                    }
                    copy_flight_group.journey_type = action.payload.type;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_AIRPORT_LIST": {
            new_state.airport_list = action.payload.airport_list;
            return new_state;
        }
        case "FLIGHT_SET_ONEWAY_ORIGIN_DATE": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            let copy_journeys = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey.map((journey, index_journey) => {
                        if (index_journey === 0) {
                            let copy_journey = Object.assign({}, journey);
                            copy_journey.start_date = action.payload.date;
                            journey = copy_journey;
                        }
                        copy_journeys.push(journey);
                    });
                    copy_flight_group.journey = copy_journeys;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_RESTART_SEARCH": {
            return new_state;
        }
        case "FLIGHT_SET_ROUNDTRIP_RETURN_DATE": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            let copy_journeys = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey.map((journey, index_journey) => {
                        if (index_journey === 1) {
                            let copy_journey = Object.assign({}, journey);
                            copy_journey.start_date = action.payload.date;
                            journey = copy_journey;
                        }
                        copy_journeys.push(journey);
                    });
                    copy_flight_group.journey = copy_journeys;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_MULTIDEST_START_DATE": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            let copy_journeys = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey.map((journey, index_journey) => {
                        if (index_journey === action.payload.key) {
                            let copy_journey = Object.assign({}, journey);
                            copy_journey.start_date = action.payload.date;
                            journey = copy_journey;
                        }
                        copy_journeys.push(journey);
                    });
                    copy_flight_group.journey = copy_journeys;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_ONEWAY_CLASS": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            let copy_journeys = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey.map((journey, index_journey) => {
                        if (index_journey === 0) {
                            let copy_journey = Object.assign({}, journey);
                            copy_journey.class_type = action.payload.value;
                            journey = copy_journey;
                        }
                        copy_journeys.push(journey);
                    });
                    copy_flight_group.journey = copy_journeys;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_ROUNDTRIP_CLASS": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            let copy_journeys = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey.map((journey, index_journey) => {
                        if (index_journey === 1) {
                            let copy_journey = Object.assign({}, journey);
                            copy_journey.class_type = action.payload.value;
                            journey = copy_journey;
                        }
                        copy_journeys.push(journey);
                    });
                    copy_flight_group.journey = copy_journeys;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_MULTIDEST_CLASS": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            let copy_journeys = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey.map((journey, index_journey) => {
                        if (index_journey === action.payload.key) {
                            let copy_journey = Object.assign({}, journey);
                            copy_journey.class_type = action.payload.value;
                            journey = copy_journey;
                        }
                        copy_journeys.push(journey);
                    });
                    copy_flight_group.journey = copy_journeys;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_GROUPS_CLASS_TYPE": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_journeys = [];
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index_group) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey.map((journey, index_journey) => {
                        if (index_journey === action.payload.index_journey) {
                            let copy_journey = Object.assign({}, journey);
                            copy_journey.class_type = action.payload.class_type;
                            journey = copy_journey;
                        }
                        copy_journeys.push(journey);
                    });
                    copy_flight_group.journey = copy_journeys;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_PREV_CLASS": {
            new_state.prev_class = action.payload.class_type;
            return new_state;
        }
        case "FLIGHT_CLEAR_CLASS": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_journeys = [];
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index_group) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey.map((journey, index_journey) => {
                        let copy_journey = Object.assign({}, journey);
                        copy_journey.class_type = new_state.prev_class[index_journey];
                        journey = copy_journey;
                        copy_journeys.push(journey);
                    });
                    copy_flight_group.journey = copy_journeys;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_TOOGLE_PASSENGER": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.passenger_open = action.payload.value;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_DIRECT_FLIGHT": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.direct_flight = action.payload.value.direct_flight;
                    copy_flight_group.one_stop = action.payload.value.one_stop;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_TOGGLE_INCLUDED_LUGGAGE": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.flight_index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    console.log('copy_flight_group:', copy_flight_group);
                    copy_flight_group.included_luggage = action.payload.included_luggage;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_PRIVATE_PRICE": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.private_price = action.payload.private_price;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_PUBLIC_PRICE": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.public_price = action.payload.public_price;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_FLEXIBLE_DATE": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.flexible_date = action.payload.value;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SPLICE_PASSENGER_LIST": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.travelers = copy_flight_group.travelers.filter((elem) => {
                        return elem.id !== action.payload.passenger_id;
                    });
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_TRAVELERS_LIST": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.travelers = [].concat(copy_flight_group.travelers, action.payload.travelers);
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_PUT_TRAVELERS_LIST": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.travelers = action.payload.travelers;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_ERROR": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.error = [].concat(copy_flight_group.error, action.payload.error);
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_REMOVE_ERROR": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.error = copy_flight_group.error.filter((elem) => {
                        return elem !== action.payload.error;
                    });
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_RESET_ERROR": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.error = copy_flight_group.error.filter((elem) => {
                        return elem === "";
                    });
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_AIRPORT_JOURNEY": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            let copy_journeys = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey.map((journey, index_journey) => {
                        if (index_journey === action.payload.key) {
                            let copy_journey = Object.assign({}, journey);
                            if (action.payload.type === "origin") {
                                copy_journey.origin = action.payload.airport === null ? null : Object.assign({}, action.payload.airport);
                            } else if (action.payload.type === "destination") {
                                copy_journey.destination = action.payload.airport === null ? null : Object.assign({}, action.payload.airport);
                            }
                            journey = copy_journey;
                        }
                        copy_journeys.push(journey);
                    });
                    copy_flight_group.journey = copy_journeys;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_REDIRECTION": {
            new_state.redirection = action.payload.redirection;
            return new_state;
        }
        case "FLIGHT_SET_AIRPORT_JOURNEY_ROUNDTRIP": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            let copy_journeys = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey.map((journey, index_journey) => {
                        if (index_journey === action.payload.key) {
                            let copy_journey = Object.assign({}, journey);
                            if (action.payload.type === "origin") {
                                copy_journey.origin = action.payload.airport === null ? null : Object.assign({}, action.payload.airport);
                            } else if (action.payload.type === "destination") {
                                copy_journey.destination = action.payload.airport === null ? null : Object.assign({}, action.payload.airport);
                            }
                            journey = copy_journey;
                        } else if (index_journey !== action.payload.key) {
                            let copy_journey = Object.assign({}, journey);
                            if (action.payload.type === "origin") {
                                copy_journey.destination = action.payload.airport === null ? null : Object.assign({}, action.payload.airport);
                            } else if (action.payload.type === "destination") {
                                copy_journey.origin = action.payload.airport === null ? null : Object.assign({}, action.payload.airport);
                            }
                            journey = copy_journey;
                        }
                        copy_journeys.push(journey);
                    });
                    copy_flight_group.journey = copy_journeys;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_AIRPORT_JOURNEY_MULTIDEST": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            let copy_journeys = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.journey.map((journey, index_journey) => {
                        if (index_journey === action.payload.key) {
                            let copy_journey = Object.assign({}, journey);
                            if (action.payload.type === "origin") {
                                copy_journey.origin = action.payload.airport === null ? null : Object.assign({}, action.payload.airport);
                            } else if (action.payload.type === "destination") {
                                copy_journey.destination = action.payload.airport === null ? null : Object.assign({}, action.payload.airport);
                            }
                            journey = copy_journey;
                        }
                        //else if (index_journey === (action.payload.key + 1)) {
                        //let copy_journey = Object.assign({}, journey);
                        //if (action.payload.type === "destination")
                        //copy_journey.origin = action.payload.airport === null ? null : Object.assign({}, action.payload.airport);
                        //journey = copy_journey;
                        //}
                        copy_journeys.push(journey);
                    });
                    copy_flight_group.journey = copy_journeys;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_CART_SET_TAB": {
            let flight_cart = new_state.cart.slice();
            let copy_outbounds = [];
            let copy_flights = [];
            flight_cart.map((flight, flight_index) => {
                if (flight_index === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.segment_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.tab = action.payload.tab;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.cart = copy_flights;
            return new_state;
        }
        case "FLIGHT_CART_TOGGLE_DETAIL": {
            let flight_cart = new_state.cart.slice();
            let copy_outbounds = [];
            let copy_flights = [];
            flight_cart.map((flight, flight_index) => {
                if (flight_index === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.outbound_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.detail = action.payload.detail;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.cart = copy_flights;
            return new_state;
        }
        case "FLIGHT_SELECT_AIRLINES": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.company = action.payload.airlines;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_CLEAR_AIRLINES": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group) => {
                let copy_flight_group = Object.assign({}, flight_group);
                copy_flight_group.company = [];
                flight_group = copy_flight_group;
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_FETCHED_CART_OPTIONS": {
            let cart = new_state.cart.slice();
            let copy_outbounds = [];
            let copy_flights = [];
            cart.map((flight, flight_index) => {
                if (flight_index === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.segment_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.options = [].concat([], action.payload.options);
                            copy_outbound.more_option = action.payload.more_option;
                            copy_outbound.empty_option = action.payload.is_empty;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.cart = copy_flights;
            return new_state;
        }
        case "FLIGHT_SET_SELECTED_CART_OPTION" : {
            let cart = new_state.cart.slice();
            let copy_outbounds = [];
            let copy_flights = [];
            cart.map((flight, flight_index) => {
                if (flight_index === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.segment_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.selected_option = action.payload.selected_option;
                            copy_outbound.key_pricing = action.payload.key_pricing;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.cart = copy_flights;
            return new_state;
        }
        case "FLIGHT_SET_EMPTY_CART_OPTIONS": {
            let cart = new_state.cart.slice();
            let copy_outbounds = [];
            let copy_flights = [];
            cart.map((flight, flight_index) => {
                if (flight_index === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.segment_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.empty_option = true;
                            copy_outbound.more_option = action.payload.more_option;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.cart = copy_flights;
            return new_state;
        }
        case "FLIGHT_SET_FETCH_ERROR_CART_OPTIONS": {
            let cart = new_state.cart.slice();
            let copy_outbounds = [];
            let copy_flights = [];
            cart.map((flight, flight_index) => {
                if (flight_index === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.segment_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.options_error = action.payload.fetch_error;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.cart = copy_flights;
            return new_state;
        }
        case "FLIGHT_RESET_FETCH_ERROR_CART_OPTIONS": {
            let cart = new_state.cart.slice();
            let copy_outbounds = [];
            let copy_flights = [];
            cart.map((flight, flight_index) => {
                if (flight_index === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.segment_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.options_error = null;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.cart = copy_flights;
            return new_state;
        }
        case "FLIGHT_ADD_CART_PASSENGER_SEAT": {
            let cart = new_state.cart.slice();
            let tmp_seat = Object.assign({}, new_state.tmp_seat);
            let copy_outbounds = [];
            let copy_flights = [];
            let copy_legs = [];

            tmp_seat[action.payload.traveler] = action.payload.seat;

            cart.map((flight, flight_index) => {
                if (flight_index === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.outbound_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.legs.map((leg, leg_index) => {
                                if (leg_index === action.payload.leg_index) {
                                    let copy_leg = Object.assign({}, leg);
                                    let copy_seats = Object.assign({}, copy_leg.seats);
                                    copy_seats[action.payload.traveler] = {
                                        seat: action.payload.seat,
                                        price: action.payload.price
                                    };
                                    copy_leg.seats = copy_seats;
                                    leg = copy_leg;
                                }
                                copy_legs.push(leg);
                            });
                            copy_outbound.legs = copy_legs;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.cart = copy_flights;
            new_state.tmp_seat = tmp_seat;
            return new_state;
        }
        case "FLIGHT_ADD_CART_BAGGAGE_OPTION": {
            let cart = new_state.cart.slice();
            let copy_outbounds = [];
            let copy_flights = [];
            cart.map((flight, flight_index) => {
                if (flight_index === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound) => {
                        let copy_outbound = Object.assign({}, outbound);
                        let paid_options = Object.assign({}, copy_outbound.paid_options);
                        let find = false;
                        paid_options[action.payload.traveler] = paid_options[action.payload.traveler] || [];
                        paid_options[action.payload.traveler].map((option) => {
                            if (option.key_optional_service === action.payload.baggage.key_optional_service) {
                                find = true;
                            }
                        });
                        if (!find) {
                            paid_options[action.payload.traveler].push(action.payload.baggage);
                        }
                        copy_outbound.paid_options = paid_options;
                        outbound = copy_outbound;
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.cart = copy_flights;
            return new_state;
        }
        case "FLIGHT_SELECT_CART_QUANTITY": {
            let cart = new_state.cart.slice();
            let copy_outbounds = [];
            let copy_flights = [];
            let copy_more_options = [];
            cart.map((flight, flight_index) => {
                if (flight_index === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound) => {
                        let copy_outbound = Object.assign({}, outbound);
                        let paid_options = Object.assign({}, copy_outbound.paid_options);
                        let copy_options = paid_options[action.payload.traveler].slice();
                        copy_options.map((option) => {
                            if (option.key_optional_service === action.payload.key_optional_service) {
                                let copy_option = Object.assign({}, option);
                                copy_option.quantity = action.payload.quantity;
                                option = copy_option;
                            }
                            copy_more_options.push(option);
                        });
                        copy_options = copy_more_options;
                        paid_options[action.payload.traveler] = copy_options;
                        copy_outbound.paid_options = paid_options;
                        outbound = copy_outbound;
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.cart = copy_flights;
            return new_state;
        }
        case "FLIGHT_REMOVE_CART_BAGGAGE_OPTION": {
            let cart = new_state.cart.slice();
            let copy_outbounds = [];
            let copy_flights = [];
            cart.map((flight, flight_index) => {
                if (flight_index === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound) => {
                        let copy_outbound = Object.assign({}, outbound);
                        let paid_options = Object.assign({}, copy_outbound.paid_options);
                        paid_options[action.payload.traveler] = paid_options[action.payload.traveler].filter((option) => {
                            return option.key_optional_service !== action.payload.baggage;
                        });
                        copy_outbound.paid_options = paid_options;
                        outbound = copy_outbound;
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.cart = copy_flights;
            return new_state;
        }
        case "FLIGHT_CART_UPDATED": {
            new_state.cart_updated = action.payload.flag;
            return new_state;
        }
        case "FLIGHT_GET_PROVIDERS": {
            new_state.provider_list = action.payload.provider_list;
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    if (action.payload.config === "verdie") {
                        let found = action.payload.provider_list.find((provider) => {
                            return provider.code === "amadeus_rest";
                        });
                        if (found !== undefined) {
                            copy_flight_group.provider = [found.id];
                        } else {
                            copy_flight_group.provider = [action.payload.provider_list[0].id];
                        }
                        let tahiti_nui = action.payload.provider_list.find((provider) => {
                            return provider.code === "tahitinui";
                        });
                        if (tahiti_nui !== undefined) {
                            copy_flight_group.provider.push(tahiti_nui.id);
                        }
                        let eva_misterfly = action.payload.provider_list.find((provider) => {
                            return provider.code === "eva_misterfly";
                        });
                        if (eva_misterfly !== undefined) {
                            copy_flight_group.provider.push(eva_misterfly.id);
                        }
                    } else if (action.payload.config === "cercledesvoyages") {
                        let found = action.payload.provider_list.find((provider) => {
                            return provider.code === "eva_misterfly";
                        });
                        let galileo = action.payload.provider_list.find((provider) => {
                            return provider.code === "1G";
                        });
                        if (found !== undefined) {
                            copy_flight_group.provider = [found.id];
                        } else {
                            copy_flight_group.provider = [];
                        }

                        if (galileo !== undefined) {
                            copy_flight_group.provider.push(galileo.id);
                        }

                        let tahiti_nui = action.payload.provider_list.find((provider) => {
                            return provider.code === "tahitinui";
                        });
                        if (tahiti_nui !== undefined) {
                            copy_flight_group.provider.push(tahiti_nui.id);
                        }
                        let travel_fusion = action.payload.provider_list.find((provider) => {
                            return provider.code === "travel_fusion";
                        });
                        if (travel_fusion !== undefined) {
                            copy_flight_group.provider.push(travel_fusion.id);
                        }
                    } else if (action.payload.config === "visiteurs") {
                        copy_flight_group.provider = [];
                        let galileo = action.payload.provider_list.find((provider) => {
                            return provider.code === "1G";
                        });
                        if (galileo !== undefined) {
                            copy_flight_group.provider.push(galileo.id);
                        }
                        let tahiti_nui = action.payload.provider_list.find((provider) => {
                            return provider.code === "tahitinui";
                        });
                        if (tahiti_nui !== undefined) {
                            copy_flight_group.provider.push(tahiti_nui.id);
                        }
                        let misterfly = action.payload.provider_list.find((provider) => {
                            return provider.code === "eva_misterfly";
                        });
                        if (misterfly !== undefined) {
                            copy_flight_group.provider.push(misterfly.id);
                        }
                    } else if (action.payload.config === "tropicalementvotre") {
                        copy_flight_group.provider = [];
                        let tahiti_nui = action.payload.provider_list.find((provider) => {
                            return provider.code === "tahitinui";
                        });
                        if (tahiti_nui !== undefined) {
                            copy_flight_group.provider.push(tahiti_nui.id);
                        }
                        let sabre = action.payload.provider_list.find((provider) => {
                            return provider.code === "sabre_rest";
                        });
                        if (sabre !== undefined) {
                            copy_flight_group.provider.push(sabre.id);
                        }
                    } else if (action.payload.config === "connections") {
                        copy_flight_group.provider = [];
                        let galileo = action.payload.provider_list.find((provider) => {
                            return provider.code === "1G";
                        });
                        if (galileo !== undefined) {
                            copy_flight_group.provider.push(galileo.id);
                        }
                        let tahiti_nui = action.payload.provider_list.find((provider) => {
                            return provider.code === "tahitinui";
                        });
                        if (tahiti_nui !== undefined) {
                            copy_flight_group.provider.push(tahiti_nui.id);
                        }
                    } else {
                        copy_flight_group.provider = action.payload.provider_list.map((provider) => provider.id);
                        // copy_flight_group.provider = [action.payload.provider_list[0].id];
                    }
                    let gir = action.payload.provider_list.find((provider) => {
                        return provider.code === "custom_flight";
                    });
                    if (gir) {
                        copy_flight_group.provider.push(gir.id);
                    }
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SELECT_PROVIDER": {
            let flight_groups = new_state.flight_groups.slice();
            let copy_flight_groups = [];
            flight_groups.map((flight_group, index_group) => {
                if (index_group === action.payload.index) {
                    let copy_flight_group = Object.assign({}, flight_group);
                    copy_flight_group.provider = action.payload.provider;
                    flight_group = copy_flight_group;
                }
                copy_flight_groups.push(flight_group);
            });
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_TOGGLE_DETAIL_PRICE_CART": {
            let cart = new_state.cart.slice();
            let copy_flights = [];
            cart.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.detail_price = action.payload.detail;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.cart = copy_flights;
            return new_state;
        }
        case "FLIGHT_SAVE_FLIGHT_GROUPS" : {
            let temp_saved_groups = new_state.previous_flight_groups.slice();
            if (temp_saved_groups.length === 10) {
                //remove first elements
                temp_saved_groups.splice(0, new_state.flight_groups.length);
            }
            new_state.previous_flight_groups = [].concat(temp_saved_groups, new_state.flight_groups);
            return new_state;
        }
        case "FLIGHT_RESET_PREVIOUS_FLIGHT_GROUPS" : {
            new_state.previous_flight_groups = [];
            return new_state;
        }
        case "FLIGHT_REPLACE_GROUP": {
            let new_groups = [];
            new_state.flight_groups.map((group, group_index) => {
                if (group_index === action.payload.index) {
                    new_groups.push(action.payload.group);
                } else {
                    new_groups.push(group);
                }
            });

            new_state.flight_groups = new_groups;
            return new_state;
        }
        case "FLIGHT_REVERT_JOURNEYS": {
            let new_groups = [];
            new_state.flight_groups.map((group, group_index) => {
                if (group_index === action.payload.index) {
                    let copy_flight_group = Object.assign({}, group);
                    let new_journey = [];
                    copy_flight_group.journey.map((journey) => {
                        let copy_journey = Object.assign({}, journey);
                        copy_journey.origin = journey.destination;
                        copy_journey.destination = journey.origin;
                        new_journey.push(copy_journey);
                    });
                    copy_flight_group.journey = new_journey;
                    new_groups.push(copy_flight_group);
                } else {
                    new_groups.push(group);
                }
            });
            new_state.flight_groups = new_groups;
            return new_state;
        }
        case "FLIGHT_SET_GROUPS_MANUAL_REQUEST": {
            let copy_flight_groups = new_state.flight_groups.slice();
            let copy_group = copy_flight_groups[action.payload.group_index];
            copy_group.manual_request = action.payload.manual_request;
            copy_flight_groups[action.payload.group_index] = copy_group;
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_FIRST_RENDER": {
            new_state.first_render = action.payload.first_render;
            return new_state;
        }
        case 'CART_REFRESH_PRICE': {
            if (action.payload !== null) {
                new_state.refresh = action.payload.flight;
            }
            return new_state;
        }
        case "FILL_MULTI_DEST": {
            new_state.multi_dest_filled = !new_state.multi_dest_filled;
            return new_state;
        }
        case "FLIGHT_SET_OPEN_DATE_PICKERS": {
            new_state.open_date_pickers = action.payload.open_date_pickers;
            return new_state;
        }
        case "FLIGHT_SET_MANUAL_LUGGAGE": {
            let copy_flight_groups = new_state.flight_groups.slice();
            let copy_group = copy_flight_groups[action.payload.group_index];
            copy_group.manual_luggage = action.payload.manual_luggage;
            copy_flight_groups[action.payload.group_index] = copy_group;
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_STANDARD_PRICE": {
            let updatedArray = [
                ...new_state.flight_groups.slice(0, action.payload.group_index), // Copy elements before the index
                { ...new_state.flight_groups[action.payload.group_index], standard_price: action.payload.standard_price, public_price: action.payload.standard_price, private_price: action.payload.standard_price }, // Replace the value at the index
                ...new_state.flight_groups.slice(action.payload.group_index + 1) // Copy elements after the index
            ];
            new_state.flight_groups = updatedArray;
            return new_state;
        }
        case "FLIGHT_SET_NDC_PRICE": {
            let updatedArray = [
                ...new_state.flight_groups.slice(0, action.payload.group_index), // Copy elements before the index
                { ...new_state.flight_groups[action.payload.group_index], ndc_price: action.payload.ndc_price, public_price_ndc: action.payload.ndc_price, private_price_ndc: action.payload.ndc_price }, // Replace the value at the index
                ...new_state.flight_groups.slice(action.payload.group_index + 1) // Copy elements after the index
            ];
            new_state.flight_groups = updatedArray;
            return new_state;
        }
        case "FLIGHT_SET_PRIVATE_PRICE_NDC": {
            let updatedArray = [
                ...new_state.flight_groups.slice(0, action.payload.group_index), // Copy elements before the index
                { ...new_state.flight_groups[action.payload.group_index], private_price_ndc: action.payload.private_price_ndc }, // Replace the value at the index
                ...new_state.flight_groups.slice(action.payload.group_index + 1) // Copy elements after the index
            ];
            new_state.flight_groups = updatedArray;
            return new_state;
        }
        case "FLIGHT_SET_PUBLIC_PRICE_NDC": {
            let updatedArray = [
                ...new_state.flight_groups.slice(0, action.payload.group_index), // Copy elements before the index
                { ...new_state.flight_groups[action.payload.group_index], public_price_ndc: action.payload.public_price_ndc }, // Replace the value at the index
                ...new_state.flight_groups.slice(action.payload.group_index + 1) // Copy elements after the index
            ];
            new_state.flight_groups = updatedArray;
            return new_state;
        }
        case "FLIGHT_SET_PARAMS": {
            const {group_index, params, gir_provider} = action.payload;
            const cabin = {
                "Economy": 0,
                "PremiumEconomy": 1,
                "Business": 2,
                "First": 3
            }
            let copy_flight_groups = [...new_state.flight_groups];
            let copy_group = copy_flight_groups[group_index];
            copy_group.direct_flight = !params.allow_stopovers;
            copy_group.public_price = params.public_standard_offer;
            copy_group.private_price = params.standard_TO_offer;
            copy_group.one_stop = params.allow_stopovers && params.max_stops === 1;
            copy_group.included_luggage = params.number_of_bags !== null && params.number_of_bags.length !== 0 && (params.number_of_bags.includes(1) || params.number_of_bags(2))
            copy_group.company = [...params.allowed_airline];
            if (params.cabin) {
                let sort_cabin = params.cabin.toSorted((a, b) => cabin[a] - cabin[b]);
                copy_group.journey[0].class_type = cabin[sort_cabin[0]];
                copy_group.journey[1].class_type = cabin[sort_cabin[0]];
            }
            let provider = [];
            if (params.providers.length !== 0) {
                provider = params.providers.map(provider => {return provider.id});
            }
            if (gir_provider !== null) {
                console.log('gir_provider:', gir_provider);
                provider.push(gir_provider.id);
            }
            if (provider.length !== 0) {
                copy_group.provider = provider;
            }
            copy_flight_groups[group_index] = copy_group;
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
        case "FLIGHT_SET_IS_HONEYMOON": {
            // update is_honeymoon
            const { group_index, is_honeymoon } = action.payload;
            let copy_flight_groups = [...new_state.flight_groups];
            let copy_group = copy_flight_groups[group_index];
            copy_group.is_honeymoon = is_honeymoon;
            copy_flight_groups[group_index] = copy_group;
            new_state.flight_groups = copy_flight_groups;
            return new_state;
        }
    }
    return new_state;
}
