import React, { Fragment, useState, useMemo, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Alert } from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import WarningIcon from '@material-ui/icons/Warning';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import {
    Box,
    styled
} from '@mui/material';

import NavigateBefore from "@material-ui/icons/NavigateBefore";

import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";

import { CartConstructionReplaceCarInputs } from "../CartMaterial/CartConstructionReplaceCarInputs";
import { getDestination } from "../Itinerary/utils/getDestination";
import {
    CartConstructionReplaceProductModalStep
} from "../CartMaterial/objects/cartConstructionReplaceProductModalStep";
import { CartConstructionReplaceProductContext } from "../CartMaterial/utils/cartConstructionReplaceProductContext";
import RenderInput from './RenderInput';
import MobileCard from './MobileCard';
import CarsSkeleton from './CarsSkeleton';
import CarLocationHeader from './CarLocationHeader';
import RenderMatrix from './RenderMatrix';
import RenderMatrixSkeleton from './RenderMatrixSkeleton';
import RenderFilters from './RenderFilters';
import CheckAvailability from "../Cars/Functions/CheckAvailability";
import GetCarContract from "../Cars/Functions/GetCarContract";
import GetMinPrice from "../Cars/Functions/GetMinPrice";
// import RenderDisplayCars from './RenderDisplayCars';
import RenderCardContent from './RenderCardContent';
import RenderContractService from './RenderContractService';
import RenderContract from './RenderContract';
import RenderSpecialEquipments from './RenderSpecialEquipments';
// import RenderItinerary from './RenderItinerary';

import { InitCarsResult, SetCarsCategories } from "../../Actions/CarsSearch";
import moment from "moment";

import { setStep } from "../CartMaterial/redux/cartConstructionReducer";
import GetCookie from "../Common/Functions/GetCookie";

// import { useItineraryContent } from '../Itinerary/network/itineraryContent';
// import { isContentStepEqual } from "../CartMaterial/utils/isContentStepEqual";
// import { useItineraryContentUpdate } from "../Itinerary/network/itineraryContentUpdate";
// import { useItineraryTripUpdate } from "../Itinerary/network/itineraryTripUpdate";
// import { setItineraryContent } from "../Itinerary/redux/reducer";
import { useItineraryContentFetch } from "../CartMaterial/network/itineraryContentFetch";
import { CarsItinerary } from "./CarsItinerary";
import { RenderCars } from "./RenderCars";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    carsTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    orangeButton: {
        backgroundColor: `${main_color}`,
        color: "white"
    },
    returnButton: {
        display: "inline",
        marginRight: 10,
        float: "left",
        marginTop: 6
    },
    noPaddingMargin: {
        padding: '0 !important',
        margin: 0
    },
    cardPadding: {
        padding: "10px 10px 0 10px"
    },
    resultHeight: {
        //height: '100%'
        flex: 1
    },
    container: {
        maxWidth: 1200
    },
    paginationItemActive: {
        outline: "5px auto -webkit-focus-ring-color"
    },
    pagination: {
        "& button:focus": {
            outline: "none !important"
        }
    },
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    bold: {
        fontWeight: "bold"
    },
    marginLeft8: {
        marginLeft: 8
    },
    verticalSub: {
        verticalAlign: "sub"
    },
    returnButtonContainer: {
        width: 48
    },
    cardTitle: {
        "& .mui-jss-MuiCardHeader-title": {
            fontSize: 22,
            fontWeight: "bold"
        },
        "fontSize": 22,
        "fontWeight": "bold"
    },
    relativePosition: {
        position: "relative"
    },
    textCenter: {
        textAlign: "center"
    },
    marginBottom15: {
        marginBottom: 15
    },
    marginBottom100: {
        marginBottom: 100
    },
    marginTop15: {
        marginTop: 15
    },
    marginTop80: {
        marginTop: 80
    },
    blockDisplay: {
        display: "block"
    },
    paddingButton: {
        paddingTop: 17,
        paddingBottom: 17
    },
    staticPriceContainer: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'white',
        marginLeft: -16,
        padding: 16,
        borderTop: '1px solid lightgray'
    },
    formControlRoot: {
        "width": '50%',
        "&& .mui-jss-MuiFormControlLabel-labelPlacementStart": {
            justifyContent: 'space-between'
        },
        "& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(14px, 13px) scale(1)'
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '9.5px 15px'
        }
    },
    paddingRight0: {
        paddingRight: '0px !important'
    }
}));

let source = null;

const MaterialCarsSearch = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const downDesktop = useMediaQuery('(min-width:960px)');

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const versionString = GetCookie("trip_id_version");
    const version = versionString ? parseInt(versionString) : -1;

    const open_itinerary = useSelector((state) => state.cars_search.open_itinerary);
    const stepperStep = useSelector((state) => state.cartConstruction.step);
    const cartLoading = useSelector((state) => state.cartConstruction.loading);
    const step = useSelector(state => state.cart.step);
    const itineraryContent = useSelector((state) => {
        return version ?
            state.itinerarySlice.content[version] :
            undefined;
    });
    const provider_list = useSelector((store) => {
        return store.trip.providers;
    });
    const all_data = useSelector((store) => {
        return store.trip.all_data;
    });
    const user = useSelector((store) => {
        return store.user.user;
    });
    const cars_results = useSelector((store) => {
        return store.cars_search.cars_results;
    });
    const cars_categories = useSelector((store) => {
        return store.cars_search.cars_categories;
    });
    const all_cars = useSelector((store) => {
        return store.cars_search.all_cars;
    });
    const detailed_index = useSelector((store) => {
        return store.cars_search.detailed_index;
    });
    const click_type = useSelector((store) => {
        return store.cars_search.click_type;
    });
    const car_contract = useSelector((store) => {
        return store.cars_search.car_contract;
    });
    const agency_options = useSelector((store) => {
        return store.cars_search.agency_options;
    });
    const language = useSelector((store) => {
        return store.header.tmp_language;
    });
    const currency_list = useSelector((store) => {
        return store.base.currency_list;
    });
    const from_cart = useSelector((store) => {
        return store.cars_search.from_cart;
    });
    const cart_car = useSelector((store) => {
        return store.cars_search.selected_car;
    });
    const cart = useSelector((store) => {
        return store.cars_search.cart;
    });
    const step_info = useSelector((store) => {
        return store.cars_search.step_info;
    });
    const isForm = useSelector((store) => {
        return store.cars_search.isForm;
    });
    const isResult = useSelector((store) => {
        return store.cars_search.isResult;
    });
    const isContract = useSelector((store) => {
        return store.cars_search.isContract;
    });
    const contract_error = useSelector((store) => {
        return store.cars_search.contract_error;
    });
    const itinerary_list = useSelector((store) => {
        return store.itinerary.itinerary_list;
    });
    const car_to_search = useSelector(store => store.cart.car_to_search);

    // const syncedItinerarySteps = useItineraryContent(version);

    const initState = {
        same_dest: false,
        age: 25,
        nb_vehicle: 1,
        origin_dest: null,
        origin_dest_error: false,
        origin_station: null,
        return_dest: null,
        return_dest_error: false,
        return_station: null,
        origin_date: null,
        origin_date_error: [],
        return_date: null,
        date_error: false,
        return_date_error: [],
        origin_hour: "",
        origin_hour_error: false,
        return_hour: "",
        return_hour_error: false,
        contract: "",
        country_code: null,
        origin_datepicker: false,
        return_datepicker: false
    };
    const [search, setSearch] = useState([initState]);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [category_id, setCategoryID] = useState(null);
    const [provider_id, setProviderID] = useState(null);
    const [display_cars, setDisplayCars] = useState([]);
    const [active_page, setActivePage] = useState(1);
    const [page_list, setPageList] = useState([]);
    const [contract_info, setContractInfo] = useState(null);
    const [location_index, setLocationIndex] = useState(0);
    const [car_index, setCarIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [info_dialogue, setInfoDialog] = useState(null);
    const [mobileDialog, setMobileDialog] = useState(false);
    const [contract_selected, setContractSelected] = useState('');
    const [important_notice, setImportantNotice] = useState(false);
    const [supplementProvider, setSupplementProvider] = useState([]);
    const [replaceProductInputs, setReplaceProductInputs] = useState({
        startDate: null,
        endDate: null,
        pickupPlace: null,
        returnPlace: null,
        samePlace: false,
        origin_station: null,
        return_station: null,
        age: 25,
        count: 1,
        contract: ''
    });
    const [stickyHeaderOffset, setStickyHeaderOffset] = useState(0);

    const inputRef = useRef(null);
    const resultsRef = useRef(null);
    const contractRef = useRef(null);
    const backRef = useRef(null);
    const stepperRef = useRef(null);
    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    useItineraryContentFetch(version);

    useEffect(() => {
        let providers = [];
        provider_list.map((tmp) => {
            if (tmp.enabled_content_types.includes(2) && ['alamo', 'bsp_auto', 'avis', 'enterprise', 'national'].includes(tmp.provider.code)) {
                providers.push(tmp.provider.name);
            }
        });
        setSupplementProvider(providers);
        if (!downDesktop) {
            setCategoryID("all");
        }
        if (car_to_search !== null) {
            const age = car_to_search.driver !== null ? moment.utc(new Date()).diff(moment.utc(car_to_search.driver.birth_date), 'y') - 18 : 25;
            setSearch([{
                same_dest: false,
                age: age >= 30 ? "30+" : age,
                nb_vehicle: 1,
                origin_dest: car_to_search.start_destination,
                origin_dest_error: false,
                origin_station: null,
                return_dest: car_to_search.end_destination,
                return_dest_error: false,
                return_station: null,
                origin_date: moment.utc(car_to_search.start_date),
                origin_date_error: [],
                return_date: moment.utc(car_to_search.end_date),
                date_error: false,
                return_date_error: [],
                origin_hour: moment.utc(car_to_search.start_date).format('HH[h]mm'),
                origin_hour_error: false,
                return_hour: moment.utc(car_to_search.end_date).format('HH[h]mm'),
                return_hour_error: false,
                contract: "",
                country_code: car_to_search.country_code,
                origin_datepicker: false,
                return_datepicker: false
            }]);
        }
        if (itinerary_list.length !== 0 && car_to_search === null) {
            let last_iti = null;
            let first_iti = null;
            let new_iti = [];
            itinerary_list.map((itinerary, index) => {
                if (itinerary.step_type === "STEP" && (index === 0 || index === 1)) {
                    first_iti = itinerary;
                }
                if (itinerary.step_type === "STEP" && (index === itinerary_list.length - 1 || index === itinerary_list.length - 2)) {
                    last_iti = itinerary;
                }
                //if (itinerary.r2r_json !== undefined && itinerary.r2r_json !== null && itinerary.r2r_json.vehicle !== undefined && itinerary.r2r_json.vehicle !== null && itinerary.r2r_json.vehicle.kind === "car") {
                //new_iti.push(index);
                //}
            });
            if (last_iti !== null && first_iti !== null) {
                let origin_hour = moment.utc(first_iti.start_date).format('HH[h]mm');
                let return_hour = moment.utc(last_iti.end_date).format('HH[h]mm');
                let split_origin_hour = origin_hour.split("h");
                let split_return_hour = return_hour.split("h");
                if (parseInt(split_origin_hour[1]) <= 30) {
                    split_origin_hour[1] = "30";
                } else if (parseInt(split_origin_hour[1]) <= 60) {
                    split_origin_hour[0] = (parseInt(split_origin_hour[0]) + 1).toString();
                    split_origin_hour[1] = "00";
                }
                if (parseInt(split_return_hour[1]) <= 30) {
                    split_return_hour[1] = "30";
                } else if (parseInt(split_return_hour[1]) <= 60) {
                    split_return_hour[0] = (parseInt(split_return_hour[0]) + 1).toString();
                    split_return_hour[1] = "00";
                }
                let new_start_hour = (parseInt(split_origin_hour[0]) + 1 < 10 ? "0" : "") + (parseInt(split_origin_hour[0]) + 1).toString() + "h" + split_origin_hour[1];
                let new_return_hour = (parseInt(split_return_hour[0]) - 3 < 10 ? "0" : "") + (parseInt(split_return_hour[0]) - 3).toString() + "h" + split_return_hour[1];
                setSearch([{
                    same_dest: false,
                    age: 25,
                    nb_vehicle: 1,
                    origin_dest: first_iti.destination,
                    origin_dest_error: false,
                    origin_station: null,
                    return_dest: last_iti.destination,
                    return_dest_error: false,
                    return_station: null,
                    origin_date: moment.utc(first_iti.start_date),
                    origin_date_error: [],
                    return_date: moment.utc(last_iti.end_date),
                    date_error: false,
                    return_date_error: [],
                    origin_hour: new_start_hour,
                    origin_hour_error: false,
                    return_hour: new_return_hour,
                    return_hour_error: false,
                    contract: "",
                    country_code: first_iti.destination.data.country !== null ? first_iti.destination.data.country.country_code : "",
                    origin_datepicker: false,
                    return_datepicker: false
                }]);
            }
        }
        return () => {
            setResults([]);
            setProviderID(null);
            setProviderID(null);
            setDisplayCars([]);
            setPageList([]);
            setContractInfo(null);
            dispatch({ type: "CARS_RESET_RESULTS" });
            dispatch({ type: "CARS_TOGGLE_TIMELINE", payload: { cars_timeline: true } });
            dispatch({ type: "CARS_IS_FORM", payload: { from_cart: false } });
            dispatch({ type: "CART_CAR_TO_SEARCH", payload: null });
            if (open_itinerary)
                dispatch({type: 'CAR_OPEN_ITINERARY'});
        };
    }, []);
    useEffect(() => {
        if (itinerary_list.length !== 0 && car_to_search === null) {
            getCarDestination();
        }
    }, [itinerary_list]);
    useEffect(() => {
        if (isForm) {
            setResults([]);
            setProviderID(null);
            setProviderID(null);
            setDisplayCars([]);
            setPageList([]);
            setContractInfo(null);
        }
    }, [isForm]);
    useEffect(() => {
        return () => {
            dispatch({ type: "CARS_IS_FORM" });
        };
    }, []);
    useEffect(() => {
        setResults(cars_results);
    }, [cars_results]);
    useEffect(() => {
        if (!downDesktop) {
            setCategoryID("all");
        } else {
            setCategoryID(null);
        }
    }, [downDesktop]);
    useEffect(() => {
        if (from_cart && cart_car !== null) {
            let origin_hour = null;
            let return_hour = null;
            if (step_info[0].origin_hours + "h" + step_info[0].origin_minutes === "12h00") {
                origin_hour = t("global.noon");
            } else if (step_info[0].origin_hours + "h" + step_info[0].origin_minutes === "00h00") {
                origin_hour = t("global.midnight");
            } else {
                origin_hour = step_info[0].origin_hours + "h" + step_info[0].origin_minutes;
            }
            if (step_info[0].return_hours + "h" + step_info[0].return_minutes === "12h00") {
                return_hour = t("global.noon");
            } else if (step_info[0].return_hours + "h" + step_info[0].return_minutes === "00h00") {
                return_hour = t("global.midnight");
            } else {
                return_hour = step_info[0].return_hours + "h" + step_info[0].return_minutes;
            }
            let tmp_search = [{
                age: 25,
                nb_vehicle: 1,
                origin_dest: null,
                origin_station: null,
                return_dest: null,
                return_station: null,
                start_destination: step_info[0].start_destination,
                end_destination: step_info[0].end_destination,
                origin_date: step_info[0].origin_date,
                return_date: step_info[0].return_date,
                origin_hour: origin_hour,
                return_hour: return_hour
            }];
            setSearch(tmp_search);
            getContractInfo({ selected_car: cart_car }, tmp_search);
        }
    }, [from_cart]);
    useEffect(() => {
        if (cars_results.length !== 0 && cars_categories.length !== 0) {
            let tmp_categories = cars_categories.slice();
            tmp_categories.map((category) => {
                category.min_price = GetMinPrice(cars_results, category.name, quotation_code);
            });
            dispatch(SetCarsCategories(tmp_categories));
        }
        if (!downDesktop) {
            getDisplayCars();
        }
    }, [cars_results, cars_categories.length]);
    useEffect(() => {
        if (contract_info !== null) {
            let minAgeError = false;
            let maxAgeError = false;
            let selected_car = contract_info.selected_car;
            if (selected_car.vehicle_info.minimal_age !== null && search[location_index].age < selected_car.vehicle_info.minimal_age) {
                minAgeError = true;
            }
            if (selected_car.vehicle_info.maximal_age !== null && selected_car.vehicle_info.maximal_age < search[location_index].age) {
                maxAgeError = true;
            }
            if (minAgeError || maxAgeError) {
                enqueueSnackbar(t("cars.check_age_min_max") + selected_car.vehicle_info.minimal_age + " " + t("global.and") + " " + selected_car.vehicle_info.maximal_age + " " + t("global.years"), {
                    variant: "warning"
                });
            }
        }
    }, [contract_info]);
    useEffect(() => {
        getDisplayCars();
    }, [provider_id, category_id]);

    useEffect(() => {
        if (display_cars.length !== 0 && matches && isResult) {
            setTimeout(() => {
                if (resultsRef !== null && resultsRef.current !== null) {
                    resultsRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
                }
            }, 200);
        }
    }, [display_cars]);
    useEffect(() => {
        if (isContract) {
            contractRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [isContract]);
    useEffect(() => {
        if (all_cars) {
            enqueueSnackbar(t("cars.get_all_cars"), {
                variant: "success"
            });
        }
    }, [all_cars]);
    useEffect(() => {
        let list = getPage(display_cars, active_page);
        setPageList(list);
        if (resultsRef.current !== null) {
            resultsRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [active_page]);
    useEffect(() => {
        if (!isContract && isResult && results.length !== 0) {
            setLoading(true);
            setPageList([]);
            setCategoryID(null);
            setProviderID(null);
            setDisplayCars([]);
            setContractInfo(null);
            dispatch({ type: "CARS_SET_CONTRACT", payload: { contract: null } });
            dispatch({ type: 'CARS_SET_CARS_RESULTS', payload: { cars_results: null } });
            CheckAvailability(search, location_index, provider_list.filter(provider => provider.branch_code === all_data.branch_code), user, dispatch, t, setLoading, source);
        }
    }, [location_index]);
    useEffect(() => {
        if (isForm) {
            if (source !== null) {
                source.cancel('Back to form');
            }
            source = axios.CancelToken.source();
        }
    }, [isForm]);
    const getCarDestination = () => {
        let tmp_iti = [[]];
        let elem_index = 0;
        let new_search = [];
        itinerary_list.map((itinerary, index) => {
            if (itinerary.step_type === "STEP") {
                if (itinerary_list[index - 1] !== undefined && itinerary_list[index - 1].r2r_json?.vehicle?.kind === 'car') {
                    if (itinerary_list[index - 1].step_type === "STEP") {
                        tmp_iti[elem_index].push(itinerary_list[index - 1]);
                    }
                    tmp_iti[elem_index].push(itinerary);
                } else {
                    elem_index++;
                    tmp_iti.push([]);
                }
            }
        });
        tmp_iti.filter(iti => iti.length !== 0).map((iti) => {
            let last_iti = iti[iti.length - 1];
            let first_iti = iti[0];
            if (last_iti !== null && first_iti !== null) {
                let origin_hour = moment.utc(first_iti.start_date).format('HH[h]mm');
                let return_hour = moment.utc(last_iti.end_date).format('HH[h]mm');
                let split_origin_hour = origin_hour.split("h");
                let split_return_hour = return_hour.split("h");
                if (parseInt(split_origin_hour[1]) <= 30) {
                    split_origin_hour[1] = "30";
                } else if (parseInt(split_origin_hour[1]) <= 60) {
                    split_origin_hour[0] = (parseInt(split_origin_hour[0]) + 1).toString();
                    split_origin_hour[1] = "00";
                }
                if (parseInt(split_return_hour[1]) <= 30) {
                    split_return_hour[1] = "30";
                } else if (parseInt(split_return_hour[1]) <= 60) {
                    split_return_hour[0] = (parseInt(split_return_hour[0]) + 1).toString();
                    split_return_hour[1] = "00";
                }
                let new_start_hour = (parseInt(split_origin_hour[0]) + 1 < 10 ? "0" : "") + (parseInt(split_origin_hour[0]) + 1).toString() + "h" + split_origin_hour[1];
                let new_return_hour = (parseInt(split_return_hour[0]) - 3 < 10 ? "0" : "") + (parseInt(split_return_hour[0]) - 3).toString() + "h" + split_return_hour[1];
                new_search.push({
                    same_dest: false,
                    age: 25,
                    nb_vehicle: 1,
                    origin_dest: first_iti.destination,
                    origin_dest_error: false,
                    return_dest: last_iti.destination,
                    return_dest_error: false,
                    origin_date: moment.utc(first_iti.start_date),
                    origin_date_error: [],
                    return_date: moment.utc(last_iti.end_date),
                    date_error: false,
                    return_date_error: [],
                    origin_hour: new_start_hour,
                    origin_hour_error: false,
                    return_hour: new_return_hour,
                    return_hour_error: false,
                    contract: "",
                    country_code: first_iti.destination.data.country !== null ? first_iti.destination.data.country.country_code : "",
                    origin_datepicker: false,
                    return_datepicker: false
                });
            }
        });
        if (new_search.length === 0) {
            let last_iti = null;
            let first_iti = null;
            itinerary_list.map((itinerary, index) => {
                if (itinerary.step_type === "STEP" && (index === 0 || index === 1)) {
                    first_iti = itinerary;
                }
                if (itinerary.step_type === "STEP" && (index === itinerary_list.length - 1 || index === itinerary_list.length - 2)) {
                    last_iti = itinerary;
                }
            });
            if (last_iti !== null && first_iti !== null) {
                let origin_hour = moment.utc(first_iti.start_date).format('HH[h]mm');
                let return_hour = moment.utc(last_iti.end_date).format('HH[h]mm');
                let split_origin_hour = origin_hour.split("h");
                let split_return_hour = return_hour.split("h");
                if (parseInt(split_origin_hour[1]) <= 30) {
                    split_origin_hour[1] = "30";
                } else if (parseInt(split_origin_hour[1]) <= 60) {
                    split_origin_hour[0] = (parseInt(split_origin_hour[0]) + 1).toString();
                    split_origin_hour[1] = "00";
                }
                if (parseInt(split_return_hour[1]) <= 30) {
                    split_return_hour[1] = "30";
                } else if (parseInt(split_return_hour[1]) <= 60) {
                    split_return_hour[0] = (parseInt(split_return_hour[0]) + 1).toString();
                    split_return_hour[1] = "00";
                }
                let new_start_hour = (parseInt(split_origin_hour[0]) + 1 < 10 ? "0" : "") + (parseInt(split_origin_hour[0]) + 1).toString() + "h" + split_origin_hour[1];
                let new_return_hour = (parseInt(split_return_hour[0]) - 3 < 10 ? "0" : "") + (parseInt(split_return_hour[0]) - 3).toString() + "h" + split_return_hour[1];
                new_search.push({
                    same_dest: false,
                    age: 25,
                    nb_vehicle: 1,
                    origin_dest: first_iti.destination,
                    origin_dest_error: false,
                    return_dest: last_iti.destination,
                    return_dest_error: false,
                    origin_date: moment.utc(first_iti.start_date),
                    origin_date_error: [],
                    return_date: moment.utc(last_iti.end_date),
                    date_error: false,
                    return_date_error: [],
                    origin_hour: new_start_hour,
                    origin_hour_error: false,
                    return_hour: new_return_hour,
                    return_hour_error: false,
                    contract: "",
                    country_code: first_iti.destination.data.country !== null ? first_iti.destination.data.country.country_code : "",
                    origin_datepicker: false,
                    return_datepicker: false
                });
            }
        }
        console.log('new_search:', new_search);
        setSearch(new_search);
    };
    const triggerActivePage = (elem, page) => {
        setActivePage(page);
    };
    const getPage = (list, page) => {
        let page_list = [];
        for (let i = 15 * (page - 1); i < 15 * page && i < list.length; i++) {
            page_list.push(list[i]);
        }
        return page_list;
    };
    const getDisplayCars = () => {
        let filtered_cars = [];
        if (category_id === "all") {
            results.map((providers) => {
                let provider = providers.vehicles;
                Object.keys(provider).map((category) => {
                    provider[category].map((vehicle) => {
                        if (vehicle.station_prices !== null && vehicle.to_render) {
                            filtered_cars = [].concat(filtered_cars, vehicle);
                        }
                    });
                });
            });
        } else if (category_id !== null && provider_id !== null) {
            let find = results.find((provider, index_provider) => {
                return index_provider === provider_id;
            });
            if (find !== undefined) {
                find.vehicles[category_id].map((vehicle) => {
                    if (vehicle.station_prices !== null && vehicle.to_render) {
                        filtered_cars.push(vehicle);
                    }
                });
            }
        } else if (category_id !== null && provider_id === null) {
            results.map((provider) => {
                let prices = true;
                if (provider.vehicles[category_id] !== undefined) {
                    provider.vehicles[category_id].map((category) => {
                        if (category.station_prices === null && category.to_render) {
                            prices = false;
                        }
                    });
                    if (prices) {
                        filtered_cars = [].concat(filtered_cars, provider.vehicles[category_id]);
                    }
                }
            });
        } else if (category_id === null && provider_id !== null) {
            let provider = results[provider_id].vehicles;
            Object.keys(provider).map((category) => {
                provider[category].map((vehicle) => {
                    if (vehicle.station_prices !== null && vehicle.to_render) {
                        filtered_cars = [].concat(filtered_cars, vehicle);
                    }
                });
            });
        }
        filtered_cars = filtered_cars.sort((a, b) => {
            return parseFloat(a.station_prices[0].prices[0].selling_price) - parseFloat(b.station_prices[0].prices[0].selling_price);
        });
        let categories = [];
        filtered_cars.map(car => {
            let found = false;
            for (let i = 0; i < categories.length; i++) {
                if (categories[i][0].vehicle_info.code === car.vehicle_info.code) {
                    categories[i].push(car);
                    found = true;
                }
            }
            if (!found) {
                let type = [];
                type.push(car);
                categories.push(type);
            }
        });
        let final_results = [];
        categories.map(categorie => {
            categorie.map(car => {
                final_results.push(car);
            });
        });
        final_results = final_results.sort((a, b) => {
            return parseFloat(a.station_prices[0].prices[0].selling_price) - parseFloat(b.station_prices[0].prices[0].selling_price);
        });
        setDisplayCars(final_results);
        let list = getPage(final_results, 1);
        setPageList(list);
    };
    const checkInput = () => {
        return new Promise((resolve, reject) => {
            let error = false;
            let tmp_search = search.slice();
            let copy_search = [];
            tmp_search.map((rental, rental_index) => {
                let tmp_rental = Object.assign({}, rental);
                if (tmp_rental.origin_dest === null) {
                    error = true;
                    tmp_rental.origin_dest_error = true;
                }
                if (!tmp_rental.same_dest && tmp_rental.return_dest === null) {
                    error = true;
                    tmp_rental.return_dest_error = true;
                }
                if (moment(tmp_rental.origin_date).isSame(moment(tmp_rental.return_date), "day")) {
                    console.log('same_day');
                    if ((tmp_rental.origin_hour !== undefined || tmp_rental.origin_hour !== null) && (tmp_rental.return_hour !== undefined || tmp_rental.return_hour !== null)) {
                        let tmp_origin_hour = null;
                        let tmp_return_hour = null;
                        if (tmp_rental.origin_hour === t("global.midnight")) {
                            tmp_origin_hour = "00h00";
                        } else if (tmp_rental.origin_hour === t("global.noon")) {
                            tmp_origin_hour = "12h00";
                        } else {
                            tmp_origin_hour = tmp_rental.origin_hour;
                        }
                        if (tmp_rental.return_hour === t("global.midnight")) {
                            tmp_return_hour = "00h00";
                        } else if (tmp_rental.return_hour === t("global.noon")) {
                            tmp_return_hour = "12h00";
                        } else {
                            tmp_return_hour = tmp_rental.return_hour;
                        }
                        if (tmp_origin_hour !== undefined && tmp_return_hour !== undefined) {
                            let split_origin = tmp_origin_hour.split("h");
                            let split_return = tmp_return_hour.split("h");
                            if (parseInt(split_origin[0]) >= parseInt(split_return[0])) {
                                tmp_rental.origin_hour_error = true;
                                tmp_rental.return_hour_error = true;
                                error = true;
                            }
                        } else {
                            tmp_rental.origin_hour_error = true;
                            tmp_rental.return_hour_error = true;
                            error = true;
                        }
                    }
                }
                if (moment(tmp_rental.origin_date).isAfter(moment(tmp_rental.return_date))) {
                    error = true;
                    tmp_rental.date_error = true;
                }
                if (tmp_rental.origin_hour === undefined || tmp_rental.origin_hour === null) {
                    error = true;
                    tmp_rental.origin_hour_error = true;
                }
                if (tmp_rental.return_hour === undefined || tmp_rental.return_hour === null) {
                    error = true;
                    tmp_rental.return_hour_error = true;
                }
                if (tmp_rental.origin_date === null) {
                    error = true;
                    tmp_rental.origin_date_error = true;
                }
                if (tmp_rental.return_date === null) {
                    error = true;
                    tmp_rental.return_date_error = true;
                }
                tmp_rental.origin_datepicker = false;
                tmp_rental.return_datepicker = false;
                rental = tmp_rental;
                copy_search.push(rental);
            });
            if (!error) {
                let check_cart = checkCart();
                if (check_cart === null || check_cart === -1) {
                    setSearch(copy_search);
                    dispatch(InitCarsResult(0, 0, null));
                    setLoading(true);
                    dispatch({ type: "CARS_IS_RESULT" });
                    CheckAvailability(search, location_index, provider_list.filter(provider => provider.branch_code === all_data.branch_code), user, dispatch, t, setLoading).then(resolve).catch(reject);
                } else {
                    setInfoDialog(check_cart);
                    setIsOpen(true);
                    resolve();
                }
            } else {
                console.log("error:", copy_search);
                setSearch(copy_search);
                resolve();
            }
        });
    };
    const checkCart = () => {
        let already_in_cart = null;
        cart.map((car) => {
            already_in_cart = search.findIndex((step) => {
                return (car.booking_status === null || car.booking_status.status_booking !== "CANCELLED") && (moment(car.start_date).format("DD/MM/YYYY") === moment(step.origin_date).format("DD/MM/YYYY") || moment(car.end_date).format("DD/MM/YYYY") === moment(step.return_date).format("DD/MM/YYYY"));
            });
        });
        return already_in_cart;
    };
    const handleButton = () => {
        dispatch(InitCarsResult(0, 0, null));
        setLoading(true);
        setIsOpen(false);
        dispatch({ type: "CARS_IS_RESULT" });
        if (replaceProductContext.enableReplace) {
            replaceProductContext.onChangeLoading(true);
        }
        CheckAvailability(search, location_index, provider_list.filter(provider => provider.branch_code === all_data.branch_code), user, dispatch, t, setLoading, source).finally(() => {
            if (replaceProductContext.enableReplace) {
                replaceProductContext.onChangeLoading(false);
            }
        });
    };
    const openItinerary = () => {
        dispatch({ type: 'CAR_OPEN_ITINERARY' });
    };
    const handleOpen = (flag) => {
        setIsOpen(flag);
    };
    const getContractInfo = (value, tmp_search) => {
        dispatch({ type: "CARS_IS_CONTRACT" });
        setContractInfo(value);
        let selected_car = value.selected_car;
        let special_equipments = null;
        let country_code = null;
        if (cars_results.length > 0) {
            cars_results.map((provider) => {
                if (provider.provider_id === selected_car.provider_id && provider.subprovider_name === selected_car.subprovider) {
                    provider.stations.map((stations) => {
                        if (stations.code === selected_car.station_prices[0].pickup_station) {
                            special_equipments = stations.special_equipments;
                            country_code = stations.country_code;
                        }
                    });
                }
            });
        } else {
            country_code = selected_car.country_code;
            special_equipments = agency_options;
        }
        if (special_equipments) {
            special_equipments.map((elem) => {
                elem.check = false;
                elem.quantity = 0;
                if (selected_car.old_equipment && selected_car.old_equipment.length > 0) {
                    selected_car.old_equipment.map((item) => {
                        if (item.code_id === elem.code_id) {
                            elem.quantity = item.quantity;
                        }
                    });
                } else {
                    elem.quantity = 0;
                }
            });
        }
        dispatch({ type: "CARS_SET_AGENCY_OPTIONS", payload: { agency_options: special_equipments } });
        GetCarContract(value, (tmp_search !== undefined ? tmp_search : search), location_index, country_code, dispatch, t);
    };
    const renderHertzContracts = () => {
        if (car_contract !== null && car_contract.provider_name === 'Hertz GSA' && car_contract.selected !== undefined && car_contract.selected !== null) {
            let selected_contract = car_contract.contracts.find((contract) => contract.contract_id === car_contract.selected);
            let description = JSON.parse(car_contract.services[0].description)[selected_contract.name].Fare;
            description = description.replaceAll('&lt;', '<');
            description = description.replaceAll('&gt;', '>');
            return (
                <div dangerouslySetInnerHTML={{ __html: description }} />
            );
        }
        return null;
    };
    let selected_currency = null;
    let tmp_price = null;
    if (contract_info !== null) {
        let selected_car = contract_info.selected_car;
        if (selected_car.station_prices) {
            selected_car.station_prices.map((station) => {
                if (station.pickup_station === (selected_car.location_code_pickup ? selected_car.location_code_pickup : selected_car.station_prices[0].pickup_station)) {
                    selected_currency = currency_list.find((currency) => {
                        return currency.id === station.prices[0].selling_currency;
                    });
                    tmp_price = station.prices[0].selling_price;
                }
            });
        } else {
            tmp_price = selected_car.selling_price.selling_price;
            if (selected_car.currency.id !== undefined) {
                selected_currency = selected_car.currency;
            } else {
                selected_currency = currency_list.find((currency) => {
                    return currency.id === selected_car.currency;
                });
            }
        }
        if (car_contract !== null && car_contract.selected !== undefined) {
            let tmp_contract = car_contract.contracts.find((el) => {
                return el.contract_id === car_contract.selected;
            });
            tmp_price = tmp_contract.prices[0].selling_price;
            selected_currency = currency_list.find((currency) => {
                return (currency.id === tmp_contract.prices[0].selling_currency);
            });
        }
    }
    let selected_car_price = null;
    if (tmp_price !== null && selected_currency !== null) {
        selected_car_price 	= new Intl.NumberFormat(language, { style: 'currency', currency: selected_currency.iso_code }).format(tmp_price);
    }
    //console.log('search:', search);

    useEffect(() => {
        if (replaceProductContext.enableReplace) {
            (async () => {
                const startDestination = await getDestination(replaceProductInputs.pickupPlace?.id ?? -1);
                const endDestination = await getDestination(replaceProductInputs.returnPlace?.id ?? -1);
                setSearch([
                    {
                        same_dest: false,
                        age: 25,
                        nb_vehicle: 1,
                        origin_dest: startDestination,
                        origin_dest_error: false,
                        origin_station: replaceProductInputs.origin_station,
                        return_dest: endDestination,
                        return_dest_error: false,
                        return_station: replaceProductInputs.return_station,
                        origin_date: replaceProductInputs.startDate,
                        origin_date_error: [],
                        return_date: replaceProductInputs.endDate,
                        date_error: false,
                        return_date_error: [],
                        origin_hour: replaceProductInputs.startDate?.format('HH[h]mm'),
                        origin_hour_error: false,
                        return_hour: replaceProductInputs.endDate?.format('HH[h]mm'),
                        return_hour_error: false,
                        contract: replaceProductInputs.contract,
                        country_code: startDestination?.data?.country?.country_code ?? '',
                        origin_datepicker: false,
                        return_datepicker: false
                    }
                ]);
            })();
        }
    }, [replaceProductContext.enableReplace, replaceProductInputs]);

    useEffect(() => {
        if (replaceProductContext.enableReplace && replaceProductContext.extraData?.current) {
            const car = replaceProductContext.extraData.current;
            setReplaceProductInputs({
                age: 25,
                count: 1,
                startDate: window.moment.utc(car.start_date),
                endDate: window.moment.utc(car.end_date),
                pickupPlace: {
                    id: car.start_destination?.id ?? -1,
                    international_name: car.start_destination?.data?.international_name ?? '',
                    localization: car.start_destination?.data?.localization ?? []
                },
                returnPlace: {
                    id: car.end_destination?.id ?? -1,
                    international_name: car.end_destination?.data?.international_name ?? '',
                    localization: car.end_destination?.data?.localization ?? []
                },
                origin_station: null,
                return_station: null,
                contract: ''
            });
        }
    }, [replaceProductContext.extraData?.current]);

    if (replaceProductContext.enableReplace) {
        switch (replaceProductContext.step) {
            case CartConstructionReplaceProductModalStep.INPUTS : {
                replaceProductContext.onRegisterNextEvent(() => {
                    replaceProductContext.onChangeLoading(true);
                    checkInput().finally(() => {
                        replaceProductContext.onChangeLoading(false);
                    });
                    replaceProductContext.onHideNextButton();
                    replaceProductContext.onNextStep();
                });
                return (
                    <CartConstructionReplaceCarInputs
                        value={replaceProductInputs}
                        onChange={setReplaceProductInputs}
                    />
                );
            }
            case CartConstructionReplaceProductModalStep.ROOMS : {
                replaceProductContext.onIsFinalStep();
            }
        }
    }
    return (
        <Container className={classes.container}>
            <Grid container spacing={ 2 }>
                {
                    isForm && !replaceProductContext.enableReplace && (
                        <Grid item xs={ 12 } style={{ marginTop: 10 }}>
                            <Typography align={'center'} variant={"h5"} className={ classes.carsTitle} gutterBottom >{t("cars.cars_rental")}</Typography>
                        </Grid>
                    )
                }
                {
                    isResult && !replaceProductContext.enableReplace && (
                        <Fragment>
                            <Grid className={ `${classes.returnButtonContainer}` } container item xs={ 12 } alignItems={ "center" }>
                                <IconButton className={ `${classes.returnButton} ${classes.noPaddingMargin}` } onClick={() => {
                                    if (downDesktop) {
                                        setCategoryID(null);
                                        setProviderID(null);
                                    }
                                    setResults([]);
                                    setDisplayCars([]);
                                    setPageList([]);
                                    dispatch({ type: "CARS_IS_FORM" });
                                }}>
                                    <NavigateBefore fontSize={ "large" }/>
                                </IconButton>
                                <Typography className={ `${classes.bold} ${classes.marginLeft8}`}>{t("flight_search.edit_search")}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <CarLocationHeader location_index={location_index} car_index={car_index} search={search} results={results} setSearch={setSearch} elem={search[0]} checkInput={checkInput}/>
                                <Divider />
                            </Grid>
                        </Fragment>
                    )
                }
                {
                    isContract && !replaceProductContext.enableReplace && (
                        <Fragment>
                            <Grid className={ `${classes.returnButtonContainer} ${classes.noPaddingMargin}` } container item xs={ 12 } alignItems={ "center" } ref={backRef}>
                                <IconButton className={ `${classes.returnButton} ${classes.noPaddingMargin}` } onClick={() => {
                                    if (!from_cart) {
                                        dispatch({ type: "CARS_IS_RESULT" });
                                        dispatch({ type: "CARS_TOGGLE_TIMELINE", payload: { cars_timeline: true } });
                                        if (downDesktop) {
                                            setCategoryID(null);
                                            setProviderID(null);
                                            setContractInfo(null);
                                            setDisplayCars([]);
                                        }
                                    } else {
                                        dispatch({ type: "CARS_IS_FORM", payload: { from_cart: !from_cart } });
                                        dispatch({ type: 'CART_SET_STEP', payload: { step: 1 } });
                                        props.router.push(`/${window.url_name}/apps/cart/`);
                                    }
                                }}>
                                    <NavigateBefore fontSize={ "large" }/>
                                </IconButton>
                                <Typography className={ `${classes.bold} ${classes.marginLeft8}`}>{from_cart ? t("cars.cart_return") : t("cars.back_to_cars")}</Typography>
                            </Grid>
                        </Fragment>
                    )
                }
                {
                    isForm && !replaceProductContext.enableReplace && (
                        <Fragment>
                            <Grid container item xs={12} direction={'row-reverse'}>
                                <Grid item>
                                    <ShowItineraryStepperButton onClick={openItinerary}>
                                        {
                                            !open_itinerary ?
                                                t('cart-material.cart-construction-show-itinerary') :
                                                t('cart-material.cart-construction-hide-itinerary')
                                        }
                                    </ShowItineraryStepperButton>
                                </Grid>
                            </Grid>
                            {
                                <Grid container item justify={'center'} style={{ width: '100%' }}>
                                    {
                                        open_itinerary &&
                                        <Grid item xs={12}>
                                            <Box
                                                sx={{
                                                    position: 'sticky',
                                                    top: 70 + stickyHeaderOffset,
                                                    backgroundColor: '#fff',
                                                    zIndex: 2
                                                }}
                                            >
                                                <CarsItinerary
                                                    ref={stepperRef}
                                                    orientation="horizontal"
                                                    step={stepperStep}
                                                    steps={
                                                        itineraryContent?.state === 'success' ?
                                                            itineraryContent.data.content.map((item) => {
                                                                return {
                                                                    mode: 'by-step',
                                                                    content: item
                                                                };
                                                            }) :
                                                            []
                                                    }
                                                    setStep={(step) => dispatch(setStep(step))}
                                                    alternativeLabel
                                                    showStatus
                                                    disableDatesEdit={true}
                                                />
                                            </Box>
                                        </Grid>
                                    }
                                </Grid>
                            }
                            <div ref={inputRef}>
                                {
                                    search.map((elem, elem_index) => {
                                        return (
                                            <Grid item xs={ 12 } key={elem_index} style={{ marginTop: 10 }}>
                                                <RenderInput search={search} elem={elem} elem_index={elem_index} last_elem={elem_index === search.length - 1} setSearch={setSearch} edit={false} initState={initState} supplementProvider={supplementProvider}/>
                                            </Grid>
                                        );
                                    })
                                }
                            </div>
                            {
                                matches && (
                                    <Grid item xs={8}/>
                                )
                            }
                            <Grid container item sm={4} xs={12} className={!matches ? classes.marginBottom100 : ""}>
                                <Grid item xs={6}/>
                                <Grid item xs={6}>
                                    <Button variant={ "contained" } className={ `${classes.orangeButton} ${!matches ? classes.paddingButton : ""}` } fullWidth={ true } onClick={() => checkInput()}>{ t("global.search") }</Button>
                                </Grid>

                            </Grid>
                        </Fragment>
                    )
                }
                {
                    (
                        (isResult && !replaceProductContext.enableReplace) ||
                        (replaceProductContext.enableReplace && replaceProductContext.step === CartConstructionReplaceProductModalStep.PROVIDERS)
                    ) && (
                        <Grid item xs={12}>
                            {
                                !replaceProductContext.enableReplace &&
                                <RenderFilters setCategoryID={setCategoryID} setProviderID={setProviderID} setDisplayCars={setDisplayCars} setPageList={setPageList} getDisplayCars={getDisplayCars}/>
                            }
                            {
                                downDesktop && (
                                    <Fragment>
                                        {
                                            results.length > 0 ? (
                                                <RenderMatrix setProviderID={setProviderID} setCategoryID={setCategoryID}/>
                                            ) : (
                                                <>
                                                    {
                                                        !replaceProductContext.enableReplace &&
                                                        <RenderMatrixSkeleton/>
                                                    }
                                                    {
                                                        replaceProductContext.enableReplace &&
                                                        <Alert severity="info">
                                                            {t('shared.no-results-found')}
                                                        </Alert>
                                                    }
                                                </>
                                            )
                                        }
                                    </Fragment>
                                )
                            }
                            <Grid item ref={resultsRef} className={classes.container}>
                                {
                                    !downDesktop && !all_cars && (
                                        <Grid item container xs={12}>
                                            {
                                                !replaceProductContext.enableReplace &&
                                                <CarsSkeleton/>
                                            }
                                            {
                                                replaceProductContext.enableReplace &&
                                                <Alert severity="info">
                                                    {t('shared.no-results-found')}
                                                </Alert>
                                            }
                                        </Grid>
                                    )
                                }
                                {
                                    (downDesktop || (!downDesktop && all_cars)) && page_list.length > 0 && page_list.map((page_result, page_result_index) => {
                                        if (page_result.to_render) {
                                            return (
                                                <Fragment key={page_result_index}>
                                                    <RenderCars index={page_result_index} car={page_result} getContractInfo={getContractInfo} results={results}/>
                                                    {/* <RenderDisplayCars card={page_result} results={results} card_index={page_result_index} display_cars={display_cars} getContractInfo={getContractInfo}/> */}
                                                </Fragment>
                                            );
                                        }
                                    })
                                }
                            </Grid>
                            <Grid item xs={ 12 } container justify={ "center" } className={`${classes.marginBottom100} ${classes.marginTop15}`}>
                                <Grid item>
                                    <Pagination className={ classes.pagination } count={ Math.ceil(display_cars.length / 15) } variant={ "outlined" } hideNextButton={ (page_list === null || page_list.length === 0 || active_page === Math.ceil(display_cars.length / 15)) } hidePrevButton={ active_page === 1 } disabled={ page_list === null || page_list.length === 0 } onChange={ triggerActivePage } renderItem={(item) => <PaginationItem className={ `${item.selected ? classes.paginationItemActive : ""}` } {...item}/>}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    (
                        (isContract && !replaceProductContext.enableReplace) ||
                        (replaceProductContext.enableReplace && replaceProductContext.step === CartConstructionReplaceProductModalStep.ROOMS)
                    ) && (
                        <Grid item container direction={ "row-reverse" } spacing={ 8 } className={`${!downDesktop ? classes.marginBottom100 : ""}`}>
                            {
                                downDesktop && (
                                    <Grid item xs={ 12 } sm={ 4 } className={classes.paddingRight0}>
                                        <Card className={ classes.stickyPaper }>
                                            <div style={{ textAlign: 'center', fontSize: 20, backgroundColor: `${main_color}`, color: 'white', fontWeight: 'bold', padding: 10 }}>
                                                {t("accommodation.your_selection")}
                                            </div>
                                            <RenderCardContent
                                                contract_info={contract_info}
                                                car_contract={car_contract}
                                                user={user}
                                                cars_results={results}
                                                step_info={search}
                                                location_index={location_index}
                                                car_index={car_index}
                                                setLocationIndex={setLocationIndex}
                                                setCarIndex={setCarIndex}
                                                agency_options={agency_options}
                                                router={props.router}
                                                enqueueSnackbar={enqueueSnackbar}
                                                ref={backRef}
                                            />
                                        </Card>
                                    </Grid>
                                )
                            }
                            <Grid item xs={ 12 } md={ 8 }>
                                <div className={`${classes.cardTitle} ${classes.marginBottom15}`} ref={contractRef}>
                                    {t("cars.forfait")}
                                </div>
                                <Divider/>
                                <Grid container className={`${classes.marginTop15}`}>
                                    {
                                        !downDesktop && car_contract !== null && (
                                            <Grid item xs={ 12 } className={classes.marginBottom15}>
                                                <FormControl fullWidth className={classes.formControlRoot}>
                                                    <InputLabel id="contract-select-label">{t("cars.choose_contract")}</InputLabel>
                                                    <Select
                                                        MenuProps={{ disableScrollLock: true }}
                                                        labelId="contract-select-label"
                                                        id="contract-select"
                                                        value={car_contract.selected !== undefined ? car_contract.selected : ""}
                                                        onChange={(e) => dispatch({ type: "CARS_SELECTED_CONTRACT", payload: { contract_id: parseInt(e.target.value) } })}
                                                    >
                                                        {
                                                            car_contract.contracts.map((contract) => {
                                                                return (
                                                                    <MenuItem key={contract.contract_id} value={contract.contract_id}>
                                                                        {contract.name}
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )
                                    }
                                    {
                                        !downDesktop && (
                                            <Grid item sm={ !downDesktop ? 8 : 4 } xs={ 12 }>
                                                <RenderContractService car_contract={car_contract} contract_info={contract_info} />
                                            </Grid>
                                        )
                                    }
                                    {
                                        downDesktop && (
                                            <Grid item sm={ 12 } className={ `${classes.relativePosition} ${classes.textCenter}` }>
                                                <RenderContract car_contract={car_contract} contract_info={contract_info}/>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                {
                                    agency_options !== null && agency_options.length > 0 && (
                                        <Fragment>
                                            <div className={`${classes.cardTitle} ${classes.marginTop80} ${classes.marginBottom15}`}>
                                                {t("cars.optional_equipments_card")}
                                            </div>
                                            <Divider/>
                                            <RenderSpecialEquipments agency_options={agency_options} step_info={search} location_index={location_index}/>
                                        </Fragment>
                                    )
                                }
                                {
                                    contract_info !== null && Object.keys(contract_info).length !== 0 && (contract_info.selected_car.vehicle_info.return_fuel_full !== null
										|| contract_info.selected_car.vehicle_info.accident_franchise !== null
										|| contract_info.selected_car.vehicle_info.theft_franchise !== null
										|| contract_info.selected_car.vehicle_info.young_taxes_age !== null
										|| contract_info.selected_car.vehicle_info.young_taxes_amount !== null || contract_info.important_notice !== null
                                        || car_contract !== null && car_contract.provider_name === 'Hertz GSA') && (
                                        <div className="row white-bg ft-mt40">
                                            <div className="col-md-12 mt20 bold">
                                                {t("cars.important_information")}
                                            </div>
                                            <div className={ "car-info-title" }/>
                                            <div className="col-md-12">
                                                <ul>
                                                    {
                                                        contract_info.selected_car.vehicle_info.return_fuel_full !== null && (
                                                            <li>{contract_info.selected_car.vehicle_info.return_fuel_full ? t("cars.return_fuel_full") : t("cars.return_fuel_not_full") }</li>
                                                        )
                                                    }
                                                    {
                                                        contract_info.selected_car.vehicle_info.accident_franchise !== null && (
                                                            <li className={ "bold" }>{t("cars.accident_franchise") + new Intl.NumberFormat(language, { style: 'currency', currency: selected_currency.iso_code }).format(parseFloat(contract_info.selected_car.vehicle_info.accident_franchise))}</li>
                                                        )
                                                    }
                                                    {
                                                        contract_info.selected_car.vehicle_info.theft_franchise !== null && (
                                                            <li className={ "bold" }>{t("cars.theft_franchise") + new Intl.NumberFormat(language, { style: 'currency', currency: selected_currency.iso_code }).format(parseFloat(contract_info.selected_car.vehicle_info.theft_franchise))}</li>
                                                        )
                                                    }
                                                    {
                                                        contract_info.selected_car.vehicle_info.young_taxes_age !== null && (
                                                            <li className={ "bold" }>{t("cars.young_taxes_age") + contract_info.selected_car.vehicle_info.young_taxes_age + " " + t("global.years")}</li>
                                                        )
                                                    }
                                                    {
                                                        contract_info.selected_car.vehicle_info.young_taxes_amount !== null && (
                                                            <li className={ "bold" }>{t("cars.young_taxes_amount") + new Intl.NumberFormat(language, { style: 'currency', currency: selected_currency.iso_code }).format(parseFloat(contract_info.selected_car.vehicle_info.young_taxes_amount))}</li>
                                                        )
                                                    }
                                                </ul>
                                                {
                                                    ((contract_info !== null && contract_info.important_notice !== null && contract_info.important_notice !== '') || (car_contract !== null && car_contract.provider_name === 'Hertz GSA' && car_contract.selected !== undefined && car_contract.selected !== null)) && (
                                                        <Grid item xs={12}>
                                                            <Button variant={"outlined"} onClick={() => {
                                                                setImportantNotice(true);
                                                            }}>{t("cars.important_notice")}</Button>
                                                        </Grid>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </Grid>
                            {
                                !downDesktop && (
                                    <Grid container className={ classes.staticPriceContainer } direction={"row-reverse"} alignItems={ "center" } justify={ "space-evenly" }>
                                        <Grid item>
                                            <Button variant={ "contained" } className={ classes.orangeButton } fullWidth={ true } onClick={() => setMobileDialog(true) }>{ t("cars.contract_choice") }</Button>
                                        </Grid>
                                        <Grid item>
                                            <span className={classes.bold}>{selected_car_price}</span>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                    )
                }
            </Grid>
            {
                !downDesktop && (
                    <MobileCard
                        mobileDialog={mobileDialog}
                        setMobileDialog={setMobileDialog}
                        contract_info={contract_info}
                        car_contract={car_contract}
                        user={user}
                        cars_results={results}
                        step_info={search}
                        location_index={location_index}
                        car_index={car_index}
                        setLocationIndex={setLocationIndex}
                        setCarIndex={setCarIndex}
                        agency_options={agency_options}
                        router={props.router}
                        enqueueSnackbar={enqueueSnackbar}
                    />
                )
            }
            <Dialog open={ important_notice } onClose={ () => {
                setImportantNotice(false);
            } } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }>
                <DialogTitle onClose={ () => {
                    setImportantNotice(false);
                } }>
                    <Grid container justify={ "space-between" } alignItems={ "center" }>
                        <Grid item>
                            <IconButton edge={ "start" } onClick={ () => {
                                setImportantNotice(false);
                            } }><NavigateBefore/></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ textAlign: "justify" }}>
                    {
                        contract_info !== null && contract_info.important_notice !== undefined && contract_info.important_notice !== null && contract_info.important_notice !== "" && contract_info.important_notice.split('\n').map((str, str_index) => {
                            return (
                                <p key={str_index}>{str}</p>
                            );
                        })
                    }
                    { renderHertzContracts() }
                </DialogContent>
            </Dialog>
            <Dialog open={isOpen} onClose={() => {
                handleOpen(false);
            }}>
                <DialogContent>
                    <DialogContentText id={"alert-dialog-description"}>
	          			<span className={ `${classes.textCenter} ${classes.blockDisplay}`}>
	          				<WarningIcon style={{ color: "orange", fontSize: 110 }}/>
	          			</span>
                        {
                            info_dialogue !== null && info_dialogue !== -1 && (
                                <span>
			       					{t("cars.already_in_cart") + ". " + t("global.from") + " " + moment.utc(search[info_dialogue].origin_date).format("DD/MM/YYYY") + " " + t("global.to") + " " + moment.utc(search[info_dialogue].return_date).format("DD/MM/YYYY")}
			       				</span>
                            )
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleOpen(false);
                    }} color="primary">
                        {t("global.cancel")}
                    </Button>
                    <Button onClick={handleButton} color="primary" autoFocus>
                        {t("global.validate")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={contract_error !== null}>
                <DialogContent>
                    <DialogContentText id={"alert-dialog-description"}>
                        {
                            contract_error === "No contracts" && (
                                t("cars.no_contract")
                            )
                        }
                        {
                            contract_error !== "No contracts" &&
							contract_error
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        dispatch({ type: "CARS_IS_RESULT" });
                        dispatch({
                            type: "CARS_CONTRACT_ERROR",
                            payload: {
                                contract_error: null
                            }
                        });
                    }} color="primary" autoFocus>
                        {t("global.edit")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};
const ShowItineraryStepperButton = styled('button')((props) => ({
    border: 'none',
    background: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
    opacity: 0.6,
    fontSize: props.theme.typography.body2.fontSize
}));
export default React.memo(MaterialCarsSearch);
