import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    IconButton,
    Stack,
    Switch,
    Typography,
    styled
} from "@mui/material";

import {
    AttachMoney,
    DashboardOutlined,
    ExploreOutlined,
    ExtensionOutlined,
    InfoOutlined,
    PlaylistAdd,
    SecurityOutlined,
    ShoppingCart,
    SupportAgentOutlined,
    TimerOutlined,
    Tune,
    UploadFileOutlined,
    ViewDay,
    ViewList
} from "@mui/icons-material";
import { isEmpty, throttle, uniqBy } from "lodash";
import axios from "axios";
import LightTooltip from "../Menu/MaterialTripList/utils/tooltip/lightTooltip";
import CartMarginNotification from "./CartMarginNotification";
import CartGlobalTripPriceNotification from "./CartGlobalTripPriceNotification";
import CartUpdateNotification from "./CartUpdateNotification";
import CartRefreshPending from "./CartRefreshPending";
import CartStackNotification from "./CartStackNotification";
import CartNotCancellableNotification from "./CartNotCancellableNotification";
import CartAssistanceModal from "./CartAssistanceModal";
import CartGritchenModal from "./CartGritchenModal";
import { CartConstructionDaveModal } from "./CartConstructionDaveModal";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { CartConstructionProductsHeaderMargin } from "./CartConstructionProductsHeaderMargin";
import { CartConstructionProductsFilters } from "./CartConstructionProductsFilters";
import { CartPackageModal } from "./CartPackageModal";
import { usePackagedProducts } from "./utils/packagedProducts";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { CartConstructionProductsContext } from "./utils/cartConstructionProductsContext";
import {
    changePackageBooking,
    clearBookablePackages,
    setBookableManualProducts,
    setIsHeaderSticked,
    setProductsMode,
    setProductsView,
    setTextsMode
} from "./redux/cartConstructionReducer";
import { isProductPackaged } from "./utils/isProductPackaged";
import { isProductSamePackage } from "./utils/isProductSamePackage";
import { isProductBookable } from "./utils/isProductBookable";
import { useBookableProducts } from "./utils/bookableProducts";
import { useUnpackagedProducts } from "./utils/unpackagedProduts";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { AppState } from "../../Reducers/Reducers";
import { useSnackbar } from "notistack";

type Props = {
    stickedActions?: (content: React.ReactNode) => React.ReactNode,
    extraStickedActions?: () => React.ReactNode,
    cartDisplay: 'product' | 'chronological',
    enablePriceOnly?: boolean,
    onToggleCartDisplay: React.Dispatch<React.SetStateAction<"product" | "chronological">>,
    stickyOffset?: number,
    fullHeight?: boolean,
    disableProductsFilters?: boolean,
    alwaysSticky?: boolean,
    enabledButtons?: ('traveler-website' | 'dave')[],
    page: 'products' | 'contents' | 'booking'
}

export function CartConstructionProductsHeader(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const all_data = useSelector((state: AppState) => state.trip.all_data);
    const textsMode = useSelector((state: AppState) => state.cartConstruction.textsMode);
    const productsMode = useSelector((state: AppState) => state.cartConstruction.productsMode);
    const view = useSelector((state: AppState) => state.cartConstruction.productsView);
    const data_trip = useSelector((state: AppState) => state.trip.data_trip);
    const to_book = useSelector((state: AppState) => state.cart.to_book);
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const step = useSelector((state: AppState) => state.cart.step);
    const user = useSelector((state: AppState) => state.user.user);
    const bookablePackages = useSelector((state: AppState) => state.cartConstruction.bookablePackages);
    const showItineraryStep = useSelector((state: AppState) => state.cartConstruction.showItineraryStepper);
    const bookableManualProducts = useSelector((state: AppState) => state.cartConstruction.bookableManualProducts);
    const isSticked = useSelector((state: AppState) => state.cartConstruction.isHeaderSticked[props.page]);
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const terrestrialPrices = data_trip?.prices_terrestrial?.filter((item) => {
        return !isEmpty(item) &&
               !isProductPackaged({
                   product: item,
                   stackInfos: data_trip?.stack_info ?? null
               });
    }) ?? [];
    const flightPrices = data_trip?.prices_flight?.filter((item) => {
        return !isEmpty(item) &&
               !isProductPackaged({
                   product: item,
                   stackInfos: data_trip?.stack_info ?? null
               });
    }) ?? [];
    const [openFilters, setOpenFilters] = useState(false);
    const [openPackageCreateModal, setOpenPackageCreateModal] = useState(false);
    const [openDaveModal, setOpenDaveModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);
    const isStickyCallback = useCallback(
        throttle(
            () => {
                const stickyLimiterElement = document.getElementById('cart-construction-header-sticky-limiter');
                if (stickyLimiterElement && containerElement) {
                    if (
                        Math.abs(stickyLimiterElement.getBoundingClientRect().bottom - containerElement.getBoundingClientRect().y) <= 1
                    ) {
                        dispatch(setIsHeaderSticked({ page: props.page, sticked: true }));
                    } else if (!props.alwaysSticky) {
                        dispatch(setIsHeaderSticked({ page: props.page, sticked: false }));
                    }
                }
            },
            100
        ),
        [
            props.page,
            containerElement,
            props.alwaysSticky
        ]
    );
    const filtersButtonRef = useRef<HTMLButtonElement>(null);
    const context = useContext(CartConstructionProductsContext);
    const packagedProducts = usePackagedProducts();
    const unpackagedProducts = useUnpackagedProducts();
    const bookableProducts = useBookableProducts();
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    const selectableBookableManualProducts = useMemo(() => {
        const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
        return unpackagedProducts.accommodations?.filter((product) => {
            return product.type === 'manual' && isProductBookable(
                {
                    type: 'manual-accommodation',
                    item: product.accommodation
                },
                user,
                data_trip?.stack_info ?? [],
                quotationCode
            );
        }).map((item): ManualProduct => {
            return item.accommodation as ManualProduct;
        }).concat(
            unpackagedProducts.pois?.filter((product) => {
                return product.type === 'manual' && isProductBookable(
                    {
                        type: 'manual',
                        item: product.poi
                    },
                    user,
                    data_trip?.stack_info ?? [],
                    quotationCode
                );
            }).map((item): ManualProduct => {
                return item.poi as ManualProduct;
            }) ?? []
        ).concat(
            unpackagedProducts.transfers?.filter((product) => {
                return product.type === 'manual' && isProductBookable(
                    {
                        type: 'manual',
                        item: product.transfer
                    },
                    user,
                    data_trip?.stack_info ?? [],
                    quotationCode
                );
            }).map((item): ManualProduct => {
                return item.transfer as ManualProduct;
            }) ?? []
        ).concat(
            unpackagedProducts.cars?.filter((product) => {
                return product.type === 'manual' && isProductBookable(
                    {
                        type: 'manual',
                        item: product.car
                    },
                    user,
                    data_trip?.stack_info ?? [],
                    quotationCode
                );
            }).map((item): ManualProduct => {
                return item.car as ManualProduct;
            }) ?? []
        );
    }, [unpackagedProducts, user, data_trip])
    const allSelected = useMemo(() => {
        const toBookIdentifiers = to_book?.map((product) => {
            return `${product.product_type}-${product.id}`;
        });
        const packagesFromProducts = uniqBy(
            Object.keys(packagedProducts).map((key) => {
                const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
                const stackInfoId = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;
                return { stackNumber, stackInfoId };
            }),
            (item) => `${item.stackNumber}-${item.stackInfoId}`
        );
        return bookableProducts.filter((item) => {
            return !item.is_optional &&
                   !item.poi_type;
        }).every((product) => {
            return toBookIdentifiers?.includes(`${product.product_type}-${product.id}`);
        }) && packagesFromProducts.every((packageItem) => {
            return bookablePackages.findIndex((item) => {
                return isProductSamePackage(
                    {
                        stack_number: packageItem.stackNumber,
                        stack_info_id: packageItem.stackInfoId
                    },
                    item
                );
            }) >= 0;
        }) && (
            quotationCode !== 'volonline' ||
            bookableManualProducts.length === selectableBookableManualProducts.length
        );
    }, [
        user,
        to_book,
        bookableProducts,
        packagedProducts,
        bookablePackages,
        selectableBookableManualProducts,
        bookableManualProducts
    ]);
    const hasPackage = Object.keys(packagedProducts).length > 0;
    const initializedBookingSelector = useRef(false);

    const onCloseFilters = useCallback(
        () => {
            setOpenFilters(false);
        },
        [setOpenFilters]
    );

    const onToggleCartDisplay = () => {
        props.onToggleCartDisplay((state) => {
            return state === 'product' ?
                'chronological' :
                'product';
        });
    };

    const onToggleFilters = () => {
        setOpenFilters((state) => !state);
    };

    const onToggleView = () => {
        if (view === 'table') {
            dispatch(setProductsView('cards'));
        } else {
            dispatch(setProductsView('table'));
        }
    };

    const onChangeMode = (checked: boolean) => {
        if (props.page === 'contents') {
            dispatch(setTextsMode(checked ? 'all-days' : 'current-day'));
        } else {
            dispatch(setProductsMode(checked ? 'all-days' : 'current-day'));
        }
    };

    const onOpenManualProductModal = () => {
        dispatch({ type: 'CART_CREATE_NEW_MANUAL_PRODUCT' });
    };

    const onAutomaticProductsToggle = () => {
        dispatch({ type: "CART_TOGGLE_AUTOMATIC_PRODUCTS_MODAL" });
    };

    const onOpenPackageModal = () => {
        setOpenPackageCreateModal(true);
    };

    const onFullTripPrice = () => {
        dispatch({ type: 'CART_FULL_TRIP_PRICE' });
    };

    const onOpenTravelerWebsite = () => {
        if (
            quotationCode &&
            ['cercledesvoyages', 'americavoyages', 'beachcomber', 'connections', 'autigrevanille', 'terreslointaines', 'tropicalementvotre', 'touristforum', 'continentsinsolites'].includes(quotationCode)
        ) {
            window.open(
                `${window.origin}/quotation/trip?trip=${all_data?.id}&token=${all_data?.token}`,
                "_blank"
            );
        } else {
            window.open(
                `${window.origin}/${window.url_name}/summary-quotation/${all_data?.id}/${all_data?.current_version}/${all_data?.token}`,
                "_blank"
            );
        }
    };

    const onCreateDave = () => {
        const { pass_check, headers } = CheckBeforeRequest();
        const version = parseInt(GetCookie('trip_id_version') ?? '-1');
        if (pass_check) {
            setLoading(true);
            axios({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${tripId}/versions/${version}/dave-mcto/notify/`,
                headers: headers
            }).then(function () {
                enqueueSnackbar(t<string>("cart-material.cart-construction-dave-create-success"), { variant: "success", disableWindowBlurListener: true });
            }).catch(function (error) {
                console.log(error.responseJSON);
                enqueueSnackbar(t<string>("cart-material.cart-construction-dave-create-error"), { variant: "error", disableWindowBlurListener: true });
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const onChangeSelection = (checked: boolean) => {
        const onSelectAll = () => {
            dispatch({
                type: 'CART_SET_TO_BOOK', 
                payload: bookableProducts.filter((item) => {
                    return !item.is_optional &&
                           !item.poi_type;
                })
            });
            if (quotationCode === 'volonline') {
                dispatch(setBookableManualProducts(selectableBookableManualProducts));
            }
            const packagesFromProducts = Object.keys(packagedProducts).map((key) => {
                const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
                const stackInfoId = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;
                return { stackNumber, stackInfoId };
            }).filter((product) => {
                const stackInfo = data_trip?.stack_info?.find((item) => {
                    return isProductSamePackage(
                        {
                            stack_info_id: item.id,
                            stack_number: item.stack_number
                        },
                        {
                            stack_info_id: product.stackInfoId,
                            stack_number: product.stackNumber
                        }
                    );
                });
                return !stackInfo?.booking_status;
            });
            for (const item of packagesFromProducts) {
                dispatch(
                    changePackageBooking({
                        stack_info_id: item.stackInfoId,
                        stack_number: item.stackNumber,
                        checked: true
                    })
                );
            }
        };
        const onDeselectAll = () => {
            dispatch({ type: 'CART_SET_TO_BOOK', payload: [] });
            dispatch(setBookableManualProducts([]));
            dispatch(clearBookablePackages());
        };
        if (checked) {
            onSelectAll();
        } else {
            onDeselectAll();
        }
    };

    const onToggleAssistance = (type: number) => {
        const travelers_ids: number[] = [];
        travelers?.map(traveler => travelers_ids.push(traveler.id));
        dispatch({ type: 'CART_TOGGLE_ASSISTANCE', payload: { travelers_ids, type } });
    };

    useEffect(() => {
        if (props.alwaysSticky && !isSticked) {
            dispatch(setIsHeaderSticked({ page: props.page, sticked: true }));
        }
    }, [isSticked, props.page, props.alwaysSticky]);

    useEffect(() => {
        if (
            step === 4 &&
            !initializedBookingSelector.current &&
            to_book &&
            to_book.length === 0
        ) {
            onChangeSelection(true);
            initializedBookingSelector.current = true;
        } else if (
            step !== 4 &&
            (
                (
                    to_book &&
                    to_book.length > 0
                ) ||
                bookablePackages.length > 0
            )
        ) {
            onChangeSelection(false);
            initializedBookingSelector.current = false;
        }
    }, [
        step,
        to_book,
        bookableProducts,
        packagedProducts,
        bookablePackages
    ]);

    useEffect(() => {
        document.addEventListener(
            'scroll',
            isStickyCallback
        );
        return () => {
            document.removeEventListener(
                'scroll',
                isStickyCallback
            );
        };
    }, [containerElement, isStickyCallback]);

    return (
        <>
            <Box
                ref={(element: HTMLDivElement | null) => {
                    setContainerElement(element);
                }}
                sx={(theme) => ({
                    padding: 2.5,
                    paddingRight: 0,
                    paddingTop: isSticked ? 0 : undefined,
                    position: 'sticky',
                    top: (props.fullHeight ? 70 : 0) + (props.stickyOffset ?? 0),
                    backgroundColor: '#fff',
                    zIndex: !isSticked ? 2 : 4,
                    marginLeft: theme.spacing(-2.5),
                    width: `calc(100% + ${theme.spacing(2.5)})`
                })}>
                {
                    showItineraryStep &&
                    !isSticked &&
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            textTransform: 'uppercase',
                            marginBottom: 1
                        }}
                    >
                        <Typography variant="caption" sx={{ fontWeight: 700 }}>
                            {
                                all_data?.is_by_day_view ?
                                    t<string>('cart-material.cart-construction-current-day') :
                                    t<string>('cart-material.cart-construction-current-step')
                            }
                        </Typography>
                        <Switch
                            size="small"
                            checked={
                                props.page === 'contents' ?
                                    textsMode === 'all-days' :
                                    productsMode === 'all-days'
                            }
                            onChange={(_, checked) => onChangeMode(checked)}
                        />
                        <Typography variant="caption" sx={{ fontWeight: 700 }}>
                            {
                                all_data?.is_by_day_view ?
                                    t<string>('cart-material.cart-construction-all-days') :
                                    t<string>('cart-material.cart-construction-all-steps')
                            }
                        </Typography>
                    </Stack>
                }
                <Stack
                    direction="row"
                    justifyContent={
                        !props.disableProductsFilters || isSticked ?
                            'space-between' :
                            'flex-end'
                    }
                    flexWrap="wrap"
                >
                    {
                        isSticked &&
                        props.extraStickedActions &&
                        <Box sx={{ marginBottom: 1.5 }}>
                            {props.extraStickedActions()}
                        </Box>
                    }
                    {
                        !props.disableProductsFilters &&
                        <Stack
                            direction="row"
                            alignItems={
                                isSticked ?
                                    'flex-end' :
                                    'center'
                            }
                            sx={{ marginBottom: 1.5 }}
                        >
                            <LightTooltip title={ t<string>('shared.filters') }>
                                <IconButton
                                    ref={filtersButtonRef}
                                    size="small"
                                    onClick={onToggleFilters}
                                    color="inherit"
                                >
                                    <Tune />
                                </IconButton>
                            </LightTooltip>
                            <LightTooltip
                                title={
                                    props.cartDisplay === 'product' ?
                                        t<string>('cart.display-cart-chronological') :
                                        t<string>('cart.display-cart-product')
                                }
                                disableInteractive
                            >
                                <IconButton
                                    size="small"
                                    onClick={onToggleCartDisplay}
                                    color="inherit"
                                >
                                    {
                                        props.cartDisplay === 'product' ?
                                            <TimerOutlined /> :
                                            <ShoppingCart />
                                    }
                                </IconButton>
                            </LightTooltip>
                            <LightTooltip
                                title={
                                    view === 'cards' ?
                                        t<string>('cart-material.cart-construction-change-table-view') :
                                        t<string>('cart-material.cart-construction-change-normal-view')
                                }
                                disableInteractive
                            >
                                <IconButton
                                    size="small"
                                    onClick={onToggleView}
                                    color="inherit"
                                >
                                    {
                                        view === 'cards' &&
                                        <ViewList />
                                    }
                                    {
                                        view === 'table' &&
                                        <ViewDay />
                                    }
                                </IconButton>
                            </LightTooltip>
                        </Stack>
                    }
                    {
                        isSticked &&
                        !context.enableBooking &&
                        props.stickedActions &&
                        props.stickedActions(
                            <Stack
                                direction="row"
                                alignItems="flex-start"
                                justifyContent="flex-end"
                                flexWrap="wrap"
                                spacing={1}
                                rowGap={1}
                            >
                                {
                                    !props.enabledButtons &&
                                    <LightTooltip title={t<string>('cart-material.add-manual-product')}>
                                        <RoundButton
                                            size="small"
                                            variant="outlined"
                                            color="inherit"
                                            startIcon={<PlaylistAdd fontSize="small" />}
                                            onClick={onOpenManualProductModal}
                                            sx={{
                                                "color": '#737373',
                                                "marginBottom": 0,
                                                '& .MuiButton-startIcon': {
                                                    marginRight: 0
                                                }
                                            }}
                                        />
                                    </LightTooltip>
                                }
                                {
                                    !props.enabledButtons &&
                                    !['visiteurs'].includes(quotationCode) &&
                                    user?.client_full?.type !== 2 &&
                                    <LightTooltip title={t<string>('cart-material.add-automatic-product')}>
                                        <RoundButton
                                            size="small"
                                            variant="outlined"
                                            color="inherit"
                                            startIcon={<ExtensionOutlined />}
                                            onClick={onAutomaticProductsToggle}
                                            sx={{
                                                "color": '#737373',
                                                "marginBottom": 0,
                                                '& .MuiButton-startIcon': {
                                                    marginRight: 0
                                                }
                                            }}
                                        />
                                    </LightTooltip>
                                }
                                {
                                    !props.enabledButtons &&
                                    user?.client_full?.type !== 2 &&
                                    <LightTooltip
                                        title={
                                            !hasPackage ?
                                                t<string>('cart-material.create-package') :
                                                t<string>('cart-material.edit-package')
                                        }
                                    >
                                        <RoundButton
                                            size="small"
                                            variant="outlined"
                                            color="inherit"
                                            startIcon={<DashboardOutlined />}
                                            onClick={onOpenPackageModal}
                                            sx={{
                                                "color": '#737373',
                                                "marginBottom": 0,
                                                '& .MuiButton-startIcon': {
                                                    marginRight: 0
                                                }
                                            }}
                                        />
                                    </LightTooltip>
                                }
                                {
                                    !props.enabledButtons && user?.client_full?.type !== 2 &&
                                    <LightTooltip
                                        title={
                                            (data_trip?.prices_flight?.length ?? 0) > 0 ||
                                                (data_trip?.prices_terrestrial?.length ?? 0) > 0 ?
                                                t<string>('cart-material.edit-full-trip-price') :
                                                t<string>('cart-material.full-trip-price')
                                        }
                                    >
                                        <RoundButton
                                            size="small"
                                            variant="outlined"
                                            color="inherit"
                                            startIcon={<AttachMoney />}
                                            onClick={onFullTripPrice}
                                            sx={{
                                                "color": '#737373',
                                                "marginBottom": 0,
                                                '& .MuiButton-startIcon': {
                                                    marginRight: 0
                                                }
                                            }}
                                        />
                                    </LightTooltip>
                                }
                                {
                                    (
                                        !props.enabledButtons ||
                                        props.enabledButtons.includes('traveler-website')
                                    ) &&
                                    ['cercledesvoyages', 'americavoyages', 'touristforum', 'continentsinsolites'].includes(quotationCode) &&
                                    quotationCode !== 'verdie' &&
                                    <LightTooltip title={t<string>('cart-material.cart-construction-traveler-website-hint')}>
                                        <RoundButton
                                            size="small"
                                            variant="outlined"
                                            color="inherit"
                                            startIcon={<ExploreOutlined />}
                                            onClick={onOpenTravelerWebsite}
                                            sx={{
                                                "color": '#737373',
                                                "marginBottom": 0,
                                                '& .MuiButton-startIcon': {
                                                    marginRight: 0
                                                }
                                            }}
                                        />
                                    </LightTooltip>
                                }
                                {
                                    (
                                        !props.enabledButtons ||
                                        props.enabledButtons.includes('dave')
                                    ) &&
                                    quotationCode === 'marcovasco' &&
                                    <>
                                        <LightTooltip title={t<string>('cart-material.cart-construction-dave-button')}>
                                            <RoundButton
                                                size="small"
                                                variant="outlined"
                                                color="inherit"
                                                startIcon={<UploadFileOutlined />}
                                                onClick={onCreateDave}
                                                sx={{
                                                    "color": '#737373',
                                                    "marginBottom": 0,
                                                    '& .MuiButton-startIcon': {
                                                        marginRight: 0
                                                    }
                                                }}
                                            />
                                        </LightTooltip>
                                        <LightTooltip title={t<string>('cart-material.cart-construction-dave-list')}>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => setOpenDaveModal(true)}
                                                sx={{
                                                    "padding": 0,
                                                    "marginTop": '2px !important',
                                                    "marginBottom": 0,
                                                    '& .MuiButton-startIcon': {
                                                        marginRight: 0
                                                    }
                                                }}
                                            >
                                                <InfoOutlined />
                                            </IconButton>
                                        </LightTooltip>
                                        {
                                            ['cercledesvoyages', 'tropicalementvotre', 'verdie', 'continentsinsolites', 'marcovasco', 'visiteurs'].includes(quotationCode) &&
                                            <LightTooltip title={t<string>('cart-material.cart-construction-support-hint')}>
                                                <RoundButton
                                                    size="small"
                                                    variant="outlined"
                                                    color="inherit"
                                                    startIcon={<SupportAgentOutlined />}
                                                    onClick={() => onToggleAssistance(0)}
                                                    sx={{
                                                        "color": '#2196F3',
                                                        "marginBottom": 0,
                                                        '& .MuiButton-startIcon': {
                                                            marginRight: 0
                                                        }
                                                    }}
                                                />
                                            </LightTooltip>
                                        }
                                    </>
                                }
                                {
                                    (
                                        ['cercledesvoyages', 'americavoyages', 'tropicalementvotre', 'terreslointaines', 'continentsinsolites'].includes(quotationCode) ||
                                        (
                                            quotationCode === 'verdie' &&
                                            window.location.hostname.split('.')[1] === 'fr'
                                        )
                                    ) &&
                                    !props.enabledButtons &&
                                    <LightTooltip title={t<string>('cart-material.cart-construction-insurance-hint')}>
                                        <RoundButton
                                            size="small"
                                            variant="outlined"
                                            color="inherit"
                                            startIcon={<SecurityOutlined />}
                                            onClick={() => onToggleAssistance(1)}
                                            sx={{
                                                "color": '#00E5FF',
                                                "marginBottom": 0,
                                                '& .MuiButton-startIcon': {
                                                    marginRight: 0
                                                }
                                            }}
                                        />
                                    </LightTooltip>
                                }
                            </Stack>
                        )
                    }
                    {
                        context.enableBooking &&
                        props.stickedActions &&
                        props.stickedActions(
                            <Stack
                                direction="row"
                                spacing={1.5}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="caption" lineHeight={1}>
                                    { t<string>('cart-material.deselect-all') }
                                </Typography>
                                <Switch
                                    size="small"
                                    onChange={(_, checked) => onChangeSelection(checked)}
                                    checked={allSelected}
                                />
                                <Typography variant="caption" lineHeight={1}>
                                    { t<string>('cart-material.select-all') }
                                </Typography>
                            </Stack>
                        )
                    }
                    {
                        !isSticked &&
                        !context.enableBooking &&
                        <Stack
                            direction="row"
                            alignItems="flex-start"
                            flexWrap="wrap"
                            spacing={1}
                        >
                            {
                                !props.enabledButtons &&
                                <LightTooltip title={t<string>('cart-material.add-manual-product')}>
                                    <RoundButton
                                        size="small"
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<PlaylistAdd fontSize="small" />}
                                        onClick={onOpenManualProductModal}
                                    >
                                        <Box component="span" sx={{ alignSelf: 'flex-end' }}>
                                            {t<string>('cart-material.cart-construction-manual')}
                                        </Box>
                                    </RoundButton>
                                </LightTooltip>
                            }
                            {
                                !props.enabledButtons &&
                                !['visiteurs'].includes(quotationCode) &&
                                user?.client_full?.type !== 2 &&
                                <LightTooltip title={t<string>('cart-material.add-automatic-product')}>
                                    <RoundButton
                                        size="small"
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<ExtensionOutlined />}
                                        onClick={onAutomaticProductsToggle}
                                    >
                                        <Box component="span" sx={{ alignSelf: 'flex-end' }}>
                                            {t<string>('cart-material.cart-construction-annex')}
                                        </Box>
                                    </RoundButton>
                                </LightTooltip>
                            }
                            {
                                !props.enabledButtons &&
                                user?.client_full?.type !== 2 &&
                                <LightTooltip
                                    title={
                                        !hasPackage ?
                                            t<string>('cart-material.create-package') :
                                            t<string>('cart-material.edit-package')
                                    }
                                >
                                    <RoundButton
                                        size="small"
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<DashboardOutlined />}
                                        onClick={onOpenPackageModal}
                                    >
                                        <Box component="span" sx={{ alignSelf: 'flex-end' }}>
                                            {t<string>('cart-material.cart-construction-package')}
                                        </Box>
                                    </RoundButton>
                                </LightTooltip>
                            }
                            {
                                !props.enabledButtons && user?.client_full?.type !== 2 &&
                                <LightTooltip
                                    title={
                                        (data_trip?.prices_flight?.length ?? 0) > 0 ||
                                        (data_trip?.prices_terrestrial?.length ?? 0) > 0 ?
                                            t<string>('cart-material.edit-full-trip-price') :
                                            t<string>('cart-material.full-trip-price')
                                    }
                                >
                                    <RoundButton
                                        size="small"
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<AttachMoney />}
                                        onClick={onFullTripPrice}
                                    >
                                        <Box component="span" sx={{ alignSelf: 'flex-end' }}>
                                            {t<string>('cart-material.cart-construction-trip')}
                                        </Box>
                                    </RoundButton>
                                </LightTooltip>
                            }
                            {
                                (
                                    !props.enabledButtons ||
                                    props.enabledButtons.includes('traveler-website')
                                ) &&
                                ['cercledesvoyages', 'americavoyages', 'touristforum', 'continentsinsolites'].includes(quotationCode) &&
                                <LightTooltip title={t<string>('cart-material.cart-construction-traveler-website-hint')}>
                                    <RoundButton
                                        size="small"
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<ExploreOutlined />}
                                        onClick={onOpenTravelerWebsite}
                                    >
                                        <Box component="span" sx={{ alignSelf: 'flex-end' }}>
                                            {t<string>('cart-material.cart-construction-traveler-website')}
                                        </Box>
                                    </RoundButton>
                                </LightTooltip>
                            }
                            {
                                (
                                    !props.enabledButtons ||
                                    props.enabledButtons.includes('dave')
                                ) &&
                                quotationCode === 'marcovasco' &&
                                <>
                                    <LightTooltip title={t<string>('cart-material.cart-construction-dave-button')}>
                                        <RoundButton
                                            size="small"
                                            variant="outlined"
                                            color="inherit"
                                            startIcon={<UploadFileOutlined />}
                                            onClick={onCreateDave}
                                        >
                                            <Box component="span" sx={{ alignSelf: 'flex-end' }}>
                                                {t<string>('cart-material.cart-construction-dave-button')}
                                            </Box>
                                        </RoundButton>
                                    </LightTooltip>
                                    <LightTooltip title={t<string>('cart-material.cart-construction-dave-list')}>
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            onClick={() => setOpenDaveModal(true)}
                                            sx={{
                                                "padding": 0,
                                                "marginTop": '4px !important',
                                                "marginBottom": 0,
                                                '& .MuiButton-startIcon': {
                                                    marginRight: 0
                                                }
                                            }}
                                        >
                                            <InfoOutlined />
                                        </IconButton>
                                    </LightTooltip>
                                </>
                            }
                            {
                                (
                                    ['cercledesvoyages', 'tropicalementvotre', 'verdie', 'continentsinsolites', 'visiteurs', 'marcovasco'].includes(quotationCode) ||
                                    (
                                        quotationCode === 'verdie' &&
                                        window.location.hostname.split('.')[2] === 'fr'
                                    )
                                ) &&
                                !props.enabledButtons &&
                                <LightTooltip title={t<string>('cart-material.cart-construction-support-hint')}>
                                    <RoundButton
                                        size="small"
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<SupportAgentOutlined />}
                                        onClick={() => onToggleAssistance(0)}
                                        sx={{ color: '#2196F3' }}
                                    >
                                        <Box component="span" sx={{ alignSelf: 'flex-end' }}>
                                            {t<string>('cart-material.cart-construction-support')}
                                        </Box>
                                    </RoundButton>
                                </LightTooltip>
                            }
                            {
                                (
                                    ['cercledesvoyages', 'americavoyages', 'tropicalementvotre', 'terreslointaines'].includes(quotationCode) ||
                                    (
                                        quotationCode === 'verdie' &&
                                        window.location.hostname.split('.')[1] === 'fr'
                                    )
                                ) &&
                                !props.enabledButtons &&
                                <LightTooltip title={t<string>('cart-material.cart-construction-insurance-hint')}>
                                    <RoundButton
                                        size="small"
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<SecurityOutlined />}
                                        onClick={() => onToggleAssistance(1)}
                                        sx={{ color: '#00E5FF' }}
                                    >
                                        <Box component="span" sx={{ alignSelf: 'flex-end' }}>
                                            {t<string>('cart-material.cart-construction-insurance')}
                                        </Box>
                                    </RoundButton>
                                </LightTooltip>
                            }
                        </Stack>
                    }
                </Stack>
                {
                    !props.alwaysSticky &&
                    <CartConstructionProductsHeaderMargin />
                }
            </Box>
            {
                !props.disableProductsFilters &&
                <Box sx={{ marginTop: 3 }}>
                    <Stack
                        direction="row"
                        flexWrap="wrap"
                        alignItems="flex-start"
                        gap={1}
                        sx={{
                            '& > *': {
                                width: 'calc(50% - 4px)'
                            }
                        }}
                    >
                        <CartMarginNotification />
                        {
                            (
                                terrestrialPrices.length > 0 ||
                                flightPrices.length > 0
                            ) &&
                            <CartGlobalTripPriceNotification />
                        }
                        <CartUpdateNotification />
                        <CartRefreshPending />
                        <CartStackNotification />
                        <CartNotCancellableNotification />
                    </Stack>
                </Box>
            }
            {
                openFilters &&
                <CartConstructionProductsFilters
                    anchorEl={filtersButtonRef.current}
                    onClose={onCloseFilters}
                />
            }
            {
                openPackageCreateModal &&
                <CartPackageModal onClose={() => setOpenPackageCreateModal(false)} />
            }
            {
                ['americavoyages'].includes(quotationCode) ? <CartAssistanceModal /> : <CartGritchenModal/>
            }
            {
                openDaveModal &&
                <CartConstructionDaveModal onClose={() => setOpenDaveModal(false)} />
            }
            <LoadingBackDrop open={loading} />
        </>
    );
}

const RoundButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
}));
