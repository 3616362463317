import { isNumber } from "lodash";
import { isProductSamePackage } from "../../../CartMaterial/utils/isProductSamePackage";
import GetPricesId from "../../../Cart/Functions/Margin/GetPricesId";
import ConvertPrice from "../../../Cart/Functions/Manual/ConvertPrice";
import GetProvider from "../../../Common/Functions/GetProvider";
import { isProductPackaged } from "../../../CartMaterial/utils/isProductPackaged";
import GetProductType from "../../TripList/Functions/GetProductType.js";
import moment from "moment";

export function GetExportData(list_currency, trip_currency, big_cart, manual_cart, providers, manual_providers, trip_info, data_trip, packagedProducts, user, owner_id, getPrice, t) {
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    let tmp_data: { product: null; provider: null; description: null; room_name: null; reference: null; booking_status: null; amount_1: null; rate: null; amount_2: null; amount_3: null; date: null; id: null; }[] = [];
    let tmp_data1 = [[]];
    tmp_data1[0].push(t("menu.file_nb"));
    tmp_data1[0].push(t("global.date"));
    tmp_data1[0].push(t("menu.trip_list.product"));
    tmp_data1[0].push(t("filters.providers"));
    tmp_data1[0].push(t("global.description"));
    tmp_data1[0].push(t("global.room"));
    tmp_data1[0].push(t("menu.trip_list.reference"));
    tmp_data1[0].push(t("cart.edit_booking.booking_status"));
    tmp_data1[0].push(t("menu.trip_list.amount_purchase"));
    tmp_data1[0].push(t("menu.trip_list.rate"));
    tmp_data1[0].push(t("menu.trip_list.amount"));
    tmp_data1[0].push(t("menu.trip_list.amount_selling"));
    if (big_cart !== null) {
        let id = (trip_info.accounting_reference !== undefined && trip_info.accounting_reference !== null) ? trip_info.trip_reference + "-" + trip_info.accounting_reference : trip_info.trip_reference;
        if (manual_cart) {
            Object.keys(packagedProducts).map((key) => {
                const stackNumber = isNumber(key.split('-')[0]) ? parseInt(key.split('-')[0]) : null;
                const stackInfoId = isNumber(key.split('-')[1]) ? parseInt(key.split('-')[1]) : null;
                const flightPrices = data_trip?.prices_flight?.filter((item: { stack_number: number | null; stack_info_id: number | null; }) => {
                    return isProductSamePackage(
                        item,
                        {
                            stack_number: stackNumber,
                            stack_info_id: stackInfoId
                        }
                    );
                }) ?? [];
                const terrestrialPrices = data_trip?.prices_terrestrial?.filter((item: { stack_number: number | null; stack_info_id: number | null; }) => {
                    return isProductSamePackage(
                        item,
                        {
                            stack_number: stackNumber,
                            stack_info_id: stackInfoId
                        }
                    );
                }) ?? [];
                let purchase_price_flight = 0;
                let selling_price_flight = 0;
                let purchase_price_terrestrial = 0;
                let selling_price_terrestrial = 0;
                let currency_buy_flight = null;
                let currency_sell_flight = null;
                let converted_price_flight = 0;
                let currency_buy_terrestrial = null;
                let currency_sell_terrestrial = null;
                let converted_price_terrestrial = 0;
                let factor_used_terrestrial = null;
                let factor_used_flight = null;
                let tmp_provider = null;
                if (flightPrices !== undefined && flightPrices.length !== 0) {
                    let tmp_obj = {
                        product: null,
                        provider: null,
                        description: null,
                        room_name: null,
                        reference: null,
                        booking_status: null,
                        amount_1: null,
                        rate: null,
                        amount_2: null,
                        amount_3: null,
                        date: null,
                        id: null
                    };
                    let tmp_arr = [];
                    purchase_price_flight = parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, true)].purchase_price);
                    selling_price_flight = parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, quotation_code !== "americavoyages")].selling_price);
                    currency_buy_flight = list_currency.find((currency: { id: any; }) => {
                        return currency.id === flightPrices[GetPricesId(flightPrices, owner_id, user, true)].purchase_currency; 
                    });
                    currency_sell_flight = list_currency.find((currency: { id: any; }) => {
                        return currency.id === flightPrices[GetPricesId(flightPrices, owner_id, user, quotation_code !== "americavoyages")].selling_currency; 
                    });
                    converted_price_flight = parseFloat(ConvertPrice(purchase_price_flight, trip_currency, currency_buy_flight, parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, true)].factor_used)));
                    factor_used_flight = parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, true)].factor_used).toFixed(2);
                    tmp_provider = GetProvider(providers, terrestrialPrices[0].provider);
                    if (tmp_provider === null) {
                        tmp_provider = GetProvider(manual_providers, terrestrialPrices[0].provider);
                    }
                    if (purchase_price_flight !== 0 && selling_price_flight !== 0) {
                        tmp_arr.push(id);
                        tmp_arr.push("-");
                        tmp_arr.push(t("providers.package"));
                        tmp_arr.push(tmp_provider !== null ? tmp_provider.provider.name : '-');
                        tmp_arr.push(t(
                            'cart-material.cart-construction-package-name',
                            { name: stackNumber }
                        ) + ' - ' + t('cart-material.flight'));
                        tmp_arr.push('');
                        tmp_arr.push('-');
                        tmp_arr.push('-');
                        tmp_arr.push(parseFloat(purchase_price_flight).toFixed(2).replace(".", ",") + currency_buy_flight.symbol);
                        tmp_arr.push(parseFloat(factor_used_flight));
                        tmp_arr.push(parseFloat(converted_price_flight).toFixed(2).replace(".", ",") + trip_currency.symbol);
                        tmp_arr.push(parseFloat(selling_price_flight).toFixed(2).replace(".", ",") + currency_sell_flight.symbol);

                        tmp_data1.push(tmp_arr);

                        tmp_obj.id = id;
                        tmp_obj.product = t("providers.package");
                        tmp_obj.provider = tmp_provider !== null ? tmp_provider.provider.name : '-';
                        tmp_obj.description = t(
                            'cart-material.cart-construction-package-name',
                            { name: stackNumber }
                        ) + ' - ' + t('cart-material.flight');
                        tmp_obj.booking_status = '-';
                        tmp_obj.reference = '-';
                        tmp_obj.amount_1 = parseFloat(purchase_price_flight).toFixed(2).replace(".", ",") + currency_buy_flight.symbol;
                        tmp_obj.rate = parseFloat(factor_used_flight);
                        tmp_obj.amount_2 = parseFloat(converted_price_flight).toFixed(2).replace(".", ",") + trip_currency.symbol;
                        tmp_obj.date = '-';
                        tmp_obj.amount_3 = parseFloat(selling_price_flight).toFixed(2).replace(".", ",") + currency_sell_flight.symbol;
                        tmp_data.push(tmp_obj);
                    }
                }
                if (terrestrialPrices !== undefined && terrestrialPrices.length !== 0) {
                    let tmp_obj = {
                        product: null,
                        provider: null,
                        description: null,
                        room_name: null,
                        reference: null,
                        booking_status: null,
                        amount_1: null,
                        rate: null,
                        amount_2: null,
                        amount_3: null,
                        date: null,
                        id: null
                    };
                    let tmp_arr = [];
                    purchase_price_terrestrial = parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].purchase_price);
                    selling_price_terrestrial = parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, quotation_code !== "americavoyages")].selling_price);
                    currency_buy_terrestrial = list_currency.find((currency: { id: any; }) => {
                        return currency.id === terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].purchase_currency; 
                    });
                    currency_sell_terrestrial = list_currency.find((currency: { id: any; }) => {
                        return currency.id === terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, quotation_code !== "americavoyages")].selling_currency; 
                    });
                    converted_price_terrestrial = parseFloat(ConvertPrice(purchase_price_terrestrial, trip_currency, currency_buy_terrestrial, parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].factor_used)));
                    factor_used_terrestrial = parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].factor_used).toFixed(2);
                    tmp_provider = GetProvider(providers, terrestrialPrices[0].provider);
                    if (tmp_provider === null) {
                        tmp_provider = GetProvider(manual_providers, terrestrialPrices[0].provider);
                    }
                    if (purchase_price_terrestrial !== 0 && selling_price_terrestrial !== 0) {
                        tmp_arr.push(id);
                        tmp_arr.push("-");
                        tmp_arr.push(t("providers.package"));
                        tmp_arr.push(tmp_provider !== null ? tmp_provider.provider.name : '-');
                        tmp_arr.push(t(
                            'cart-material.cart-construction-package-name',
                            { name: stackNumber }
                        ) + ' - ' + t('cart-material.terrestrial'));
                        tmp_arr.push('');
                        tmp_arr.push('-');
                        tmp_arr.push('-');
                        tmp_arr.push(parseFloat(purchase_price_terrestrial).toFixed(2).replace(".", ",") + currency_buy_terrestrial.symbol);
                        tmp_arr.push(parseFloat(factor_used_terrestrial));
                        tmp_arr.push(parseFloat(converted_price_terrestrial).toFixed(2).replace(".", ",") + trip_currency.symbol);
                        tmp_arr.push(parseFloat(selling_price_terrestrial).toFixed(2).replace(".", ",") + currency_sell_terrestrial.symbol);

                        tmp_data1.push(tmp_arr);

                        tmp_obj.id = id;
                        tmp_obj.product = t("providers.package");
                        tmp_obj.provider = tmp_provider !== null ? tmp_provider.provider.name : '-';
                        tmp_obj.description = t(
                            'cart-material.cart-construction-package-name',
                            { name: stackNumber }
                        ) + ' - ' + t('cart-material.terrestrial');
                        tmp_obj.booking_status = '-';
                        tmp_obj.reference = '-';
                        tmp_obj.amount_1 = parseFloat(purchase_price_terrestrial).toFixed(2).replace(".", ",") + currency_buy_terrestrial.symbol;
                        tmp_obj.rate = parseFloat(factor_used_terrestrial);
                        tmp_obj.amount_2 = parseFloat(converted_price_terrestrial).toFixed(2).replace(".", ",") + trip_currency.symbol;
                        tmp_obj.date = '-';
                        tmp_obj.amount_3 = parseFloat(selling_price_terrestrial).toFixed(2).replace(".", ",") + currency_sell_terrestrial.symbol;
                        tmp_data.push(tmp_obj);
                    }
                }
            });
        }
        big_cart.map((cart: { is_optional: any; product_type: number; provider: number | null | undefined; provider_name: null | undefined; booking_reference: string | null | undefined; booking_status: { item_reference: string; status_booking: any; } | null | undefined; name: string | null | undefined; hotel: { name: string; }[]; description: string; segments: { destination_station: { station_code: string; }; }[] | null | undefined; custom_product: { title: string; } | undefined; contract_name: string; prices: any[]; taxes: string | null | undefined; start_date: moment.MomentInput; end_date: moment.MomentInput; }) => {
            if (!cart.is_optional && !isProductPackaged({ product: cart, stackInfos: data_trip?.stack_info ?? null })) {
                let tmp_obj = {
                    product: null,
                    provider: null,
                    description: null,
                    room_name: null,
                    reference: null,
                    booking_status: null,
                    amount_1: null,
                    rate: null,
                    amount_2: null,
                    amount_3: null,
                    date: null,
                    id: null
                };
                let tmp_arr = [];
                let product_type = GetProductType(cart.product_type);
                let provider = null;
                if (cart.provider !== undefined && cart.provider !== null && providers.length !== 0 && (manual_providers.length !== 0 || ['marcovasco'].includes(quotation_code))) {
                    let tmp_provider = GetProvider(providers, cart.provider);
                    if (tmp_provider === null) {
                        tmp_provider = GetProvider(manual_providers, cart.provider);
                    }
                    provider = tmp_provider.provider.name;
                }
                if (cart.provider_name !== undefined && cart.provider_name !== null) {
                    provider = cart.provider_name;
                }
                let booking_ref = "-";
                let booking_status = "-";
                if (cart.booking_reference !== undefined && cart.booking_reference !== null) {
                    booking_ref = cart.booking_reference;
                }
                if (cart.booking_status !== undefined && cart.booking_status !== null) {
                    booking_ref = cart.booking_status.item_reference;
                    booking_status = (cart.booking_status.status_booking).toLowerCase();
                }
                let name = "-";
                let room_name = "";
                if (cart.name !== undefined && cart.name !== null) {
                    name = cart.name;
                } else {
                    if (cart.product_type === 0) {
                        name = t("accommodation.hotel") + " : " + cart.hotel[0].name;
                        room_name = cart.description;
                    }
                    if (cart.product_type === 6) {
                        if (cart.segments !== undefined && cart.segments !== null) {
                            name = cart.segments[0].marketing_airline.commercial_name + " : " + (cart.segments[0].origin !== null && cart.segments[0].destination !== null ? cart.segments[0].origin.airport_code + " - " + cart.segments[0].destination.airport_code : cart.segments[0].origin_station.station_code + " - " + cart.segments[0].destination_station.station_code);
                        }
                    }
                    if (cart.product_type === 11 || cart.product_type === 12) {
                        if (cart.custom_product !== undefined) {
                            name = cart.custom_product.title;
                        }
                    }
                    if (cart.product_type === 8) {
                        name = cart.contract_name ? cart.contract_name : t('cart-material.assistance');
                    }
                }
                let price = parseFloat(cart.prices[GetPricesId(cart.prices, owner_id, user, true)].purchase_price);
                let selling_price = parseFloat(cart.prices[GetPricesId(cart.prices, owner_id, user, quotation_code !== "americavoyages")].selling_price);
                let currency_buy = list_currency.find((currency: { id: any; }) => {
                    return currency.id === cart.prices[GetPricesId(cart.prices, owner_id, user, true)].purchase_currency; 
                });
                let currency_sell = list_currency.find((currency: { id: any; }) => {
                    return currency.id === cart.prices[GetPricesId(cart.prices, owner_id, user, true)].selling_currency; 
                });
                let currency_real_purchase = cart.purchase_currency !== undefined && list_currency.find((currency) => {
                    return currency.id === cart.purchase_currency
                });
                let converted_price = parseFloat(ConvertPrice(parseFloat(cart.prices[GetPricesId(cart.prices, owner_id, user, true)].purchase_price), trip_currency, currency_buy, parseFloat(cart.prices[GetPricesId(cart.prices, owner_id, user, true)].factor_used)));
                if (cart.product_type === 6) {
                    console.log('cart:', cart);
                    const provider = providers.concat(manual_providers).find((item) => {
                        return item.provider.id === item.provider;
                    })?.provider;
                    let seatsCost = 0;
                    let option_cost = 0;
                    let otherOptionsCost = 0;
                    let option_on_flight = [];
                    if (provider?.code !== 'amadeus_rest') {
                        let outbounds = cart.segments || cart.outbounds;
                        outbounds.map(outbound => {
                            let option_on_group = [];
                            if (outbound.legs !== undefined) {
                                outbound.legs.map(leg => {
                                    for (const seat of leg.seats ?? []) {
                                        let seatPrice = 0;
                                        if ((seat.prices?.length ?? 0) > 0) {
                                            seatPrice = getPrice(seat.prices).cost;
                                        } else {
                                            seatPrice = parseFloat(seat.price ?? '0');
                                        }
                                        seatsCost += seatPrice;
                                    }
                
                                    if (leg.paid_options && leg.paid_options.length > 0) {
                                        leg.paid_options.map(option => {
                                            let optionPrice = 0;
                                            if ((option.prices?.length ?? 0) > 0) {
                                                optionPrice = getPrice(option.prices, undefined).cost;
                                            } else {
                                                optionPrice = parseFloat(option.price);
                                            }
                                            if (option.option_on_flight) {
                                                if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                    option_cost += optionPrice;
                                                    option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                                }
                                            } else if (option.option_on_group) {
                                                if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                    option_cost += optionPrice;
                                                    option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                                }
                                            } else {
                                                option_cost += optionPrice;
                                            }
                                        }); 
                                    }
                                });
                            } else {
                                for (const seat of outbound.seats ?? []) {
                                    let seatPrice = 0;
                                    if ((seat.prices?.length ?? 0) > 0) {
                                        seatPrice = getPrice(seat.prices).cost;
                                    } else {
                                        seatPrice = parseFloat(seat.price ?? '0');
                                    }
                                    seatsCost += seatPrice;
                                }

                                if (outbound.paid_options && outbound.paid_options.length > 0) {
                                    outbound.paid_options.map(option => {
                                        let optionPrice = 0;
                                        if ((option.prices?.length ?? 0) > 0) {
                                            optionPrice = getPrice(option.prices, undefined).cost;
                                        } else {
                                            optionPrice = parseFloat(option.price);
                                        }
                                        if (option.option_on_flight) {
                                            if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                option_cost += optionPrice;
                                                option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                            }
                                        } else if (option.option_on_group) {
                                            if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                option_cost += optionPrice;
                                                option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                            }
                                        } else {
                                            option_cost += optionPrice;
                                        }
                                    }); 
                                }
                            }
                        });
                    }
                    else {
                        seatsCost = cart.paid_option_price.filter((item) => {
                            return item.service_type === 'SEATS';
                        }).reduce((prev, current) => {
                            return prev + getPrice(current.prices).cost;
                        }, 0);
                        otherOptionsCost = cart.paid_option_price.filter((item) => {
                            return item.service_type !== 'SEATS';
                        }).reduce((prev, current) => {
                            return prev + getPrice(current.prices, undefined).cost;
                        }, 0);
                    }
                    console.log('seatsCost:', seatsCost);
                    console.log('otherOptionsCost:', otherOptionsCost);
                    console.log('option_cost:', option_cost);
                    price += parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost);
                    selling_price += parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost);
                    converted_price += parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost);

                    if (cart.taxes !== undefined && cart.taxes !== null) {
                        console.log('taxes 7 :', cart.taxes);
                        const master_price = cart.prices.find((price) => price.master_price);
                        price += parseFloat(cart.taxes) * parseFloat(master_price.factor_used);
                        selling_price += parseFloat(cart.taxes) * parseFloat(master_price.factor_used);
                        converted_price += parseFloat(cart.taxes) * parseFloat(master_price.factor_used);
                    }
                }
                let start_date = cart.start_date !== undefined && cart.start_date !== null ? (moment.utc(cart.start_date).format("DD-MM-YYYY") + " " + t("global.to")) : "-";
                let end_date = cart.end_date !== undefined && cart.end_date !== null ? moment.utc(cart.end_date).format("DD-MM-YYYY") : "";
                tmp_arr.push(id);
                tmp_arr.push(start_date + " " + end_date);
                tmp_arr.push(t("providers." + product_type));
                tmp_arr.push(provider);
                tmp_arr.push(name);
                tmp_arr.push(room_name);
                tmp_arr.push(booking_ref);
                tmp_arr.push(booking_status !== "-" ? t(`cart.edit_booking.${booking_status}`) : "-");
                if (cart.charge !== undefined) {
                    tmp_arr.push(parseFloat(cart.origin_charge).toFixed(2).replace(".", ",") + cart.origin_charge_currency.symbole);
                    tmp_arr.push(parseFloat(cart.charge_factor_used).toFixed(2));
                    tmp_arr.push(parseFloat(cart.charge).toFixed(2).replace(".", ",") + cart.charge_currency.symbole);
                    if (cart.purchase !== undefined && currency_real_purchase !== undefined) {
                        tmp_arr.push(parseFloat(cart.purchase).toFixed(2).replace(".", ",") + currency_real_purchase.symbole);
                    } else {
                        tmp_arr.push(parseFloat(price).toFixed(2).replace(".", ",") + currency_buy.symbole);
                    }
                } else {
                    tmp_arr.push(parseFloat(price).toFixed(2).replace(".", ",") + currency_buy.symbol);
                    tmp_arr.push(parseFloat(cart.prices[GetPricesId(cart.prices, owner_id, user, true)].factor_used));
                    tmp_arr.push(parseFloat(converted_price).toFixed(2).replace(".", ",") + trip_currency.symbol);
                }
                tmp_arr.push(parseFloat(selling_price).toFixed(2).replace(".", ",") + currency_sell.symbol);

                tmp_data1.push(tmp_arr);

                tmp_obj.id = id;
                tmp_obj.product = t("providers." + product_type);
                tmp_obj.provider = provider;
                tmp_obj.description = name;
                tmp_obj.room_name = room_name;
                tmp_obj.reference = booking_ref;
                tmp_obj.date = start_date + " " + end_date;
                tmp_obj.booking_status = booking_status !== "-" ? t(`cart.edit_booking.${booking_status}`) : "-";
                if (cart.charge !== undefined) {
                    tmp_obj.amount_1 = parseFloat(cart.origin_charge).toFixed(2).replace(".", ",") + cart.origin_charge_currency.symbol;
                    tmp_obj.rate = parseFloat(cart.charge_factor_used).toFixed(2);
                    tmp_obj.amount_2 = parseFloat(cart.charge).toFixed(2).replace(".", ",") + cart.charge_currency.symbol;
                    if (cart.purchase !== undefined && currency_real_purchase !== undefined) {
                        tmp_obj.amount_4 = parseFloat(cart.purchase).toFixed(2).replace(".", ",") + currency_real_purchase.symbol;
                    } else {
                        tmp_obj.amount_4 = parseFloat(price).toFixed(2).replace(".", ",") + currency_buy.symbol;
                    }
                } else {
                    tmp_obj.amount_1 = parseFloat(price).toFixed(2).replace(".", ",") + currency_buy.symbol;
                    tmp_obj.rate = parseFloat(cart.prices[GetPricesId(cart.prices, owner_id, user, true)].factor_used);
                    tmp_obj.amount_2 = parseFloat(converted_price).toFixed(2).replace(".", ",") + trip_currency.symbol;
                }
                tmp_obj.amount_3 = parseFloat(selling_price).toFixed(2).replace(".", ",") + currency_sell.symbol;
                tmp_data.push(tmp_obj);
            }
        });
        return { tmp_data, tmp_data1 };
    }
}