import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import grey from '@material-ui/core/colors/grey';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import GetDateFormat from '../Functions/GetDateFormat';
import GetIsoCode from '../Functions/GetIsoCode';

import moment from "moment";
import localeText from '../../Functions/localeText';
import i18n from "../../../i18n";
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';

const useStyles = makeStyles({
    bold: {
        fontWeight: "bold"
    },
    paper: {
        marginBottom: 8,
    },
    background: {
        backgroundColor: grey[200]
    },
    padding: {
        padding: 8
    },
    pointer: {
        cursor: 'pointer'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        padding: 0
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    selection: {
        fontWeight: 'bold',
        paddingBottom: 8
    }
});

const PoiCardPreCart = ({ productSelected, setProductSelected, assignedGroups, setAssignedGroups, current_groups, manualVariant, provider }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    // const current_groups = useSelector(store => store.poi.current_groups);
    const language = useSelector(state => state.header.tmp_language);
    const currency = useSelector(state => state.trip.currency);
    const currency_list = useSelector(state => state.base.currency_list);
    const end_date = useSelector(state => state.poi.end_date);
    const locales = useSelector(state => state.user.locales);

    const current_locale = locales.find((el) => {return el.language_code === i18n.language});

    const [showMore, setShowMore] = useState([]);
    const [totalPrice, setTotalPrice] = useState(null);

    moment.locale(language);
    useEffect(() => {
        let temp_show_more = [];
        productSelected.map(() => {
            temp_show_more.push(true);
        })
        setShowMore(temp_show_more);

        if (productSelected.length > 0){
            let total_price = 0;
            let total_currency = 0;
            productSelected.map((product) => {
                total_price += parseFloat(product.total_price[0].selling_price);
                total_currency = product.total_price[0].selling_currency;
            })
            setTotalPrice({selling_price: total_price, selling_currency: total_currency});
        }
    }, [productSelected])

    const handleShowMore = (index) => {
        let temp_show_more = showMore.slice();
        temp_show_more[index] = !temp_show_more[index];
        setShowMore(temp_show_more);
    }

    const onDelete = (index) => {
        let temp_products = productSelected.slice();
        let temp_groups = assignedGroups.slice();

        let group_id = temp_products[index].comps[0];
        
        temp_groups.map((parsed_group_id, parsed_group_index) => {
            if (parsed_group_id === group_id){
                temp_groups.splice(parsed_group_index);
            }
        })

        temp_products.splice(index);
        setProductSelected(temp_products);
        setAssignedGroups(temp_groups);
    }
    const onUpdateQuantity = (index_activity, index) => (event) => {
        let temp_products = productSelected.slice();
        const activity = {...temp_products[index_activity]};
        const detail_price = [...activity.detail_price];
        const price = {...detail_price[index]};
        price.quantity = event.target.value;
        detail_price[index] = price;
        activity.detail_price = detail_price;
        let total_price = [...activity.total_price];
        let total = 0;
        for (let i = 0; i < activity.detail_price.length; i++) {
            total += parseFloat(activity.detail_price[i].price[activity.detail_price[i].price.length - 1].selling_price) * activity.detail_price[i].quantity;
        }
        total_price[total_price.length - 1].selling_price = total;
        activity.total_price = total_price;
        temp_products[index_activity] = activity;
        setProductSelected(temp_products);
    }
    return (
        <Fragment>
            {
                productSelected.length > 0 && (
                    <Typography variant={ 'h6' } className={ classes.selection }>{ t('accommodation.your_selection') } : {manualVariant !== undefined && manualVariant !== null && localeText(current_locale.id, manualVariant.localization, manualVariant.name, 'title')}</Typography>
                )
            }
            {
                productSelected.map((activity, index_activity) => {
                    let nb_adults = 0;
                    let nb_teens = 0;
                    let nb_children = 0;
                    let nb_babies = 0;

                    current_groups.map((group, group_index) =>  {
                        if (group_index === activity.group_index){
                            group.travelers_list.map((traveler) => {
                                let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
                                if (age > 12 && age < 18) {
                                    nb_teens += 1;
                                } else if (age > 2 && age <= 12) {
                                    nb_children += 1;
                                } else if (age <= 2) {
                                    nb_babies += 1;
                                } else if (age >= 18) {
                                    nb_adults += 1;
                                }
                            })
                        }
                    })
                    return (
                        <Paper key={ index_activity } id={ `pre-cart-${index_activity}` } className={ classes.paper } variant={ 'outlined' }>
                            <Grid container spacing={ 0 }>
                                <Grid container item xs={ 12 } className={ `${classes.padding} ${classes.background}` } justify={ 'space-between' }>
                                    <Grid item>
                                        {
                                            !mobile && (
                                                <Typography className={classes.bold}>
                                                    { t('poi.group') + " " + t("global.number") + (activity.group_index + 1) + " : " }
                                                    { nb_adults > 0 ? (nb_adults > 1 ? nb_adults + " " + t("flight_search.adults") : nb_adults + " " + t("flight_search.adult")) : ""  }{ nb_teens > 0 ? ", " : "" }
                                                    { nb_teens > 0 ? (nb_teens > 1 ? nb_teens + " " + t("flight_search.ados") : nb_teens + " " + t("flight_search.ado")) : ""  }{ nb_children > 0 ? ", " : "" }
                                                    { nb_children > 0 ? (nb_children > 1 ? nb_children + " " + t("flight_search.children") : nb_children + " " + t("flight_search.child")) : ""  }{ nb_babies > 0 ? ", " : "" }
                                                    { nb_babies > 0 ? (nb_babies > 1 ? nb_babies + " " + t("flight_search.babies") : nb_babies + " " + t("flight_search.baby")) : ""  }
                                                    { activity.variant_name !== undefined ? " - " + activity.variant_name : "" }
                                                </Typography>
                                            )
                                        }
                                        {
                                            mobile && (
                                                <Grid>
                                                    <Typography className={classes.bold}>
                                                        { t('poi.group') + " " + t("global.number") + (activity.group_index + 1) + " : " }
                                                    </Typography>
                                                    <Typography className={classes.bold}>
                                                        { nb_adults > 0 ? (nb_adults > 1 ? nb_adults + " " + t("flight_search.adults") : nb_adults + " " + t("flight_search.adult")) : ""  }{ nb_teens > 0 ? ", " : "" }
                                                    </Typography>
                                                    <Typography className={classes.bold}>
                                                        { nb_teens > 0 ? (nb_teens > 1 ? nb_teens + " " + t("flight_search.ados") : nb_teens + " " + t("flight_search.ado")) : ""  }{ nb_children > 0 ? ", " : "" }
                                                    </Typography>
                                                    <Typography className={classes.bold}>
                                                        { nb_children > 0 ? (nb_children > 1 ? nb_children + " " + t("flight_search.children") : nb_children + " " + t("flight_search.child")) : ""  }{ nb_babies > 0 ? ", " : "" }
                                                    </Typography>
                                                    <Typography className={classes.bold}>
                                                        { nb_babies > 0 ? (nb_babies > 1 ? nb_babies + " " + t("flight_search.babies") : nb_babies + " " + t("flight_search.baby")) : ""  }
                                                    </Typography>
                                                    <Typography className={classes.bold}>
                                                        { activity.variant_name !== undefined ? activity.variant_name : "" }
                                                    </Typography>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                    <Grid item>
                                        <ExpandMoreIcon onClick={() => handleShowMore(index_activity)} />
                                        <Close className={ classes.pointer } onClick={() => { onDelete(index_activity); }}/>
                                    </Grid>
                                </Grid>
                                {
                                    showMore[index_activity] && (
                                        <Grid item xs={ 12 } className={ classes.padding }>
                                            <Typography className={classes.bold}> { t("poi.date") + " :"} </Typography>
                                            <br />
                                            {
                                                (manualVariant === undefined || !manualVariant.multiple_days) &&
                                                <>
                                                    <Typography display="inline">{ activity.long_date !== undefined ? activity.long_date : GetDateFormat(activity.arrival_date, language)} - </Typography>
                                                    <Typography className={classes.bold} display="inline"> {t("global.hour")} :</Typography>
                                                    <Typography display="inline"> {moment(activity.arrival_date).format("LT")} </Typography>
                                                </>
                                            }
                                            {
                                                manualVariant !== undefined && manualVariant.multiple_days &&
                                                <>
                                                {
                                                    JSON.parse(activity.selected_dates).map((date, date_index) => {
                                                        return <Typography key={date_index}>{GetDateFormat(date, language)}</Typography>
                                                    })
                                                }
                                                </>
                                            }
                                            <br />
                                            <br />
                                            <Typography className={classes.bold}> { t("poi.composition") + " :"} </Typography>
                                            <br />
                                            {
                                                current_groups.map((group, group_index) =>{
                                                    if(group_index === activity.group_index){
                                                        return(
                                                            <Grid key={group_index}>
                                                                {
                                                                    group.travelers_list.map((traveler) => {
                                                                        return(
                                                                            <Typography key={`${traveler.id}`}> {t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name} </Typography>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                        )
                                                    }
                                                })
                                            }
                                            <br />
                                            <Grid container alignItems="center" justify="space-between">
                                                <Grid item>
                                                    <Typography className={classes.bold}>{ t("poi.price_details") }</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.bold}>{ t("poi.total") }</Typography>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            {
                                                activity.detail_price.map((pax_type, pax_type_index) => {
                                                    let price_by_pax = new Intl.NumberFormat(language, { style: 'currency', currency: GetIsoCode(currency_list, currency, pax_type.price[0].selling_currency) }).format(parseFloat(pax_type.price[0].selling_price));
                                                    let total_for_pax = (parseFloat(pax_type.price[0].selling_price) * pax_type.quantity).toFixed(2);
                                                    total_for_pax = new Intl.NumberFormat(language, { style: 'currency', currency: GetIsoCode(currency_list, currency, pax_type.price[0].selling_currency) }).format(parseFloat(total_for_pax));
                                                    return(
                                                        <Grid container alignItems="center" justify="space-between"  key={`${activity.group_index}-${pax_type_index}`}>
                                                            <Grid item>
                                                                {
                                                                    provider?.code === "civitatis" ? (
                                                                        <>
                                                                            <div style={{display: "inline"}}>
                                                                                <FormControl>
                                                                                    <InputLabel id={ `pax-type-quantity-${pax_type_index}` }>{ t('transfers.quantity') }</InputLabel>
                                                                                    <Select labelId={ `pax-type-quantity-${pax_type_index}` } value={ pax_type.quantity } label={ t('transfers.quantity') } style={{width: "150px"}} onChange={ onUpdateQuantity(index_activity, pax_type_index) }>
                                                                                        <MenuItem value={ 0 }>0</MenuItem>
                                                                                        <MenuItem value={ 1 }>1</MenuItem>
                                                                                        <MenuItem value={ 2 }>2</MenuItem>
                                                                                        <MenuItem value={ 3 }>3</MenuItem>
                                                                                        <MenuItem value={ 4 }>4</MenuItem>
                                                                                        <MenuItem value={ 5 }>5</MenuItem>
                                                                                        <MenuItem value={ 6 }>6</MenuItem>
                                                                                        <MenuItem value={ 7 }>7</MenuItem>
                                                                                        <MenuItem value={ 8 }>8</MenuItem>
                                                                                        <MenuItem value={ 9 }>9</MenuItem>
                                                                                        <MenuItem value={ 10 }>10</MenuItem>
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </div>
                                                                            <Typography display={'inline'}>{ pax_type.name }</Typography>
                                                                        </>
                                                                    ) : pax_type.name !== "produit" && pax_type.name !== 'Par personne' && (
                                                                        <Typography>
                                                                            { pax_type.quantity + " " + pax_type.name}
                                                                            {/* { pax_type.quantity + " " + (pax_type.quantity > 1 ? t(`poi.${pax_type.name.toLowerCase()}s`) : t(`poi.${pax_type.name.toLowerCase()}`))} */}
                                                                            { " x " + price_by_pax }
                                                                        </Typography>
                                                                    )
                                                                }
                                                                {/*{*/}
                                                                {/*    pax_type.name !== "produit" && pax_type.name !== 'Par personne' && (*/}
                                                                {/*        <Typography>*/}
                                                                {/*            { pax_type.quantity + " " + pax_type.name}*/}
                                                                {/*            /!* { pax_type.quantity + " " + (pax_type.quantity > 1 ? t(`poi.${pax_type.name.toLowerCase()}s`) : t(`poi.${pax_type.name.toLowerCase()}`))} *!/*/}
                                                                {/*            { " x " + price_by_pax }*/}
                                                                {/*        </Typography>*/}
                                                                {/*    )*/}
                                                                {/*}*/}
                                                                {
                                                                    pax_type.name === "produit" && (
                                                                        <Typography>
                                                                            { t("poi.produit") }
                                                                        </Typography>
                                                                    )
                                                                }
                                                                {
                                                                    pax_type.name === 'Par personne' && (
                                                                        <Typography>
                                                                            { t(`poi.${pax_type.name.replace(' ', '_').toLowerCase()}`) } : {pax_type.quantity} { " x " + price_by_pax }
                                                                        </Typography>
                                                                    )
                                                                }
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography> { total_for_pax }</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                    
                                                })
                                            }
                                            <br />
                                            <Grid container alignItems="center" justify="space-between">
                                                <Grid item>
                                                    <Typography className={classes.bold}>
                                                        { t("poi.total") + " " + t('poi.group') + " " + t("global.number") + (activity.group_index + 1) + " : " }
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.bold}>
                                                        { new Intl.NumberFormat(language, { style: 'currency', currency: GetIsoCode(currency_list, currency, activity.total_price[0].selling_currency) }).format(parseFloat(activity.total_price[0].selling_price))}
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Paper>
                    );
                })
            }
            {
                productSelected.length !== 0 && totalPrice !== null && (
                    <Paper id={ `pre-cart-total` } className={ classes.paper } variant={ 'outlined' }>
                        <Grid container spacing={ 0 }>
                            <Grid container item xs={ 12 } className={ `${classes.padding} ${classes.background}` } justify={ 'space-between' }>
                                <Grid item>
                                    <Typography variant='h6'> {t("poi.total") + " : "} </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='h6'>
                                        { new Intl.NumberFormat(language, { style: 'currency', currency: GetIsoCode(currency_list, currency, totalPrice.selling_currency) }).format(parseFloat(totalPrice.selling_price)) }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                )
            }
        </Fragment>
    );
};

export default React.memo(PoiCardPreCart);