//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import clsx from "clsx";
import { Link } from "react-router";

import Container from "@material-ui/core/Container";
import { Fab, Skeleton, Tab, Tabs } from '@mui/material';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import ArticleIcon from '@mui/icons-material/Article';
import RoomIcon from '@mui/icons-material/Room';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import CustomHomeSlider from '../../Home/CustomHomeSlider.jsx';
import MaterialHeader from '../../Header/MaterialHeader.jsx';
import CheckBeforeRequest from '../../Common/CheckBeforeRequest.js';
import MaterialBreadcrumb from '../MaterialBreadcrumb.js';
import { RemoveXHistorydest, SetHistorydest, SetItineraryTypeDetails } from '../../../Actions/ItineraryType.js';
import { SetProviders } from "../../../Actions/Trip.js";

//---- CSS ----//
import "../../../Style/Home.css";
import "../../../Style/Global.css";
import "../../../Style/GlobalAgencies.css";

import GetDataId from '../Functions/GetDataId.js';
import GetProducts from '../Circuits/GetProducts.js';
import RightPanel from '../PackagesMaterial/RightPanel.tsx';
import PriceCalendar from '../PackagesMaterial/PriceCalendar.js';
import RenderExtra from '../PackagesMaterial/RenderExtra.js';
import MobileRightPanel from '../PackagesMaterial/MobileRightPanel.tsx';
import MaterialDuplicateDialog from '../MaterialDuplicateDialog.js';
import HotelInfo from '../PackagesMaterial/HotelInfo.tsx';
import SalePitchDialog from '../PackagesMaterial/SalePitchDialog.tsx';
import RenderDetailStep from './RenderDetailStep.tsx';
import Help from "../../Base/Help";
import MaterialLogin from '../../Home/MaterialLogin.js';
import i18n from '../../../i18n.jsx';
import localeText from '../../Functions/localeText.js';
import { CircuitSummary }  from './CircuitSummary.tsx';
import ByDayPicture from './ByDayPicture.tsx';
import CircuitSkeleton from './CircuitSkeleton.tsx';
import ItineraryTypeDetailsMap from '../../ItineraryTypeDetails/ItineraryTypeDetailsMap.jsx';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    breadCrumb: {
        maxWidth: 1200,
        marginTop: 80,
        marginBottom: 10,
        zIndex: 90,
        position: "relative"
    },
    container: {
        maxWidth: 1200,
        paddingTop: 15,
        backgroundColor: '#F5F5F5'
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    headerPicture: {
        height: "50vh",
        position: 'relative'
    },
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 10
    },
    customButton: {
        "background": `${main_color}`,
        //padding: "15px 40px",
        "textAlign": "center",
        "color": "#fff",
        "fontSize": 20,
        "textTransform": "none",
        "&:hover": {
            background: "#cc4f2a"
        }
    },
    customButtonV: {
        "background": "#BF202D",
        //padding: "15px 40px",
        "textAlign": "center",
        "color": "#fff",
        "fontSize": 20,
        "textTransform": "none",
        "&:hover": {
            background: "#cc4f2a"
        }
    },
    headerContainer: {
        height: "50vh",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0
    },
    destinationTitle: {
        textAlign: "center",
        color: "white",
        textShadow: "black 1px 0 10px",
        fontFamily: "Roboto",
        //marginBottom: 50,
        fontWeight: 400
    },
    hoverContainer: {
        "&:hover": {
            borderLeft: `6px solid ${main_color}`
        }
    },
    bookingContainer: {
        borderLeft: `6px solid ${main_color}`
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 50
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    staticPriceContainer: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'white',
        marginLeft: -16,
        padding: 16,
        borderTop: '1px solid lightgray'
    },
    orangeButton: {
        backgroundColor: `${main_color}`,
        color: "white"
    },
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    fullWidth: {
        width: "100%"
    },
    textJustify: {
        textAlign: "justify"
    },
    tabs: {
        "& .MuiTab-root": {
            fontWeight: 'bold'
        },
        "& .MuiTabs-indicator": {
            height: 4
        },
        "& .MuiTab-root.Mui-selected": {
            color: `${main_color}`
        },
        "position": 'sticky',
        "top": 7,
        "zIndex": 1000
    },
    fabColor: {
        "boxShadow": 'none !important',
        "zIndex": '1 !important',
        "backgroundColor": `${main_color} !important`,
        "color": 'white !important',
        "&:hover": {
            backgroundColor: `${main_color}CA !important`
        }
    },
    coverImg: {
        display: "block",
        width: "100%",
        objectFit: "cover",
        height: "100%"
    },
    coverImgMap: {
        width: "100%",
        height: 300
    },
    paddingRight0: {
        paddingRight: '0px !important'
    },
    basicLink: {
        "fontFamily": "Roboto",
        "cursor": "pointer",
        "color": `${main_color} !important`,
        "&:hover": {
            textDecoration: "underline"
        }
    },
    basicLinkV: {
        "fontFamily": "Roboto",
        "cursor": "pointer",
        "color": "#BF202D !important",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    wrapIcon: {
        verticalAlign: "top",
        display: "inline-flex",
        marginRight: 2
    },
    orange: {
        fontFamily: "Roboto",
        color: `${main_color} !important`
    },
    red: {
        fontFamily: 'Roboto',
        color: '#BF202D !important'
    },
    carouselAccommodationFullScren: {
        position: 'relative',
        float: 'left',
        width: '100%',
        marginRight: '1em',
        marginBottom: 0
    },
    carouselAccommodation: {
        position: 'relative',
        float: 'left',
        width: '50%',
        marginRight: '1em',
        marginBottom: 0
    },
    carouselAccommodationAlt: {
        width: '120% !important',
        overflow: 'hidden',
        display: 'flex',
        position: 'unset'
    },
    carouselAccommodationInteractions: {
        position: 'absolute',
        width: '96%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 5px'
    },
    carouselAccommodationInteractionsSvg: {
        cursor: 'pointer',
        fontSize: '22pt',
        backgroundColor: '#0000004f',
        color: 'white'
    },
    firstImg: {
        marginRight: '2%',
        width: '60% !important'
    },
    secondImg: {
        width: '49% !important'
    },
    containImage: {
        width: '100%',
        objectFit: 'contain'
    },
    onePicture: {
        margin: "15px 0",
        paddingLeft: '2em'
    },
    multiplePictures: {
        margin: "1.5em 0",
        position: "relative",
        overflow: "hidden",
        paddingLeft: '2em'
    },
    border: {
        border: '1px solid #ebebeb',
        borderRadius: 13,
        padding: 10,
        marginBottom: 10,
        backgroundColor: 'white',
        marginRight: 7,
        marginLeft: 0,
        marginTop: 10
    }
}));

const CircuitsDetails = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const { pass_check, headers } = CheckBeforeRequest();

    const config = JSON.parse(localStorage.getItem("config"));
    const quotation_code = config.quotation_code;
    const [logo] = useState(config !== null && config.logo !== undefined && config.logo !== null && config.logo.url !== undefined && config.logo.url !== null ? config.logo : null);

    const itinerary_type_details_data = useSelector(store => store.itinerary_type.itinerary_type_details_data) ??
        props.itinerary_type_details_data ??
        null;
    const itinerary_type_trip_data = useSelector(store => store.itinerary_type.itinerary_type_trip_data);
    const is_connected = useSelector(store => store.user.is_connected);
    const dest_history = useSelector(store => store.itinerary_type.dest_history);
    const user = useSelector(store => store.user.user);
    const itinerary = useSelector(store => store.itinerary_type.itinerary);
    const locales = useSelector(state => state.user.locales);
    const client_type = useSelector(state => state.accommodation.client_type);
    const type = useSelector(state => state.itinerary_type.type);

    const pricesRef = useRef(null);
    const topContainerRef = useRef(null);
    const summaryCircuitRef = useRef(null);
    const itiRef = useRef(null);
    const priceCalendarRef = useRef(null);
    const includeRef = useRef(null);
    const photoRef = useRef(null);

    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });

    let history = [];
    let all_trips_loaded = 0;

    const [isLoading, setIsLoading] = useState(false);
    const [isItiLoading, setIsItiLoading] = useState(false);
    const [duplicationOpen, setDuplicationOpen] = useState(false);
    const [mobileDialog, setMobileDialog] = useState(false);
    const [byDay, setByDay] = useState([]);
    const [variationArr, setVariationArr] = useState([]);
    const [mainTrip, setMainTrip] = useState(null);
    const [versionToShow, setVersionToShow] = useState("");
    const [variantToShow, setVariantToShow] = useState(null);
    const [versionAvailable, setVersionAvailable] = useState([]);
    const [variantAvailable, setVariantAvailable] = useState([]);
    const [products, setProducts] = useState([]);
    const [sortProducts, setSortProducts] = useState([]);
    const [hotels, setHotels] = useState([]);
    const [flights, setFlights] = useState([]);
    const [poi, setPoi] = useState([]);
    const [cars, setCars] = useState([]);
    const [transfers, setTransfers] = useState([]);
    const [assurances, setAssurances] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [salePitch, setSalePitch] = useState(false);
    const [dateIsAfter, setDateIsAfter] = useState(false);
    const [loginDialog, setLoginDialog] = useState(false);
    const [tabValue, setTabValue] = useState('summary');
    const [byDayPicures, setByDayPictures] = useState([]);
    const [priceEmpty, setPriceEmpty] = useState(false);
    useEffect(() => {
        console.log('init circuit');
        // if (itinerary_type_details_data === null || (itinerary_type_details_data !== null && itinerary_type_details_data.id !== parseInt(props.params.itinerary_type_id))) {
        getTripFromURL();
        // } else {
        //     setIsLoading(true);
        //     setIsItiLoading(true);
        //     if (itinerary_type_details_data.forfait_price) {
        //         getFlight(itinerary_type_details_data.id, itinerary_type_details_data.trip.token, itinerary_type_details_data.destination.id);
        //     }
        //     //GetProducts(itinerary_type_details_data.trip.id, itinerary_type_details_data.trip.current_version, itinerary_type_details_data.trip.token, setProducts, dispatch, false);
        //     setNewBreadCrumbs(itinerary_type_details_data.destination.id, itinerary_type_details_data.name);
        // }    
        return () => {
            dispatch(SetItineraryTypeDetails(null));
            dispatch({ type: "ITINERARY_SET_LOWEST_PRICE", payload: 0 });
            dispatch({ type: "ITINERARY_SET_TYPE", payload: "" });
            dispatch({ type: "ITINERARY_SET_PRICE_DATA", payload: [] });
            dispatch({ type: "ITINERARY_TYPE_SET_ITINERARY_TRIP_DATA", payload: null });
            dispatch({ type: 'ITINERARY_TYPE_SET_FLIGHT_CIRCUIT', payload: [] });
        };
    }, []);
    useEffect(() => {
        if (itinerary_type_details_data !== null) {
            dispatch({ type: "ITINERARY_SET_TYPE", payload: itinerary_type_details_data.type.toLowerCase() });
            if (byDay.length === 0) {
                itiByDay();
            }
            // if (itinerary_type_details_data.map_picture === null) {
            //     console.log('initMap');
            //     InitItiMap(itinerary_type_details_data);
            // }
            if (itinerary_type_details_data.trip !== null) {
                getTrip();
            }
        }
    }, [itinerary_type_details_data]);
    useEffect(() => {
        if (versionToShow !== "") {
            GetProducts(itinerary_type_details_data.trip.id, versionToShow, itinerary_type_details_data.trip.token, setProducts, setIsItiLoading, dispatch, false, quotation_code);
            let variant_available = [];
            let variant_to_show = 0;
            itinerary_type_details_data.variant_name.forEach(variant => {
                if (variant.version === versionToShow) {
                    variant_available.push(variant.id);
                }
            });
            if (variant_available.length > 0) {
                variant_to_show = variant_available[0];
            }
            variant_available = variant_available.sort();
            setVariantAvailable(variant_available);
            setVariantToShow(variant_to_show);
        }
    }, [versionToShow]);
    useEffect(() => {
        checkVariantProduct();
    }, [variantToShow]);
    useEffect(() => {
        if (byDay.length !== 0) {
            let tmp_arr = [];
            byDay.map((day) => {
                if (day.trip_version === versionToShow && day.pictures !== undefined && day.pictures !== null && day.pictures.length > 0) {
                    day.pictures.map((pic) => {
                        if (tmp_arr.find((el) => el.id === pic.id) === undefined) {
                            tmp_arr.push(pic);
                        }
                    });
                }
            });
            if (tmp_arr.length !== 0) {
                tmp_arr = tmp_arr.map(img => {
                    let real_url = img.url;
                    if (!real_url) real_url = img.thumbnail_big;
                    if (!real_url) real_url = img.thumbnail_medium;
                    return { ...img, url: real_url };
                });
            }
            setByDayPictures(tmp_arr ?? []);
        }
    }, [byDay, versionToShow]);
    useEffect(() => {
        if (products.length > 0) {
            let sort_products = [];
            let tmp_nb_active_hotel = 0;
            let tmp_hotel = [];
            let tmp_flight = [];
            let tmp_transfers = [];
            let tmp_linked_dest = [];
            let tmp_assurances = [];
            let tmp_options = [];
            let tmp_poi = [];
            let tmp_cars = [];
            let tmp_simple_product = [];
            let tmp_currency_symbol = "-";
            let tmp_currency_iso = null;
            let tmp_flight_included = false;
            let selected_variant = [];

            tmp_flight_included = itinerary_type_details_data !== null ? itinerary_type_details_data.flight_included : false;
            for (let i = 0; i < products.length; i++) {
                // if (!products[i].is_optional) {
                if (products[i].product_type === 0 || products[i].product_type === 7) {
                    if (!products[i].is_manual) {
                        let same = false;
                        sort_products.map(sort_item => {
                            if ((sort_item.product_type === 0 || sort_item.product_type === 7) && products[i].is_displayed && !sort_item.is_manual && sort_item.hotel[0].id === products[i].hotel[0].id && (sort_item.provider === products[i].provider || client_type === 2) && sort_item.start_date === products[i].start_date &&
                                    sort_item.end_date === products[i].end_date && sort_item.is_available === products[i].is_available && (sort_item.variant === products[i].variant || (products[i].variant === null && products[i].is_optional)) && sort_item.trip_version === products[i].trip_version) {
                                same = true;
                                sort_item.rooms.push(products[i]);
                                sort_item.rooms = sort_item.rooms.sort((a, b) => {
                                    return a.room_ordering - b.room_ordering;
                                });
                            }
                        });
                        if (!same) {
                            sort_products.push({ ...products[i], rooms: [products[i]] });
                        }
                    } else {
                        sort_products.push(products[i]);
                    }
                    // if (products[i].is_optional === false) {
                    // tmp_nb_active_hotel++;
                    // sort_products.push(products[i]);
                    tmp_hotel.push(products[i]);
                    // }
                }
                if (products[i].product_type === 8 && products[i].is_optional === true) {
                    tmp_assurances.push(products[i]);
                    sort_products.push(products[i]);
                }
                if (products[i].product_type === 6) {
                    tmp_flight.push(products[i]);
                    sort_products.push(products[i]);
                }
                if (products[i].product_type === 11 || products[i].product_type === 12) {
                    tmp_poi.push(products[i]);
                    sort_products.push(products[i]);
                }
                if (products[i].product_type === 2) {
                    tmp_cars.push(products[i]);
                    sort_products.push(products[i]);
                }
                if (products[i].product_type === 4) {
                    tmp_transfers.push(products[i]);
                    sort_products.push(products[i]);
                }
                if (products[i].product_type === 8) {
                    tmp_assurances.push(products[i]);
                    sort_products.push(products[i]);
                }
                // let variant = products[i].variant !== null ? products[i].variant : 0;
                // if (!variant_available.includes(variant)) {
                //     variant_available.push(variant);
                // }
                if (products[i].variant === null && !products[i].is_optional) {
                    selected_variant.push(products[i].id);
                }
                if (products[i].variant === variantToShow && products[i].trip_version === versionToShow && !products[i].is_optional) {                        
                    selected_variant.push(products[i].id);
                }
                // }
                // if (products[i].is_optional === true && products[i].product_type !== 8) {
                //     tmp_options.push(products[i]);
                // }
            }
            if (itinerary_type_details_data !== null && itinerary_type_details_data.trip.currency !== undefined && itinerary_type_details_data.trip.currency !== null) {
                tmp_currency_symbol = itinerary_type_details_data.trip.currency.symbol;
                tmp_currency_iso = itinerary_type_details_data.trip.currency.iso_code;
            }
            tmp_hotel = tmp_hotel.sort((a, b) => {
                return a.start_date < b.start_date;
            });
            tmp_transfers = tmp_transfers.sort((a, b) => {
                return a.start_date < b.start_date;
            });
            tmp_cars = tmp_cars.sort((a, b) => {
                return a.start_date < b.start_date;
            });
            tmp_flight = tmp_flight.sort((a, b) => {
                return a.start_date < b.start_date;
            });
            tmp_poi = tmp_poi.sort((a, b) => {
                return a.start_date < b.start_date;
            });

            const base_arr = [0, 7, 4, 2, 11, 12, 1];

            tmp_options = tmp_options.sort((a, b) => {
                return base_arr.indexOf(a.product_type) - base_arr.indexOf(b.product_type);
            });
            setSortProducts(sort_products);
            checkVariantProduct();
            setHotels(tmp_hotel);
            setFlights(tmp_flight);
            setCars(tmp_cars);
            setPoi(tmp_poi);
            setTransfers(tmp_transfers);
            setOptions(tmp_options);
            setAssurances(tmp_assurances);
            setSelectedVariant(selected_variant);
        }
    }, [products]);
    const checkVariantProduct = () => {
        let new_product = products.filter((el) => {
            return (el.variant === variantToShow || el.variant === null) && el.trip_version === versionToShow && !el.is_optional;
        });
        let selected_variant = [];
        new_product.map((el) => {
            selected_variant.push(el.id);
        });
        setSelectedVariant(selected_variant);
    };
    const variantIsChecked = (product) => {
        return selectedVariant.includes(product.id);
    };
    const onToggleVariant = product => () => {
        let variant_arr = selectedVariant.slice();
        let start_destination = product.start_destination.data.id;
        let start_date = product.start_date;
        let end_date = product.end_date;

        if (product.product_type === 0) {
            hotels.map((hotel) => {
                if (hotel.start_destination.data.id === start_destination && hotel.start_date === start_date && variant_arr.includes(hotel.id)) {
                    variant_arr = variant_arr.filter((el) => {
                        return el !== hotel.id;
                    });
                    variant_arr.push(product.id);
                }
            });
        } else if (product.product_type === 4) {
            if (variant_arr.includes(product.id)) {
                variant_arr = variant_arr.filter((el) => {
                    return el !== product.id;
                });
            } else {
                variant_arr.push(product.id);
            }
        } else if (product.product_type === 2) {
            cars.map((car) => {
                if (car.start_destination.data.id === start_destination && moment(car.start_date).isSame(moment(start_date), "day") && variant_arr.includes(car.id)) {
                    variant_arr = variant_arr.filter((el) => {
                        return el !== car.id;
                    });
                } else {
                    variant_arr.push(product.id);
                }
            });
        } else if (product.product_type === 6) {
            let find_product = flights.find((el) => {
                return el.start_destination.data.id === start_destination && moment(el.start_date).isSame(moment(start_date), "day") && variant_arr.includes(el.id);
            });
            if (find_product !== undefined) {
                variant_arr = variant_arr.filter((el) => {
                    return el !== find_product.id;
                });
            } else {
                variant_arr.push(product.id);
            }
        } else if (variant_arr.includes(product.id)) {
            variant_arr = variant_arr.filter((el) => {
                return el !== product.id;
            });
        } else {
            variant_arr.push(product.id);
        }
        setSelectedVariant(variant_arr);
    };
    const getTripFromURL = () => {
        if (pass_check) {
            setIsLoading(true);
            setIsItiLoading(true);
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/circuits/${props.params.itinerary_type_id}/?reduced=true`
            }).then(function (response) {
                let data = Object.assign({}, response.data);
                console.log('circuit data:', data);
                setNewBreadCrumbs(data.destination.id, data.name);
                if (data.flight_included) {
                    getFlight(data.id, data.trip.token, data.destination.id);
                    getProvider();
                }
                dispatch(SetItineraryTypeDetails(data));
            }).catch(function (error) {
                console.log('error:', error);
            });
        }
    };
    const getProvider = () => {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/providers/?limit=1000`
        }).then((response) => {
            dispatch(SetProviders(response.data.results));
        }).catch((error) => {
            console.log('error:', error);
        });
    };
    const getFlight = (circuit_id, token, destination_id) => {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/flights_bo_params/circuit/${circuit_id}/?token=${token}`
        }).then((response) => {
            console.log('flight circuit:', response.data);
            if (response.data.length !== 0) {
                dispatch({ type: 'ITINERARY_TYPE_SET_FLIGHT_CIRCUIT', payload: response.data });
            } else {
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/flights_bo_params/`
                }).then((response1) => {
                    console.log('flight circuit1:', response1.data);
                    let params = response1.data.find((el) => el.destination !== null && el.destination.id);
                    if (params.length !== undefined) {
                        dispatch({ type: 'ITINERARY_TYPE_SET_FLIGHT_CIRCUIT', payload: params });
                    }
                }).catch((error) => {
                    console.log('flights_bo_params error:', error);
                });
            }
        }).catch((error) => {
            console.log('flights_bo_params circuit error:', error);
        });
    };
    const getTrip = () => {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${itinerary_type_details_data.trip.id}/?token=${itinerary_type_details_data.trip.token}`
        }).then(function (response) {
            console.log('response:', response);
            let data_trip = Object.assign({}, response.data);
            dispatch({ type: "ITINERARY_TYPE_SET_ITINERARY_TRIP_DATA", payload: data_trip });
            console.log('data_trip:', data_trip);
            let variant_arr = [];
            all_trips_loaded = 0;
            if (data_trip !== null && data_trip.data !== undefined) {
                data_trip.data.map((data) => {
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${data_trip.id}/versions/${data.id}/?token=${itinerary_type_details_data.trip.token}`
                    }).then(function (response1) {
                        let variation = Object.assign({}, response1.data);
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${data_trip.id}/versions/${data.id}/itinerary/?token=${data_trip.token}`
                        }).then(function (response2) {
                            let iti = response2.data.slice();
                            let variant_obj = {
                                data_trip: variation,
                                itinerary: iti
                            };
                            variant_arr.push(variant_obj);
                            all_trips_loaded++;
                            if (all_trips_loaded === data_trip.data.length) {
                                compareTripVariation(variant_arr);
                            }
                        }).catch(function (error2) {
                            console.log('trip error 2:', error2);
                            all_trips_loaded++;
                            if (all_trips_loaded === data_trip.data.length) {
                                compareTripVariation(variant_arr);
                            }
                        });
                    }).catch(function (error1) {
                        console.log('trip error 1:', error1);
                    });
                });
            }
        }).catch(function (error) {
            console.log('trip error:', error);
        });
    };
    const compareTripVariation = (data) => {
        let main_trip = data[GetDataId(data)];
        let nb_variant = 0;
        let tmp_data = data.slice();
        let copy_data = [];
        let version_available = [];
        tmp_data.map((version) => {
            let tmp_version = Object.assign({}, version);
            tmp_version.selected = false;
            let diff_obj = {
                name: null,
                steps: [],
                desc: null
            };
            if (main_trip !== null && tmp_version.data_trip.id === main_trip.data_trip.id) {
                tmp_version.selected = true;
            }
            if (main_trip !== null && tmp_version.data_trip.id !== main_trip.data_trip.id) {
                diff_obj.name = "Extension n°" + (nb_variant + 1);
                for (let i = 0; i < itinerary_type_details_data.description_version.length; i++) {
                    if (itinerary_type_details_data.description_version[i].trip_version === tmp_version.data_trip.id) {
                        diff_obj.desc = itinerary_type_details_data.description_version[i].description;
                    }
                }
            }
            tmp_version.differences = diff_obj;
            version_available.push(tmp_version.data_trip);
            copy_data.push(tmp_version);
        });
        setVariationArr(copy_data);
        setMainTrip(main_trip);
        version_available = version_available.sort((a, b) => a.id - b.id);
        setVersionAvailable(version_available);
        setVersionToShow(main_trip.data_trip.id);
    };
    const setNewBreadCrumbs = (id, name) => {
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/destinations/${id}/breadcrumbs/`
            }).then(function (response) {
                let data = response.data;
                history = [];
                let is_main = getDestinationPositionOnBreadcrumbs(data.child, id);
                if (is_main.type !== undefined) {
                    let tmp_arr = [];
                    let tmp_obj = {
                        name: t("home.back_home"),
                        data: null,
                        need_redirect: false
                    };
                    tmp_arr.push(tmp_obj);
                    for (let i = 0; i < history.length; i++) {
                        console.log('history[i]:', history[i]);
                        tmp_obj = {
                            name: history[i].international_name,
                            data: history[i],
                            need_redirect: true
                        };
                        tmp_arr.push(tmp_obj);
                    }
                    let tmp_obj2 = {
                        name: name,
                        data: null,
                        need_redirect: false
                    };
                    tmp_arr.push(tmp_obj2);
                    dispatch(SetHistorydest(tmp_arr));
                    setIsLoading(false);
                }
            }).catch(function (error) {
                console.log('err:', error);
            });
        }
    };
    const getDestinationPositionOnBreadcrumbs = (data, id_to_find) => {
        if (data.id !== parseFloat(id_to_find)) {
            if (data.data.type !== 0) {
                history.push(data.data);
            }
            return (getDestinationPositionOnBreadcrumbs(data.child, id_to_find));
        } 
        history.push(data.data);
        return (data.data);
    };
    const itiByDay = () => {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/circuits/${itinerary_type_details_data.id}/by-day/?limit=1000`
        }).then(function (response) {
            let data = response.data.results.slice();
            setByDay(data);
        }).catch(function (error) {
            console.log('err:', error);
        });
    };
    const scrollToTable = () => {
        pricesRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };
    const handleDuplicateOpen = () => {
        setDuplicationOpen(!duplicationOpen);
    };
    const checkScreen = () => {
        if (is_connected) {
            if (!fullScreen) {
                handleDuplicateOpen();
            } else {
                setMobileDialog(true);
            }
        } else {
            setLoginDialog(true);
        }
    };
    const handleScroll = (value) => {
        switch (value) {
            case 'summary':
                setTimeout(() => {
                    summaryCircuitRef.current.scrollIntoView({
                        // behavior: "smooth",
                        block: "center",
                        inline: "start"
                    });
                }, 250);
                break;
            case 'itinerary':
                itiRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start"
                });
                break;
            case 'prices':
                priceCalendarRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start"
                });
                break;
            case 'included':
                includeRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start"
                });
                break;
            case 'pictures':
                photoRef.current.scrollIntoView({
                    // behavior: "smooth",
                    block: "center",
                    inline: "start"
                });
                break;
        }
    };
    const onTabChange = (event, newValue) => {
        event.preventDefault();
        handleScroll(newValue);
        setTabValue(newValue);
    };
    const onTabClick = (value) => () => {
        if (value === tabValue) {
            handleScroll(value);
        }
    };
    const goBack = () => {
        if (dest_history.length > 1) {
            props.router.push(`/${window.url_name}/destination-product/${ dest_history[1].data.destination}`);
        } else {
            // dispatch(RemoveXHistorydest(dest_history.length - (index_dest + 1), null));
            props.router.push(`/home/${window.url_name}`);
        }
    };
    return (
        <Grid container style={{ display: "block", backgroundColor: '#F5F5F5', scrollBehavior: 'smooth' }}>
            {
                !props.forItinerary &&
                <Container className={classes.breadCrumb}>
                    <Grid container direction={"row"} alignItems={"center"} spacing={4} >
                        <Grid item >
                            {/* <MaterialBreadcrumb router={props.router}/> */}
                            <span className={classes.wrapIcon}>
                                <ArrowBackIcon className={clsx({ [classes.red]: ['visiteurs'].includes(quotation_code), [classes.orange]: !['visiteurs'].includes(quotation_code) })} size={"small"}/>
                            </span>
                            <span className={clsx({ [classes.basicLinkV]: ['visiteurs'].includes(quotation_code), [classes.basicLink]: !['visiteurs'].includes(quotation_code) })} onClick={goBack}>{t('itineraryType.return_to_product_list')}</span>
                        </Grid>
                    </Grid>
                </Container>
            }
            <Grid item className={"wrapper"} className={classes.headerPicture}>
                <CustomHomeSlider height={"Packages"} pictures={(itinerary_type_details_data !== null && itinerary_type_details_data.pictures !== null) ? itinerary_type_details_data.pictures : []} />
                <Grid item className={classes.absoluteCenter} style={{ width: fullScreen ? "100%" : "auto" }}>
                    <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                        {
                            itinerary_type_details_data !== null && (
                                <Grid item container direction={"column"} justify={"center"} alignItems={"center"} style={{ marginBottom: 50 }}>
                                    <Grid item>
                                        <Typography variant={"h2"} className={classes.destinationTitle}>{localeText(current_locale.id, itinerary_type_details_data.localization, itinerary_type_details_data.name, 'name')}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"h5"} style={{ color: "white", textShadow: "black 1px 0 10px", textAlign: "center" }} className={classes.genericText}>{ localeText(current_locale.id, itinerary_type_details_data.destination.data.localization, itinerary_type_details_data.destination.data.international_name !== undefined ? itinerary_type_details_data.destination.data.international_name : circuit_info.destination.data.name, 'name') }</Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            // !props.forItinerary &&
                            // <Grid item>
                            //     <Button variant={"contained"} fullWidth={fullScreen} color={"primary"} className={`${['visiteurs'].includes(quotation_code) ? classes.customButtonV : classes.customButton}`} onClick={checkScreen} >{t("itineraryType.choose_package")}</Button>
                            // </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {
                !props.forItinerary &&
                <Grid item className={classes.headerContainer} id={"home-picture"} style={{ display: "block" }}>
                    <Grid container direction={"row"} justify={"flex-end"}>
                        <Grid item className={"home-nav"} id={"home-nav"} style={{ top: 0 }}>
                            {
                                !is_connected && (
                                    <Fragment>
                                        <Grid item style={{ position: "absolute", left: 16, top: 20 }}>
                                            <Link to={ `/home/${window.url_name}/` } id={ "header-home-link" }>
                                                <img src={ logo !== null ? (logo.thumbnail_little !== null ? logo.thumbnail_little : logo.url) : "/Img/inner_favicon_header.png" } height={ 30 } alt={ "" }/>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Button style={{ color: "white" }} onClick={() => setLoginDialog(true)}>{t("home.nav.login")}</Button>
                                        </Grid>
                                    </Fragment>
                                )
                            }
                            {
                                is_connected && (
                                    <MaterialHeader router={props.router}/>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                (is_connected || ['visiteurs'].includes(quotation_code)) && !isLoading && (
                    <Grid item style={{ paddingBottom: fullScreen ? 120 : 70 }} ref={topContainerRef}>
                        <Container className={classes.container}>
                            {
                                // !props.forItinerary &&
                                // <Grid container direction={"row"} alignItems={"center"} spacing={4}>
                                //     <Grid item>
                                //         <MaterialBreadcrumb router={props.router}/>
                                //     </Grid>
                                // </Grid>
                            }
                            {
                                (summaryCircuitRef !== null || itiRef !== null || priceCalendarRef !== null) &&
                                <Tabs
                                    value={tabValue}
                                    onChange={onTabChange}
                                    variant={'fullWidth'}
                                    className={classes.tabs}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: `${main_color}`
                                        }
                                    }}
                                    centered
                                >
                                    <Tab value={'summary'} label={t('menu.trip_list.quotation_brief')} onClick={onTabClick('summary')}/>
                                    {type === 'circuit' && itinerary.length !== 0 && <Tab value={'itinerary'} label={t('apps_navigation.itinerary')} onClick={onTabClick('itinerary')}/>}
                                    {!priceEmpty && <Tab value={'prices'} label={t('shared.circuit-prices')} onClick={onTabClick('prices')}/>}
                                    {
                                        itinerary_type_details_data !== null && ((itinerary_type_details_data.advantage !== undefined && itinerary_type_details_data.advantage !== null && itinerary_type_details_data.advantage.length > 0) ||
                                        (itinerary_type_details_data.included_in_price !== undefined && itinerary_type_details_data.included_in_price !== null && itinerary_type_details_data.included_in_price.length > 0) ||
                                        (itinerary_type_details_data.extra !== undefined && itinerary_type_details_data.extra !== null && itinerary_type_details_data.extra.length > 0) ||
                                        (itinerary_type_details_data.child_reduction !== undefined && itinerary_type_details_data.child_reduction !== null && itinerary_type_details_data.child_reduction.length > 0)) &&
                                        <Tab value={'included'} label={t('itineraryType.additional_information')} onClick={onTabClick('included')}/>
                                    }
                                    {byDayPicures.length > 0 && <Tab value={'pictures'} label={t('summary.pictures')} onClick={onTabClick('pictures')}/>}
                                </Tabs>
                            }
                            {
                                itinerary_type_details_data !== null && (
                                    <Grid container direction={"row"} justify={"space-between"} spacing={8} style={{ marginTop: 20 }} id={'summary-circuit'}>
                                        <Grid item sm={ fullScreen ? 12 : 8} xs={12}>
                                            <Grid container direction={"column"} justify={"center"} alignItems={"flex-start"} spacing={2}>
                                                <Grid item ref={summaryCircuitRef}>
                                                    <Fab className={classes.fabColor} size={'small'} disableRipple={true} disableFocusRipple={true}>
                                                        <ArticleIcon/>
                                                    </Fab>
                                                    <span className={`${classes.genericText}`} style={{marginLeft: 10, fontWeight: 'bold'}}>{t('summary.trip_summary')}</span>
                                                </Grid>
                                                <Grid item style={{ width: '99%', marginLeft: 15 }}>
                                                    <CircuitSummary itinerary_type_details_data={itinerary_type_details_data} hotels={hotels} flights={flights} poi={poi} cars={cars} transfers={transfers} isItiLoading={isItiLoading} versionToShow={versionToShow} variantToShow={variantToShow}/>
                                                </Grid>
                                                {
                                                    itinerary_type_details_data.map_picture !== null && type === 'circuit' &&
                                                    <Grid item container justify={'center'} style={{ marginBottom: 20, marginTop: 20, width: "100%" }}>
                                                        <Grid item style={{width: "50%" }}>
                                                            <img className={classes.coverImg} src={itinerary_type_details_data.map_picture.thumbnail_little !== null ? itinerary_type_details_data.map_picture.thumbnail_little : itinerary_type_details_data.map_picture.url}/>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                {
                                                    itinerary_type_details_data.map_picture === null && type === 'circuit' &&
                                                    <Grid item style={{ marginBottom: 20, marginTop: 20, width: "102%" }}>
                                                        {/* <div className={ classes.coverImgMap } id={ "coverImgMap" }/> */}
                                                        {
                                                            isItiLoading ? (
                                                                <Skeleton variant={"rect"} width={"100%"} height={500}/>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        itinerary.length !== 0 &&
                                                                        <ItineraryTypeDetailsMap/>
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </Grid>
                                                }
                                                {
                                                    localeText(current_locale.id, itinerary_type_details_data.localization, itinerary_type_details_data.description, 'description') !== '' &&
                                                    <Grid item style={{ width: "102%" }}>
                                                        <Grid container>
                                                            <Grid item xs={12} className={`${classes.genericText} ${classes.textJustify}`}>
                                                                <div className={classes.border} style={{width: '100%'}} dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, itinerary_type_details_data.localization, itinerary_type_details_data.description, 'description') }}/>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                            {
                                                itinerary_type_details_data.strengths !== undefined && itinerary_type_details_data.strengths !== null && itinerary_type_details_data.strengths.length > 0 && (
                                                    <Grid container direction={"column"} spacing={2} style={{ marginTop: 20 }} className={classes.genericText}>
                                                        <Grid item container xs className={classes.border} style={{ width: "100%", marginLeft: 6 }} justify={"center"}>
                                                            <Typography variant={"h4"} >{t("itineraryType.strong_points")}</Typography>
                                                        </Grid>
                                                        <Grid item className={classes.border} style={{ marginLeft: 6 }}>
                                                            {
                                                                itinerary_type_details_data.strengths.map((strength, strength_index) => {
                                                                    return (
                                                                        <div className={classes.listContainer} key={strength_index} ><ul><li>{ strength }</li></ul></div>
                                                                    );
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            <Grid item container>
                                                {
                                                    isItiLoading && (
                                                        <Grid item container direction={"row"} justify={"center"}>
                                                            <Skeleton variant={"rect"} width={"100%"} height={500}/>
                                                        </Grid>
                                                    )
                                                }
                                                {
                                                    !isItiLoading && (
                                                        <RenderDetailStep
                                                            itiRef={itiRef}
                                                            byDay={byDay}
                                                            itinerary={itinerary}
                                                            versionToShow={versionToShow}
                                                            cars={cars}
                                                            flights={flights}
                                                            hotels={hotels}
                                                            poi={poi}
                                                            transfers={transfers}
                                                            products={sortProducts}
                                                            variantToShow={variantToShow}
                                                            variantIsChecked={variantIsChecked}
                                                            onToggleVariant={onToggleVariant}
                                                        />
                                                    )
                                                }
                                                {
                                                    !priceEmpty &&
                                                <Grid item ref={priceCalendarRef}>
                                                    <Fab className={classes.fabColor} size={'small'} disableRipple={true} disableFocusRipple={true}>
                                                        <AttachMoneyIcon/>
                                                    </Fab>
                                                    <span className={`${classes.genericText}`} style={{ marginLeft: 10, fontWeight: 'bold' }}>{t('shared.circuit-prices')}</span>
                                                </Grid>
                                                }
                                                {
                                                    versionToShow !== undefined && versionToShow !== "" && (
                                                        <Grid item sm={12} className={fullScreen ? classes.fullWidth : ""}>
                                                            <PriceCalendar
                                                                dateIsAfter={dateIsAfter}
                                                                setDateIsAfter={setDateIsAfter}
                                                                type={"circuits"}
                                                                versionToShow={versionToShow}
                                                                itinerary_data={itinerary_type_details_data}
                                                                products={products}
                                                                variantToShow={variantToShow}
                                                                setPriceEmpty={setPriceEmpty}
                                                                priceEmpty={priceEmpty}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                                {/* <Grid item ref={summaryCircuitRef}> */}
                                                {
                                                    ((itinerary_type_details_data.advantage !== undefined && itinerary_type_details_data.advantage !== null && itinerary_type_details_data.advantage.length > 0) ||
                                                (itinerary_type_details_data.included_in_price !== undefined && itinerary_type_details_data.included_in_price !== null && itinerary_type_details_data.included_in_price.length > 0) ||
                                                (itinerary_type_details_data.excluded_in_price !== undefined && itinerary_type_details_data.excluded_in_price !== null && itinerary_type_details_data.excluded_in_price.length > 0) ||
                                                (itinerary_type_details_data.extra !== undefined && itinerary_type_details_data.extra !== null && itinerary_type_details_data.extra.length > 0) ||
                                                (itinerary_type_details_data.supported_languages !== undefined && itinerary_type_details_data.supported_languages !== null && itinerary_type_details_data.supported_languages.length > 0) ||
                                                (itinerary_type_details_data.child_reduction !== undefined && itinerary_type_details_data.child_reduction !== null && itinerary_type_details_data.child_reduction.length > 0) ||
                                                (itinerary_type_details_data.keywords !== undefined && itinerary_type_details_data.keywords !== null && itinerary_type_details_data.keywords !== '')) &&
                                                <Fragment>
                                                    <Grid item ref={includeRef} style={{ marginTop: 10, marginBottom: 10 }}>
                                                        <Fab className={classes.fabColor} size={'small'} disableRipple={true} disableFocusRipple={true}>
                                                            <CheckOutlinedIcon/>
                                                        </Fab>
                                                        <span className={`${classes.genericText}`} style={{ marginLeft: 10, fontWeight: 'bold' }}>{t('itineraryType.additional_information')}</span>
                                                    </Grid>
                                                    <Grid item sm={12}>
                                                        <RenderExtra
                                                            pricesRef={pricesRef}
                                                            itinerary_type_details_data={itinerary_type_details_data}
                                                        />
                                                    </Grid>
                                                </Fragment>
                                                }
                                                {
                                                    fullScreen && (
                                                        <Grid container className={ classes.staticPriceContainer } direction={"column"} justify={ "center" }>
                                                            {
                                                                !props.forItinerary &&
                                                            <Grid item>
                                                                <Button variant={ "contained" } className={ classes.orangeButton } fullWidth={ true } onClick={ checkScreen } >{t("itineraryType.choose_package")}</Button>
                                                            </Grid>
                                                            }
                                                            <Grid item style={{ textAlign: "right" }}>
                                                                <div style={{ fontSize: 12, textDecoration: "underline", cursor: "pointer" }} onClick={scrollToTable}>{t("itineraryType.see_prices")}</div>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }
                                                {
                                                    byDayPicures.length !== 0 &&
                                                <Grid item ref={photoRef} style={{ marginBottom: 20, marginTop: 20 }}>
                                                    <Fab className={classes.fabColor} size={'small'} disableRipple={true} disableFocusRipple={true}>
                                                        <CameraAltIcon/>
                                                    </Fab>
                                                    <span className={`${classes.genericText}`} style={{ marginLeft: 10, fontWeight: 'bold' }}>{t('summary.pictures')}</span>
                                                </Grid>
                                                }
                                                {
                                                    byDayPicures.length !== 0 && (
                                                        <ByDayPicture pictures={byDayPicures} is_step={false}/>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                        {
                                            !fullScreen && (
                                                <Grid item sm={4} className={classes.paddingRight0}>
                                                    <Card className={ `${classes.stickyPaper} ${classes.genericText}` }>
                                                        <div style={{ textAlign: 'center', fontSize: 20, backgroundColor: `${main_color}`, color: 'white', fontWeight: 'bold', padding: 10 }}>{t('shared.trip-summary')}</div>
                                                        <RightPanel
                                                            checkScreen={checkScreen}
                                                            options={options}
                                                            assurances={assurances}
                                                            selectedOptions={selectedOptions}
                                                            setSelectedOptions={setSelectedOptions}
                                                            pricesRef={pricesRef}
                                                            setSalePitch={setSalePitch}
                                                            dateIsAfter={dateIsAfter}
                                                            versionAvailable={versionAvailable}
                                                            setVersionToShow={setVersionToShow}
                                                            versionToShow={versionToShow}
                                                            setIsItiLoading={setIsItiLoading}
                                                            variantToShow={variantToShow}
                                                            variantAvailable={variantAvailable}
                                                            setVariantToShow={setVariantToShow}
                                                            topContainerRef={topContainerRef}
                                                            itinerary_data={itinerary_type_details_data}
                                                            forItinerary={props.forItinerary}
                                                            selectedVariant={selectedVariant}
                                                            products={products}
                                                            isItiLoading={isItiLoading}
                                                            onClose={props.onClose}
                                                        />
                                                    </Card>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                )
                            }
                        </Container>
                    </Grid>
                )
            }
            {
                (is_connected || ['visiteurs'].includes(quotation_code)) && isLoading && (
                    <Grid item style={{ paddingBottom: 70 }}>
                        <Container className={classes.container}>
                            <Grid container direction={"row"} justify={"space-between"} spacing={8} style={{ marginTop: 20 }}>
                                <Grid item sm={12}>
                                    <Skeleton variant={"rect"} width={'100%'} height={50}/>
                                </Grid>
                                <Grid item sm={ fullScreen ? 12 : 8} xs={12}>
                                    <CircuitSkeleton/>
                                    {/* <Skeleton variant={"rect"} width={'100%'} height={500}/> */}
                                </Grid>
                                {
                                    !fullScreen && (
                                        <Grid item sm={4}>
                                            <Skeleton variant={"rect"} width={300} height={300}/>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Container>
                    </Grid>
                )
            }
            {
                fullScreen && (
                    <MobileRightPanel
                        mobileDialog={mobileDialog}
                        setMobileDialog={setMobileDialog}
                        checkScreen={checkScreen}
                        options={options}
                        assurances={assurances}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        pricesRef={pricesRef}
                        setSalePitch={setSalePitch}
                        dateIsAfter={dateIsAfter}
                        versionAvailable={versionAvailable}
                        setVersionToShow={setVersionToShow}
                        versionToShow={versionToShow}
                        setIsItiLoading={setIsItiLoading}
                        variantToShow={variantToShow}
                        variantAvailable={variantAvailable}
                        setVariantToShow={setVariantToShow}
                        topContainerRef={topContainerRef}
                        itinerary_data={itinerary_type_details_data}
                        forItinerary={props.forItinerary}
                        selectedVariant={selectedVariant}
                        products={products}
                        onClose={props.onClose}
                    />
                )
            }
            <MaterialDuplicateDialog
                duplicationOpen={duplicationOpen}
                setDuplicationOpen={setDuplicationOpen}
                isFrom={"package"}
                duration_day={itinerary_type_details_data !== null ? itinerary_type_details_data.duration_day : 0}
                hotels={hotels}
                flights={flights}
                poi={poi}
                assurances={assurances}
                cars={cars}
                selectedVariant={selectedVariant}
                selectedOptions={selectedOptions}
                router={props.router}
                versionToShow={versionToShow}
                variantToShow={variantToShow}
            />
            <HotelInfo/>
            <SalePitchDialog salePitch={salePitch} setSalePitch={setSalePitch} infos={itinerary_type_details_data}/>
            <MaterialLogin loginDialog={loginDialog} setLoginDialog={setLoginDialog} logo={logo} router={props.router} token={null} id={null}/>
            { JSON.parse(localStorage.getItem("config")).quotation_code === 'visiteurs' && <Help/> }
        </Grid>
    );
};
export default CircuitsDetails;
