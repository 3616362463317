import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Chip,
    Divider,
    ListSubheader,
    Menu,
    MenuItem,
    Stack,
    styled,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import i18next from "i18next";
import { MailTemplateVisualEditor } from "./MailVisualEditor/mailTemplateVisualEditor";
import { MailTemplateMainRecipient } from "./MailVisualEditor/mailTemplateMainRecipient";
import { MailTemplateCCRecipients } from "./MailVisualEditor/mailTemplateCCRecipients";
import { MailTemplateVisualEditorDragArea } from "./MailVisualEditor/mailTemplateVisualEditorDragArea";
import { MailTemplateHTMLCodeEditor } from "./MailVisualEditor/mailTemplateHTMLCodeEditor";
import { MailTemplateVisualEditorContext } from "./MailVisualEditor/mailTemplateVisualEditorContext";
import { useSendMail } from "./Functions/useSendMail";
import { renderHtml } from "./MailVisualEditor/utils/render-html";
import { isHtmlFromVisualEditor } from "./MailVisualEditor/utils/is-html-from-visual-editor";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { isEmailValid } from "./utils/editor/isEmailValid";
import { resetEditors, setMailTemplates } from "./MailVisualEditor/redux/actions";
import { AutocompleteTranslations } from "./MailVisualEditor/objects/autocompleteTranslations";
import { MailTemplate } from "./MailVisualEditor/objects/mailTemplate";
import { Recipient } from "./MailVisualEditor/objects/recipient";
import { AppState } from "../../../Reducers/Reducers";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type Inputs = {
    fromName: string,
    fromEmail: string,
    to: Recipient | null,
    cc: Recipient[],
    object: string
}

type Props = {
    actions?: string[],
    setLoading: (loading: boolean) => void,
    onRefresh?: () => void,
    onClose?: () => void,
    submitAction?: () => void,
    checkMinisiteTag?: boolean,
    checkLeadTraveler?: boolean,
    autofill?: boolean,
    customData?: any,
}

const defaultInputs: Inputs = {
    fromName: '',
    fromEmail: 'dev@facilitatrip.com',
    to: null,
    cc: [],
    object: ''
};

export function TripListSendMailForm(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user.user);
    const locales = useSelector((state: AppState) => state.user.locales);
    const quotation_code = JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code;
    const templates = useSelector((state: AppState) => state.mailTemplate.others.mailTemplates)?.filter((item) => {
        return !props.actions || props.actions.includes(item.action);
    });
    const [inputs, setInputs] = useState<Inputs>(defaultInputs);
    const [chosenTemplate, setChosenTemplate] = useState<{locale: number | null, id: number} | null>(null);
    const [deployInputs, setDeployInputs] = useState(false);
    const [openTemplateMenu, setOpenTemplateMenu] = useState(false);
    const templateButton = useRef(null);

    const getContentHtml = () => {
        const template = templates?.find((item) => {
            return item.id === chosenTemplate?.id;
        });
        const localizedTemplate = template?.localization.find((item) => {
            return item.locale === chosenTemplate?.locale;
        });
        if (localizedTemplate) {
            return localizedTemplate.template;
        }
        return template?.localization[0]?.template;
    };
    const contentHtml = getContentHtml();

    function onChangeInput<K extends keyof Inputs>(key: K, value: Inputs[K]): void {
        setInputs((state) => ({
            ...state,
            [key]: value
        }));
    }

    const onToggleTemplateMenu = () => {
        setOpenTemplateMenu((state) => !state);
    };

    const onCloseTemplateMenu = () => {
        setOpenTemplateMenu(false);
    };

    const onChooseTemplate = (template: typeof chosenTemplate) => {
        setChosenTemplate(template);
    };

    const fillInputs = (template: MailTemplate) => {
        let fromName = template.from_name ?? '';

        if (user) {
            fromName = `${user.first_name} ${user.last_name}`.trim();
        }

        const newInputs: Inputs = {
            to: {
                type: isEmailValid(template.main_recipient ?? '') ?
                    'static' :
                    'variable',
                empty: !template.main_recipient,
                value: template.main_recipient ?? ''
            },
            fromName,
            fromEmail: template.from_mail && template.from_mail.length > 0 ?
                template.from_mail :
                defaultInputs.fromEmail,
            cc: template.cc_recipients?.map((recipient) => ({
                type: isEmailValid(recipient ?? '') ?
                    'static' :
                    'variable',
                empty: false,
                value: recipient ?? ''
            })) ?? [],
            object: template.subject
        };
        if (newInputs.cc.length > 0) {
            setDeployInputs(true);
        }
        setInputs(newInputs);
    };

    useEffect(() => {
        if (!templates) {
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                (async () => {
                    try {
                        const response = await axios.get<{results: MailTemplate[]}>(
                            `${API_HREF}client/${window.id_owner}/templates/`,
                            {
                                params: {
                                    limit: 100000,
                                    activated: true,
                                    display_in_apps: true,
                                    ordering: 'name',
                                    offset: 0
                                },
                                headers
                            }
                        );
                        dispatch(setMailTemplates(response.data.results));
                    } catch (error) {
                        console.error(error);
                    }
                })();
            }
        }
    }, [templates]);

    useEffect(() => {
        const template = templates?.find((item) => {
            return item.id === chosenTemplate?.id;
        });
        if (template) {
            fillInputs(template);
        }
    }, [chosenTemplate]);

    useEffect(() => {
        const template = templates ? templates[0] : null;
        if (props.autofill && template) {
            fillInputs(template);
            const locale = template.localization[0]?.locale ?? null;
            onChooseTemplate({ id: template.id, locale });
        }
    }, [props.autofill, templates?.length]);

    useEffect(() => {
        return () => {
            dispatch(resetEditors());
        };
    }, []);

    return (
        <Container>
            <MailTemplateVisualEditor
                instanceId="trip-list-send-mail-form"
                placeholder={t<string>('menu.email-history.choose-template')}
                renderContentArea={() => (
                    <ContentEditorContainer>
                        <ContentEditoHeader>
                            <Stack direction="row" sx={{ marginBottom: 1.5 }} justifyContent="space-between" alignItems="center">
                                <ContentEditorLeftHeader direction="row" spacing={1} alignItems="center">
                                    <ContentEditorTemplate
                                        ref={templateButton}
                                        variant="body2"
                                        component="button"
                                        onClick={onToggleTemplateMenu}
                                    >
                                        <MailOutlineIcon />
                                        {t<string>('menu.email-history.template')}
                                        <KeyboardArrowDownIcon />
                                    </ContentEditorTemplate>
                                    <Menu
                                        anchorEl={templateButton.current}
                                        open={openTemplateMenu}
                                        onClose={onCloseTemplateMenu}
                                        PaperProps={{
                                            style: {
                                                maxHeight: 250
                                            }
                                        }}
                                    >
                                        {
                                            templates && ['cercledesvoyages', 'connections', 'terreslointaines', 'tropicalementvotre'].includes(quotation_code) && templates.map((template) => (
                                                <MenuItem
                                                    key={template.id}
                                                    onClick={() => onChooseTemplate({
                                                        id: template.id,
                                                        locale: null
                                                    })}
                                                >
                                                    {template.name}
                                                </MenuItem>
                                            ))
                                        }
                                        {
                                            templates && !['cercledesvoyages', 'connections', 'terreslointaines', 'tropicalementvotre'].includes(quotation_code) && templates.map((template) => [
                                                <ListSubheader key={`group-${template.id}`}>{template.name}</ListSubheader>,
                                                <TemplateLocale
                                                    key={`${template.id}-0`}
                                                    onClick={() => onChooseTemplate({
                                                        id: template.id,
                                                        locale: null
                                                    })}
                                                >
                                                    {t<string>('menu.email-history.default')}
                                                </TemplateLocale>,
                                                ...template.localization.map((item) => (
                                                    <TemplateLocale
                                                        key={`${template.id}-${item.locale}`}
                                                        onClick={() => onChooseTemplate({
                                                            id: template.id,
                                                            locale: item.locale
                                                        })}
                                                    >
                                                        {
                                                            locales.find((locale) => {
                                                                return locale.id === item.locale;
                                                            })?.full_name ?? `Unknown locale: ${item.locale}`
                                                        }
                                                    </TemplateLocale>
                                                ))
                                            ])
                                        }
                                    </Menu>
                                    {
                                        !deployInputs &&
                                        <>
                                            <Divider
                                                orientation="vertical"
                                                sx={{ height: '15px', alignSelf: 'center', marginLeft: '0 !important' }}
                                                flexItem
                                            />
                                            <ToInput
                                                value={inputs.to}
                                                onChange={(value) => onChangeInput('to', value)}
                                            />
                                        </>
                                    }
                                </ContentEditorLeftHeader>
                                {
                                    !deployInputs &&
                                    <ContentEditorCCInputContainer
                                        variant="body2"
                                        component="div"
                                        onClick={() => setDeployInputs(true)}
                                    >
                                        {t<string>('menu.email-history.cc')}
                                    </ContentEditorCCInputContainer>
                                }
                            </Stack>
                            <Stack spacing={1}>
                                {
                                    deployInputs &&
                                    <>
                                        <ToInput
                                            value={inputs.to}
                                            onChange={(value) => onChangeInput('to', value)}
                                        />
                                        <MailTemplateCCRecipients
                                            size="small"
                                            variant="standard"
                                            value={inputs.cc}
                                            startAdornment={(
                                                <Typography variant="body2" component="div">
                                                    CC
                                                </Typography>
                                            )}
                                            mainRecipient={inputs.to}
                                            onChange={(value) => onChangeInput('cc', value)}
                                        />
                                    </>
                                }
                                <TextField
                                    placeholder={t<string>('menu.email-history.sender-name')}
                                    variant="standard"
                                    value={inputs.fromName}
                                    onChange={(event) => onChangeInput('fromName', event.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    placeholder={t<string>('menu.email-history.sender-email')}
                                    variant="standard"
                                    value={inputs.fromEmail}
                                    onChange={(event) => onChangeInput('fromEmail', event.target.value)}
                                    disabled
                                    fullWidth
                                />
                                <TextField
                                    placeholder={t<string>('menu.email-history.subject')}
                                    variant="standard"
                                    value={inputs.object}
                                    onChange={(event) => onChangeInput('object', event.target.value)}
                                    fullWidth
                                />
                            </Stack>
                        </ContentEditoHeader>
                        <Box sx={{ overflow: 'auto', height: '50vh' }}>
                            {
                                !contentHtml || isHtmlFromVisualEditor(contentHtml) ?
                                    <MailTemplateVisualEditorDragArea /> :
                                    <MailTemplateHTMLCodeEditor defaultHtml={contentHtml} />
                            }
                        </Box>
                        <Actions direction="row" justifyContent="flex-end" spacing={1}>
                            {
                                props.onClose &&
                                <Button onClick={props.onClose}>
                                    {t<string>('global.cancel')}
                                </Button>
                            }
                            <SendButton
                                inputs={inputs}
                                setLoading={props.setLoading}
                                onChangeInputs={setInputs}
                                onChangeTemplate={setChosenTemplate}
                                chosenTemplate={ chosenTemplate }
                                checkMinisiteTag={props.checkMinisiteTag}
                                checkLeadTraveler={props.checkLeadTraveler}
                                submitAction={props.submitAction}
                                customData={props.customData}
                            />
                        </Actions>
                    </ContentEditorContainer>
                )}
                defaultHtml={contentHtml}
                disablePreview={!isHtmlFromVisualEditor(contentHtml ?? '')}
                disableBlocksEdit
            />
        </Container>
    );
}

type ToInputProps = {
    value: Recipient | null,
    onChange: (value: Recipient | null) => void
}

function ToInput(props: ToInputProps): JSX.Element {
    const { t } = useTranslation();
    const autocomplete = useSelector((state: AppState) => state.mailTemplate.others.autocomplete);
    const translations = autocomplete.state === 'success' ?
        autocomplete.data.translations :
        { action: {}, fields: {}, recipients: {} } as AutocompleteTranslations;

    const translateRecipient = (recipient: Recipient) => {
        if (recipient.type === 'variable') {
            const label = translations.recipients[recipient.value] ?? {};
            return label[i18next.language] ?? Object.values(label)[0] ?? recipient.value;
        }
        return recipient.value;
    };

    return (
        <Stack direction="row" width="100%" alignItems="center" spacing={1}>
            <Typography variant="body2" component="div">
                {t<string>('menu.email-history.to')}
            </Typography>
            <ContentEditorToInputContainer>
                {
                    props.value &&
                    <Tooltip title={props.value.info}>
                        <Chip
                            avatar={<AccountCircleIcon />}
                            label={translateRecipient(props.value)}
                            onDelete={() => props.onChange(null)}
                        />
                    </Tooltip>
                }
                {
                    !props.value &&
                    <MailTemplateMainRecipient
                        size="small"
                        variant="standard"
                        onChange={(value) => props.onChange(value)}
                        removeLabel
                    />
                }
            </ContentEditorToInputContainer>
        </Stack>
    );
}

type SendButtonProps = {
    inputs: typeof defaultInputs,
    setLoading: (loading: boolean) => void,
    checkMinisiteTag?: boolean,
    checkLeadTraveler?: boolean,
    onChangeInputs: (inputs: typeof defaultInputs) => void,
    onChangeTemplate: (template: {locale: number | null, id: number} | null) => void,
    chosenTemplate: {locale: number | null, id: number} | null,
    onRefresh?: () => void,
    onClose?: () => void,
    submitAction?: () => void,
    customData?: any,
}

function SendButton(props: SendButtonProps): JSX.Element {
    const context = useContext(MailTemplateVisualEditorContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const trip = useSelector((state: AppState) => state.menu.trip_info);
    const to_show_version = useSelector((state: AppState) => state.menu.to_show_version);
    const bodyStyles = useSelector((state: AppState) => {
        return state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorBodyStyles;
    });
    const blocks = useSelector((state: AppState) => {
        return state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorBlocks;
    });
    const displayedVersion = to_show_version !== null ? to_show_version : trip?.current_version;
    const assistances = useSelector((state: AppState) => state.cart.assistance_cart);
    const total_cost = useSelector((state: AppState) => state.trip.total_cost);
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const user = useSelector(state => state.user.user);
    const currency_list = useSelector(state => state.base.currency_list);
    const templates = useSelector((state: AppState) => state.mailTemplate.others.mailTemplates);
    const leadTraveler = trip?.data.find((item) => {
        return item.id === displayedVersion;
    })?.contact_traveler_detail;
    const send = useSendMail({
        customData: props.customData,
        onTrigger: () => {
            props.setLoading(true);
        },
        onSuccess: () => {
            enqueueSnackbar(
                t<string>('menu.email-history.mail-sent'),
                { variant: 'success' }
            );
            props.onChangeInputs(defaultInputs);
            props.onChangeTemplate(null);
            dispatch(resetEditors());
            if (props.onRefresh) {
                props.onRefresh();
            }
            if (props.onClose) {
                props.onClose();
            }
        },
        onError: (error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        },
        onFinally: () => {
            props.setLoading(false);
        }
    });
    const quotationCode = JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code;
    const priceToDisplay = price => {
        return {
            cost: parseFloat(price.selling_price),
            currency: currency_list.find(currency => currency.id === price.selling_currency)
        };
    };
    const getPrice = prices => {
        if (user.client_full.type === 1 || user.client_full.type === 3 || quotationCode === 'verdie') {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_surcom) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].master_price) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return priceToDisplay(prices[i]);
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return priceToDisplay(prices[i]);
                }
            }
        }
        return {
            cost: 0
        };
    };
    const onSend = () => {
        if (props.submitAction !== undefined && props.submitAction !== null) {
            props.submitAction();
        }
        if (
            ['cercledesvoyages', 'tropicalementvotre', 'continentsinsolites'].includes(quotationCode) &&
            props.checkLeadTraveler &&
            (
                !leadTraveler?.email || leadTraveler.email.trim().length === 0
            )
        ) {
            enqueueSnackbar(
                t<string>('menu.email-history.lead-traveler-email-not-found'),
                { variant: 'error' }
            );
            return;
        }
        if (
            props.checkLeadTraveler &&
            quotationCode === 'cercledesvoyages' &&
            (
                !leadTraveler?.phone_number ||
                leadTraveler.phone_number.trim().length === 0
            ) &&
            (
                !leadTraveler?.phone_number_house ||
                leadTraveler?.phone_number_house.trim().length === 0
            ) &&
            (
                !leadTraveler?.phone_number_work ||
                leadTraveler?.phone_number_work.trim().length === 0
            )
        ) {
            enqueueSnackbar(
                t('menu.email-history.lead-traveler-phone-not-found'),
                { variant: 'error' }
            );
            return;
        }
        const insurances = assistances.filter(item => item.product_type === 8);
        let insurance_up_to_date = true;
        insurances.map((insurance) => {
            if (!insurance.is_hidden_for_traveler) {
                if ((insurance.trip_price > Math.round(((total_cost - getPrice(insurance.prices).cost) / travelers.length * insurance.group_passenger.travelers.length)) + 1 || insurance.trip_price < Math.round(((total_cost - getPrice(insurance.prices).cost) / travelers.length * insurance.group_passenger.travelers.length)) - 1)) {
                    insurance_up_to_date = false;
                }
            }
        });
        const trip_custom = templates?.filter(template => template.id === props.chosenTemplate?.id && template.action === 'TRIP_CUSTOM');
        if (!insurance_up_to_date && (trip_custom?.length ?? 0) > 0) {
            enqueueSnackbar(
                t<string>('menu.email-history.insurance-not-up-to-date'),
                { variant: 'error' }
            );
            return;
        }
        const content = Object.values(blocks ?? {})[0];
        
        if (content && props.inputs.to && bodyStyles) {
            send(
                {
                    from: {
                        email: props.inputs.fromEmail,
                        name: props.inputs.fromName
                    },
                    to: props.inputs.to.value,
                    cc: props.inputs.cc.map((item) => item.value),
                    object: props.inputs.object,
                    content: renderHtml(bodyStyles, content, quotationCode)
                },
                props.checkMinisiteTag
            );
        }
    };

    return (
        <Button variant="contained" onClick={onSend}>
            {t<string>('menu.email-history.send-mail')}
        </Button>
    );
}

const Container = styled('div')((props) => ({
    marginBottom: props.theme.spacing(1.5)
}));

const Actions = styled(Stack)((props) => ({
    marginTop: props.theme.spacing(1)
}));

const TemplateLocale = styled(MenuItem)((props) => ({
    paddingLeft: props.theme.spacing(5) + ' !important'
}));

const ContentEditorContainer = styled('div')((props) => ({
    border: '1px solid #1F73B8',
    borderRadius: props.theme.shape.borderRadius,
    padding: props.theme.spacing(2),
    paddingTop: 0
}));

const ContentEditoHeader = styled('div')((props) => ({
    marginBottom: props.theme.spacing(1.5)
}));

const ContentEditorLeftHeader = styled(Stack)(() => ({
    width: '35%'
}));

const ContentEditorTemplate = styled(Typography)((props) => ({
    "border": 'none',
    "background": 'none',
    "cursor": 'pointer',
    "display": 'flex',
    "alignItems": 'center',
    "gap": '5px',
    "fontWeight": 500,
    "height": 48,
    "paddingLeft": props.theme.spacing(1.5),
    "paddingRight": props.theme.spacing(1.5),
    "&:hover": {
        backgroundColor: 'rgba(31, 115, 183, 0.08)',
        color: 'rgb(20, 74, 117)'
    }
})) as typeof Typography;

const ContentEditorToInputContainer = styled('div')((props) => ({
    width: '100%',
    marginLeft: props.theme.spacing(1.5)
}));

const ContentEditorCCInputContainer = styled(Typography)(() => ({
    color: '#1D6BAB',
    cursor: 'pointer'
})) as typeof Typography;
