//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import SortByKey from "../../Common/SortByKey";
import GetAllPrice from "../Packages/GetAllPrices.js";
import GetCalendarData from "../Packages/GetCalendarData";
import LocaleTextVariant from '../../Utils/localeText/localTextVariant';
import { getCircuitPeriods } from '../../Itinerary/utils/getCircuitPeriods';

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    justify: {
        textAlign: "justify"
    },
    font10: {
        fontSize: 10
    },
    bold: {
        fontWeight: "bold"
    },
    font16: {
        fontSize: 16
    },
    formControlRoot: {
        "width": "100%",
        "&& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 10px'
        },
        "&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: 8
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "#0000008A"
        },
        "& .mui-jss-MuiFormLabel-root": {
            //fontWeight: "bold",
            color: "#0000008A",
            //fontSize: 12,
            transform: 'translate(20px, 10px) scale(1)'
        },
        "& .mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        }
    },
    border: {
        border: '1px solid #ebebeb',
        borderRadius: 13,
        padding: 10,
        marginBottom: 10,
        backgroundColor: 'white',
        marginRight: 7,
        marginLeft: 0,
        marginTop: 10
    }
}));

const PriceCalendar = ({ dateIsAfter, setDateIsAfter, type, versionToShow, itinerary_data, products, variantToShow, setPriceEmpty, priceEmpty }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();

    const package_info = useSelector(store => store.itinerary_type.itinerary_type_details_data) ??
        itinerary_data;
    const [prices, setPrices] = useState(null);
    const [newsortData, setNewSortData] = useState(null);
    const [filterData, setFilterData] = useState(null);
    const [priceData, setPriceData] = useState(null);
    const [info, setInfo] = useState([]);
    const [dates, setDates] = useState([]);
    const [airportList, setAirportList] = useState([]);
    const [departureAirport, setDepartureAirport] = useState("");
    const [periods, setPeriods] = useState([]);

    let currency_symbol = "-";
    let currency_iso = null;

    if (package_info.trip.currency !== undefined && package_info.trip.currency !== null) {
        currency_symbol = package_info.trip.currency.symbol;
        currency_iso = package_info.trip.currency.iso_code;
    }

    const compareArray = (arr1, arr2) => {
        if (arr1 === []) {
            return true;
        }
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].id !== arr2[i].id) {
                return false;
            }
        }
        return true;
    };
    const getPrice = () => {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            if (package_info !== null && !package_info.forfait_price) {
                // let contract_id = package_info.trip.contract_id !== null ? package_info.trip.contract_id : (package_info.contracts !== null && package_info.contracts.length !== 0 ? package_info.contracts[0].id : null);
                let variant = 0;
                if (package_info.variant_name !== null && package_info.variant_name.length > 0) {
                    variant = package_info.variant_name[0].id;
                }
                // (async () => {
                //     let periods = await getCircuitPeriods(package_info.id);
                //     console.log('versionToShow:', versionToShow);
                //     console.log('variantToShow:', variantToShow);
                //     setPeriods(
                //         periods.filter((item) => {
                //             return item.trip_version === versionToShow &&
                //                 item.variant === variantToShow;
                //             // window.moment.utc(item.start_date).startOf('day').isSameOrAfter(
                //             //     window.moment.utc().startOf('day'),
                //             //     'day'
                //             // );
                //         })
                //     );
                // })();
                GetAllPrice(package_info, package_info.id, setPriceData, versionToShow, variant, type, dispatch);
            } else {
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/${type}/${package_info.id}/prices/`
                }).then(function (response) {
                    let data = response.data.slice();
                    console.log('data:', data);
                    setPrices(data);
                    dispatch({ type: "ITINERARY_SET_PRICE_DATA", payload: data });
                    //sortData(data);
                }).catch(function (error) {
                    console.log('error:', error);
                });
            }
        }
    };
    const sortData = (data) => {
        if (data === null) {
            return;
        }
        let tmp_all_dates = [];
        let tmp_variant = [];
        let tmp_airport_list = [];
        let isAfter = false;

        for (let i = 0; i < data.length; i++) {
            let is_already_in_array = false;
            for (let k = 0; k < tmp_variant.length; k++) {
                if (tmp_variant[k].variant === data[i].variant) {
                    is_already_in_array = true;
                    break;
                }
            }
            if (!is_already_in_array) {
                tmp_variant.push({
                    variant: data[i].variant,
                    airport_list: []
                });
            }
        }
        let sorted_arr = data.sort(SortByKey(true, "start_date"));
        //Get all dates
        for (let i = 0; i < sorted_arr.length; i++) {
            let is_same = false;
            for (let j = 0; j < tmp_all_dates.length; j++) {
                if (tmp_all_dates[j].start_date === sorted_arr[i].start_date && tmp_all_dates[j].end_date === sorted_arr[i].end_date) {
                    is_same = true;
                    break;
                }
            }
            if (!is_same) {
                if (type === "packages" || (type === "circuits" && versionToShow !== undefined && versionToShow === sorted_arr[i].trip_version)) {
                    tmp_all_dates.push({
                        start_date: sorted_arr[i].start_date,
                        end_date: sorted_arr[i].end_date
                    });
                }
            }
        }
        for (let i = 0; i < sorted_arr.length; i++) {
            if (type === "packages" || (type === "circuits" && versionToShow !== undefined && versionToShow === sorted_arr[i].trip_version)) {
                for (let o = 0; o < tmp_variant.length; o++) {
                    if (sorted_arr[i].name !== "" && sorted_arr[i].name !== null) {
                        if (sorted_arr[i].airports.length === 0 && tmp_variant[o].airport_list.length === 0) {
                            let price = [];
                            price.push((package_info.flight_included ? parseFloat(sorted_arr[i].price_flight) : 0) + parseFloat(sorted_arr[i].price_xml) + parseFloat(sorted_arr[i].price_custom_product));
                            tmp_variant[o].airport_list.push({
                                airports: [],
                                name: sorted_arr[i].name,
                                prices: price,
                                date: [{
                                    start_date: sorted_arr[i].start_date,
                                    end_date: sorted_arr[i].end_date
                                }]
                            });
                        } else if (tmp_variant[o].variant === sorted_arr[i].variant) {
                            let is_already_in_array = false;
                            for (let k = 0; k < tmp_variant[o].airport_list.length; k++) {
                                if (sorted_arr[i].name === tmp_variant[o].airport_list[k].name) {
                                    is_already_in_array = true;
                                    let index = tmp_all_dates.findIndex((el) => {
                                        return el.start_date === sorted_arr[i].start_date && el.end_date === sorted_arr[i].end_date;
                                    });
                                    let item = (package_info.flight_included ? parseFloat(sorted_arr[i].price_flight) : 0) + parseFloat(sorted_arr[i].price_xml) + parseFloat(sorted_arr[i].price_custom_product);
                                    let slice_arr = tmp_variant[o].airport_list[k].prices.slice();
                                    slice_arr[index] = item;
                                    tmp_variant[o].airport_list[k].prices = slice_arr;
                                }
                            }
                            if (is_already_in_array === false) {
                                let price = [];
                                let item = (package_info.flight_included ? parseFloat(sorted_arr[i].price_flight) : 0) + parseFloat(sorted_arr[i].price_xml) + parseFloat(sorted_arr[i].price_custom_product);
                                let index = tmp_all_dates.findIndex((el) => {
                                    return el.start_date === sorted_arr[i].start_date && el.end_date === sorted_arr[i].end_date;
                                });
                                tmp_all_dates.map((el) => {
                                    price.push(0);
                                });
                                price[index] = item;
                                tmp_variant[o].airport_list.push({
                                    airports: [],
                                    name: sorted_arr[i].name,
                                    prices: price,
                                    date: tmp_all_dates
                                });
                            }
                        }
                    } else if (tmp_variant[o].variant === sorted_arr[i].variant) {
                        let is_already_in_array = false;
                        for (let k = 0; k < tmp_variant[o].airport_list.length; k++) {
                            if (compareArray(sorted_arr[i].airports, tmp_variant[o].airport_list[k].airports) === true) {
                                is_already_in_array = true;
                                let index = tmp_all_dates.findIndex((el) => {
                                    return el.start_date === sorted_arr[i].start_date && el.end_date === sorted_arr[i].end_date;
                                });
                                let item = (package_info.flight_included ? parseFloat(sorted_arr[i].price_flight) : 0) + parseFloat(sorted_arr[i].price_xml) + parseFloat(sorted_arr[i].price_custom_product);
                                let slice_arr = tmp_variant[o].airport_list[k].prices.slice();
                                slice_arr[index] = item;
                                tmp_variant[o].airport_list[k].prices = slice_arr;
                            }
                        }
                        if (is_already_in_array === false) {
                            let price = [];
                            let item = (package_info.flight_included ? parseFloat(sorted_arr[i].price_flight) : 0) + parseFloat(sorted_arr[i].price_xml) + parseFloat(sorted_arr[i].price_custom_product);
                            let index = tmp_all_dates.findIndex((el) => {
                                return el.start_date === sorted_arr[i].start_date && el.end_date === sorted_arr[i].end_date;
                            });
                            tmp_all_dates.map((el) => {
                                price.push(0);
                            });
                            price[index] = item;
                            tmp_variant[o].airport_list.push({
                                airports: sorted_arr[i].airports,
                                prices: price,
                                date: tmp_all_dates
                            });
                        }
                    }
                }
            }
        }
        if (package_info.flight_included) {
            tmp_variant.map((info, info_index) => {
                info.airport_list = info.airport_list.sort((a, b) => {
                    if (a.airports.length !== 0 && b.airports.length !== 0) {
                        return a.airports[0].id - b.airports[0].id;
                    }
                    else if (a.airports.length === 0 && b.airports.length !== 0) {
                        return 1;
                    } else if (a.airports.length !== 0 && b.airports.length === 0) {
                        return -1;
                    }
                });
                info.airport_list.map((airport, airport_index) => {
                    if (airport.airports !== null && airport.airports.length !== 0) {
                        for (let i = 0; i < airport.airports.length; i++) {
                            let found_airport = tmp_airport_list.find((el) => {
                                return el.id === airport.airports[i].id; 
                            });
                            if (found_airport === undefined) {
                                tmp_airport_list.push(airport.airports[i]);
                            }
                        }
                    } 
                });
            });
        }
        tmp_variant = tmp_variant.sort((a, b) => {
            return a.variant - b.variant;
        });

        let tmpSortData = data.sort((a, b) => {
            let da = a.start_date;
            let db = b.start_date;
            if (da < db) {
                return -1;
            } else if (da > db) {
                return 1;
            } else if (da === db) {
                return a.variant - b.variant;
            }
        });
        isAfter = tmpSortData.find((date) => {
            return moment.utc(date.end_date).isAfter(moment.utc(new Date()))
        });
        setDateIsAfter(isAfter);
        if (isAfter) {
            setNewSortData(tmpSortData);
            setFilterData(tmpSortData);
            setAirportList(tmp_airport_list);
            if (tmp_airport_list.length !== 0) {
                setDepartureAirport(tmp_airport_list[0].id);
            }
            let filter_variant = [];
            tmp_variant.map((variant) => {
                if (package_info.variant_name.find((el) => el.id === variant.variant) !== undefined) {
                    filter_variant.push(variant);
                }
            });
            console.log('filter_variant:', filter_variant);
            setInfo(filter_variant);
            setDates(tmp_all_dates);
        }
    };
    const getVariantName = (nb) => {
        return current_variant = package_info.variant_name.find((variant) => variant.id === nb && variant.version === versionToShow);
        //if (current_variant !== undefined) {
        //return current_variant.name !== undefined && current_variant.name !== "" ? current_variant.name : `${t("itineraryType.variant")} ${current_variant.id}`
        //}
        //return `${t("itineraryType.variant")} ${nb}`
    };
    const handleAirport = (e) => {
        setDepartureAirport(e.target.value);
    };

    // useEffect(() => {
    //     if (package_info !== null) {
    //         getPrice();
    //     }
    // }, []);
    useEffect(() => {
        if ((prices === null || prices.length === 0) && package_info !== null && products.length !== 0 && versionToShow !== null) {
            getPrice();
        }
        if (prices !== null) {
            sortData(prices);
        }
    }, [products, package_info]);
    useEffect(() => {
        if (versionToShow !== null) {
            getPrice();
        }
    }, [versionToShow]);
    useEffect(() => {
        if (prices !== null && variantToShow !== null) {
            let lowest_price = prices.filter(el => el.variant === variantToShow && el.trip_version === versionToShow).reduce((prev, curr) => (parseFloat(prev.price_custom_product) + parseFloat(prev.price_flight) + parseFloat(prev.price_xml)) < (parseFloat(curr.price_custom_product) + parseFloat(curr.price_flight) + parseFloat(curr.price_xml)) ? prev : curr, 0);
            sortData(prices);
            dispatch({ type: "ITINERARY_SET_LOWEST_PRICE", payload: (parseFloat(lowest_price.price_custom_product) + parseFloat(lowest_price.price_flight) + parseFloat(lowest_price.price_xml)) });
        }
    }, [prices]);
    useEffect(() => {
        if (prices !== null && variantToShow !== null) {
            let lowest_price = prices.filter(el => el.variant === variantToShow && el.trip_version === versionToShow).reduce((prev, curr) => (parseFloat(prev.price_custom_product) + parseFloat(prev.price_flight) + parseFloat(prev.price_xml)) < (parseFloat(curr.price_custom_product) + parseFloat(curr.price_flight) + parseFloat(curr.price_xml)) ? prev : curr, 0);
            dispatch({ type: "ITINERARY_SET_LOWEST_PRICE", payload: (parseFloat(lowest_price.price_custom_product) + parseFloat(lowest_price.price_flight) + parseFloat(lowest_price.price_xml)) });
        }
    }, [variantToShow]);
    useEffect(() => {
        console.log('package_info:', package_info);
        //if (package_info.forfait_price && prices === null && priceData !== null) {
        //GetCalendarData(priceData, package_info.flight_included, setPrices);
        //}
        if (priceData !== null && priceData.length !== 0) {
            GetCalendarData(priceData, package_info.flight_included, setPrices);
            dispatch({ type: "ITINERARY_SET_PRICE_DATA", payload: priceData });
        }
    }, [priceData]);
    useEffect(() => {
        if (departureAirport !== "") {
            let tmp_filter_data = newsortData.filter((el) => {
                let found_airport = el.airports.find((airport) => {
                    return airport.id === departureAirport;
                });
                if (found_airport !== undefined) {
                    return el;
                }
            });
            setFilterData(tmp_filter_data);
        }
    }, [departureAirport]);
    useEffect(() => {
        let is_empty = true;
        info.map((el) => {
            if (el.airport_list.length !== 0) {
                is_empty = false;
            }
        });
        setPriceEmpty(is_empty);
    }, [info]);

    console.log('periods:', periods);
    console.log('priceData:', priceData);
    if (dateIsAfter && !priceEmpty) {
        let airport_name = '';
        let current_airport = airportList.find((el) => {
            return el.id === departureAirport;
        });
        if (current_airport !== undefined) {
            airport_name += (current_airport.iata_city.name !== null ? current_airport.iata_city.name : current_airport.iata_city.international_name);
        }
        if (airport_name !== '') {
            airport_name = t("itineraryType.departure_from") + airport_name;
        }
        return (
            <Grid container direction={"column"} spacing={2} className={classes.border}>
                {/* <Grid item container xs style={{ width: "100%" }} justify={"center"}>
                    <Typography variant={"h4"} className={classes.genericText}>{t("global.price")}</Typography>
                </Grid> */}
                {
                    package_info.reference !== null && (
                        <Grid item>
                            <span className={classes.genericText}>{ t("itineraryType.reference") } : { package_info.reference }</span>
                        </Grid>
                    )
                }
                {
                    airportList.length > 0 && (
                        <Grid item sm={6} xs={12}>
                            <TextField
                                id="outlined-select-departure-airport"
                                className={`${classes.formControlRoot}`}
                                select
                                label="Votre aéroport de départ"
                                value={departureAirport}
                                onChange={handleAirport}
                                variant="outlined"
                            >
                                {airportList.map((airport) => (
                                    <MenuItem key={airport.id} value={airport.id}>
                                        {airport.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )
                }
                {
                    !fullScreen && (
                        <Grid item style={{ overflowX: "auto" }}>
                            <TableContainer>
                                <Table size="small" style={{ width: "100%" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontStyle: "italic", fontSize: 10, width: 215, lineHeight: "initial" }}>
                                                <span className={classes.genericText}>{ t("itineraryType.price_ttc_from_per_pers") } <br/> { t("itineraryType.base_from_double_room") }</span>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container direction={"column"}>
                                                    <Grid item container direction={"row"} justify={"center"} alignItems={"center"}>
                                                        {
                                                            airport_name !== "" && (
                                                                <Grid item>
                                                                    <span className={`${classes.bold} ${classes.genericText} ${classes.font16}`}> { airport_name } </span>
                                                                </Grid>
                                                            )
                                                        }
                                                    </Grid>
                                                    <Grid item container direction={"row"} justify={"center"} alignItems={"center"}>
                                                        {
                                                            info.map((info, info_index) => {
                                                                let variant = getVariantName(info.variant);
                                                                if (variant !== undefined) {
                                                                    return (
                                                                        <Grid item xs key={info_index} style={{ textAlign: "center" }}>
                                                                            <LocaleTextVariant localization={variant.name} default_text={variant.name !== undefined && typeof variant.name === 'string' && variant.name !== "" ? variant.name : `${t("itineraryType.variant")} ${variant.id}`}/>
                                                                            {/*<span className={`${classes.bold} ${classes.genericText} ${classes.font16}`} > { (variant_name === null || variant_name === "") ? t("itineraryType.variant") + " " + info.variant : variant_name } </span> */}
                                                                        </Grid>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            dates.map((date, index_date) => {
                                                if (moment.utc(date.end_date).isAfter(moment.utc(new Date()))) {
                                                    return (
                                                        <TableRow key={index_date} >
                                                            <TableCell>
                                                                { date.start_date !== date.end_date ? moment.utc(date.start_date).format("DD/MM/YYYY") + " → " + moment.utc(date.end_date).format("DD/MM/YYYY") : moment.utc(date.start_date).format("DD/MM/YYYY") } 
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: "center" }}>
                                                                <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                                                                    {
                                                                        current_airport !== undefined && info.map((tmp_info, info_index) => {
                                                                            let found_airport = tmp_info.airport_list.find((el) => {
                                                                                return el.airports.find((airport) => {
                                                                                    return airport.id === departureAirport;
                                                                                });
                                                                            });
                                                                            return (
                                                                                <Grid item xs key={info_index}>
                                                                                    <span>{ Math.round(found_airport.prices[index_date]) } { currency_symbol }</span>
                                                                                </Grid>
                                                                            );
                                                                        })
                                                                    }
                                                                    {
                                                                        current_airport === undefined && info.map((tmp_info, info_index) => {
                                                                            return (
                                                                                <Fragment key={info_index}>
                                                                                    {
                                                                                        tmp_info.airport_list.map((airport, airport_index) => {
                                                                                            return (
                                                                                                <Grid item xs key={airport_index}>
                                                                                                    {
                                                                                                        airport.prices[index_date] !== 0 && (
                                                                                                            <span>{ Math.round(airport.prices[index_date]) } { currency_symbol }</span>
                                                                                                        ) 
                                                                                                    }
                                                                                                    {
                                                                                                        airport.prices[index_date] === 0 && (
                                                                                                            <span>-</span>
                                                                                                        ) 
                                                                                                    }
                                                                                                </Grid>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </Fragment>
                                                                            );
                                                                        })
                                                                    }
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )
                }
                {
                    fullScreen && (
                        <Grid item container direction={"column"} spacing={2}>
                            <Grid item className={classes.genericText} style={{ fontStyle: "italic", fontSize: 10 }}>{ t("itineraryType.price_ttc_from_per_pers") } <br/> { t("itineraryType.base_from_double_room") }</Grid>
                            {
                                filterData.map((data, data_index) => {
                                    if (moment.utc(data.end_date).isAfter(moment.utc(new Date()))) {
                                        let variant = getVariantName(data.variant);
                                        let price = (package_info.flight_included ? parseFloat(data.price_flight) : 0) + parseFloat(data.price_xml) + parseFloat(data.price_custom_product);
                                        return (
                                            <Grid item container direction={"row"} key={data_index} style={{ border: "1px solid", color: "#0000008A", borderBottom: (data_index === filterData.length - 1) ? "1px solid" : "none" }}>
                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                    <span className={`${classes.bold} ${classes.genericText} ${classes.font16}`} > { moment.utc(data.start_date).format("DD/MM/YYYY") + " → " + moment.utc(data.end_date).format("DD/MM/YYYY") }</span>
                                                </Grid>
                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                    <LocaleTextVariant localization={variant.name} default_text={variant.name !== undefined && typeof variant.name === 'string' && variant.name !== "" ? variant.name : `${t("itineraryType.variant")} ${variant.id}`}/>
                                                    {/*<span className={`${classes.bold} ${classes.genericText} ${classes.font16}`} > { (variant_name === null || variant_name === "") ? t("itineraryType.variant") + " " + data.variant : variant_name } </span> */}
                                                </Grid>
                                                <Grid item xs={8} style={{ textAlign: "left" }}>
                                                    {
                                                        current_airport !== undefined && (
                                                            <span className={`${classes.bold} ${classes.genericText} ${classes.font16}`} > { airport_name } </span>
                                                        )
                                                    }
                                                </Grid>
                                                <Grid item xs={4} style={{ textAlign: "right" }}>
                                                    <span className={`${classes.bold} ${classes.genericText} ${classes.font16}`} > {new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency_iso, minimumFractionDigits: 0 }).format(Math.ceil(price)) } </span>
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                })
                            }
                        </Grid>
                    )
                }
                {
                    package_info.condition !== null && (
                        <Grid item>
                            <div className={`${classes.genericText} ${classes.justify} ${classes.font10}`}>{package_info.condition}</div>
                        </Grid>
                    )
                }
            </Grid>
        );
    }
};
export default React.memo(PriceCalendar);
