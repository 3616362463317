import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import CheckResponse from "../../Flight/FlightSelected/Functions/CheckResponse";
import { EditItemToBook } from "../../../Actions/Cart";
import { AddRefreshedProduct } from "../../../Actions/Booking";

import axios from "axios";

export default (product_to_refresh, end_date, dispatch, mode, provider) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        if (product_to_refresh.is_manual) {
            let product_id = product_to_refresh.product_id !== undefined ? product_to_refresh.product_id : product_to_refresh.id;
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${product_id}/`
            }).then(function (response) {
                let temp_product = Object.assign({}, response.data);
                temp_product.is_manual = true;
                dispatch(EditItemToBook(temp_product));
                if (mode === 'BookingCard') {
                    dispatch(AddRefreshedProduct());
                }
            });
        } else {
            switch (product_to_refresh.product_type) {
                case 0:
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${product_to_refresh.id}/`
                    }).then(function (response) {
                        dispatch(EditItemToBook(response.data));
                        if (mode === 'BookingCard') {
                            dispatch(AddRefreshedProduct());
                        }
                    });
                    break;
                case 2:
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${product_to_refresh.id}/`
                    }).then(function (response) {
                        dispatch(EditItemToBook(response.data));
                        if (mode === 'BookingCard') {
                            dispatch(AddRefreshedProduct());
                        }
                    });
                    break;
                case 4:
                    if (product_to_refresh.is_custom) {
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${product_to_refresh.id}/`
                        }).then(function (response) {
                            dispatch(EditItemToBook(response.data));
                            if (mode === 'BookingCard') {
                                dispatch(AddRefreshedProduct());
                            }
                        });
                    } else {
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/transfers/${product_to_refresh.id}/`
                        }).then(function (response) {
                            dispatch(EditItemToBook(response.data));
                            if (mode === 'BookingCard') {
                                dispatch(AddRefreshedProduct());
                            }
                        });
                    }
                    break;
                case 6:
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${product_to_refresh.id}/`
                    }).then(function (response) {
                        let arr = CheckResponse([response.data], end_date);
                        dispatch(EditItemToBook(arr[0]));
                        if (mode === 'BookingCard') {
                            dispatch(AddRefreshedProduct());
                        }
                    });
                    break;
                case 11:
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${product_to_refresh.id}/`
                    }).then(function (response) {
                        dispatch(EditItemToBook(response.data));
                        if (mode === 'BookingCard') {
                            dispatch(AddRefreshedProduct());
                        }
                    });
                    break;
                case 12:
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${product_to_refresh.id}/`
                    }).then(function (response) {
                        dispatch(EditItemToBook(response.data));
                        if (mode === 'BookingCard') {
                            dispatch(AddRefreshedProduct());
                        }
                    });
                    break;
                case 19:
                case 8:
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${provider?.code === "gritchen" ? 'insurance' : 'assistance'}/${product_to_refresh.id}/`
                    }).then(function (response) {
                        dispatch(EditItemToBook(response.data));
                        if (mode === 'BookingCard') {
                            dispatch(AddRefreshedProduct());
                        }
                    });
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/insurance/${product_to_refresh.id}/`
                    }).then(function (response) {
                        dispatch(EditItemToBook(response.data));
                        if (mode === 'BookingCard') {
                            dispatch(AddRefreshedProduct());
                        }
                    });
                    break;
            }
        }
    }
};
