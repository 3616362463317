import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCartProducts } from "../../Itinerary/network/cartProducts";
import { ManualProduct } from "../../../Reducers/objects/manualProduct";
import { AppState } from "../../../Reducers/Reducers";
import { useGetPrice } from "../../CartMaterial/utils/getPrice";
import { TableCell, TableRow } from "@mui/material";
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId";
import GetProvider from "../../Common/Functions/GetProvider";
import { Currency } from "../../../Reducers/objects/currency";
import ConvertPrice from "../../Cart/Functions/Manual/ConvertPrice";
import { isProductSamePackage } from "../../CartMaterial/utils/isProductSamePackage";
import { isNumber } from "lodash";
import { makeStyles } from "@material-ui/core";
import HasPermission from "../../Common/Functions/HasPermission";
import { BookingStatus } from "../../Itinerary/objects/bookingStatus";
import { usePackagePrice } from "../../CartMaterial/utils/packagePrice.ts";
import clsx from "clsx";

const useRowStyles = makeStyles({
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            padding: "10px 0px 10px 16px !important",
            fontSize: 12
        }
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    green: {
        color: "#4caf50"
    },
    orange: {
        color: "#ff9800"
    },
    black: {
        color: "black"
    },
    red: {
        color: "#f44336"
    },
    bold: {
        fontWeight: "bold"
    },
    hiddenProduct: {
        backgroundColor: "#eeee",
    }
});

type Props = ({
    cart: {
        [key: string]: Omit<
            ReturnType<typeof useCartProducts> & {manualProducts: ManualProduct[]},
            'trains' | 'ferries' | 'cruises' | 'insurances'
        >
    }
})
export function RenderPackage(props: Props): JSX.Element {
    return (
        <>
            {
                Object.keys(props.cart).map((key) => {
                    const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
                    const stackInfoId = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;
                    if (props.cart[key]) {
                        return (
                            <Row
                                key={key}
                                stackNumber={stackNumber}
                                stackInfoId={stackInfoId}
                            />
                        );
                    }
                    return null;
                })
            }
        </>
    );
}

type RowProps = {
    stackNumber: number | null,
    stackInfoId: number | null
}

function Row(props: RowProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const currency = useSelector((state: AppState) => state.trip.currency);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const user = useSelector((state: AppState) => state.user.user);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const big_cart = useSelector(store => store.menu.big_cart);
    const finance_charges = useSelector(store => store.menu.finance_charges);

    const getPrice = useGetPrice();

    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    
    const owner_id = useState(user ? user.client : null);
    const [price, setPrice] = useState(null);
    const prices = usePackagePrice({ stackNumber: props.stackNumber, stackInfoId: props.stackInfoId });
    console.log('prices:', prices);
    const flightPrices = trip?.prices_flight?.filter((item) => {
        return isProductSamePackage(
            item,
            {
                stack_number: props.stackNumber,
                stack_info_id: props.stackInfoId
            }
        );
    }) ?? [];
    const terrestrialPrices = trip?.prices_terrestrial?.filter((item) => {
        return isProductSamePackage(
            item,
            {
                stack_number: props.stackNumber,
                stack_info_id: props.stackInfoId
            }
        );
    }) ?? [];
    const filtered_big_cart = big_cart?.filter((item) => {
        return item.stack_number === props.stackNumber && item.stack_info_id === props.stackInfoId;
    });
    const stack_info = trip?.stack_info?.filter((item) => {
        return item.stack_number === props.stackNumber;
    }) ?? [];
    useEffect(() => {
        let purchase_price_flight = 0;
        let selling_price_flight = 0;
        let purchase_price_terrestrial = 0;
        let selling_price_terrestrial = 0;
        let currency_buy_flight = null;
        let currency_sell_flight = null;
        let converted_price_flight = 0;
        let currency_buy_terrestrial = null;
        let currency_sell_terrestrial = null;
        let converted_price_terrestrial = 0;
        let factor_used_terrestrial = null;
        let factor_used_flight = null;
        let tmp_provider = null;
        let charge = filtered_big_cart.reduce((a, b) => a + b.charge, 0);
        let origin_charge = filtered_big_cart.reduce((a, b) => a + b.origin_charge, 0);
        let purchase = filtered_big_cart.reduce((a, b) => a + b.purchase, 0);
        let find_charge = finance_charges.find((item) => item.stack_number === props.stackNumber);
        if (find_charge !== undefined) {
            charge = find_charge.amount;
            origin_charge = find_charge.origin_amount;
            purchase = find_charge.amount;
        }
        if (flightPrices !== undefined && flightPrices.length !== 0) {
            purchase_price_flight = parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, true)].purchase_price);
            selling_price_flight = parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, quotation_code !== "americavoyages")].selling_price);
            currency_buy_flight = currency_list.find((currency: Currency) => {
                return currency.id === (find_charge !== undefined ? find_charge.origin_currency.id : flightPrices[GetPricesId(flightPrices, owner_id, user, true)].purchase_currency); 
            });
            currency_sell_flight = currency_list.find((currency: Currency) => {
                return currency.id === (find_charge !== undefined ? find_charge.currency.id : flightPrices[GetPricesId(flightPrices, owner_id, user, quotation_code !== "americavoyages")].selling_currency); 
            });
            converted_price_flight = find_charge !== undefined ? parseFloat(ConvertPrice(origin_charge, currency, selling_price_flight, parseFloat(find_charge.factor_used))) : parseFloat(ConvertPrice(purchase_price_flight, currency, currency_buy_flight, parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, true)].factor_used)));
            factor_used_flight = find_charge !== undefined ? find_charge.factor_used : parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, true)].factor_used).toFixed(2);
        }
        if (terrestrialPrices !== undefined && terrestrialPrices.length !== 0) {
            purchase_price_terrestrial = parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].purchase_price);
            selling_price_terrestrial = parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, quotation_code !== "americavoyages")].selling_price);
            currency_buy_terrestrial = currency_list.find((currency: Currency) => {
                return currency.id === (find_charge !== undefined ? find_charge.origin_currency.id : terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].purchase_currency); 
            });
            currency_sell_terrestrial = currency_list.find((currency: Currency) => {
                return currency.id === (find_charge !== undefined ? find_charge.currency.id : terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, quotation_code !== "americavoyages")].selling_currency); 
            });
            converted_price_terrestrial = find_charge !== undefined ? parseFloat(ConvertPrice(origin_charge, currency, currency_buy_terrestrial, parseFloat(find_charge.factor_used))) : parseFloat(ConvertPrice(purchase_price_terrestrial, currency, currency_buy_terrestrial, parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].factor_used)));
            factor_used_terrestrial = find_charge !== undefined ? find_charge.factor_used : parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].factor_used).toFixed(2);
            tmp_provider = GetProvider(providers, terrestrialPrices[0].provider);
            if (tmp_provider === null) {
                tmp_provider = GetProvider(manual_providers, terrestrialPrices[0].provider);
            }
        }
        const total = {
            currency_buy_flight: currency_buy_flight,
            currency_sell_flight: currency_sell_flight,
            converted_price_flight: converted_price_flight,
            currency_buy_terrestrial: currency_buy_terrestrial,
            currency_sell_terrestrial: currency_sell_terrestrial,
            converted_price_terrestrial: converted_price_terrestrial,
            provider: tmp_provider !== null ? tmp_provider.provider.name : '-',
            selling_price_terrestrial: selling_price_terrestrial,
            selling_price_flight: selling_price_flight,
            purchase_price_flight: purchase_price_flight,
            purchase_price_terrestrial: purchase_price_terrestrial,
            factor_used_flight: factor_used_flight,
            factor_used_terrestrial: factor_used_terrestrial,
            charge: charge,
            origin_charge: origin_charge,
            purchase: purchase
        };
        setPrice(total);
    }, [trip, getPrice, props.stackNumber, providers, manual_providers]);
    if (price !== null) {
        return (
            <>
            {
                terrestrialPrices.length !== 0 && price.selling_price_terrestrial !== 0 && price.purchase_price_terrestrial !== 0 &&
                    <DisplayRow price={price} stackNumber={props.stackNumber} type={'terrestrial'} stack_info={stack_info.length !== 0 ? stack_info[0] : null}/>   
            }
            {
                flightPrices.length !== 0 && price.selling_price_flight !== 0 && price.purchase_price_flight !== 0 &&
                    <DisplayRow price={price} stackNumber={props.stackNumber} type={'flight'} stack_info={stack_info.length !== 0 ? stack_info[0] : null}/>   
            }
            </>
        );
    } 
    return null;
}

type DisplayRowProps = {
    price: {
        currency_buy_flight: Currency,
        currency_sell_flight: Currency,
        converted_price_flight: number,
        currency_buy_terrestrial: Currency,
        currency_sell_terrestrial: Currency,
        converted_price_terrestrial: number,
        provider: string,
        selling_price_terrestrial: number,
        selling_price_flight: number,
        purchase_price_flight: number,
        purchase_price_terrestrial: number,
        factor_used_flight: null|number
        factor_used_terrestrial: null|number,
        charge: number,
        origin_charge: number,
        purchase: number
    },
    stack_info: {
        booking_process_state: string | null,
        booking_status: BookingStatus,
        is_available: boolean,
        stack_number: number
    } | null,
    type: string,
    stackNumber: number
}
function DisplayRow(props: DisplayRowProps): JSX.Element {
    const { price, stackNumber, type, stack_info } = props;
    const { t } = useTranslation();
    const classes = useRowStyles();

    const language = useSelector((state: AppState) => state.header.tmp_language);
    const currency = useSelector((state: AppState) => state.trip.currency);
    const finance_charges = useSelector(store => store.menu.finance_charges);
    const permissions = useSelector(store => store.user.permissions);
    const user = useSelector(store => store.user.user);
    console.log('stack_info:', stack_info);
    let booking_ref = null;
    let booking_status = "";
    if (stack_info !== undefined && stack_info !== null && stack_info.booking_status !== undefined && stack_info.booking_status !== null) {
        booking_ref = stack_info.booking_status.item_reference;
        booking_status = (stack_info.booking_status.status_booking).toLowerCase();
    }
    return (
        <TableRow sx={{
            "& .mui-jss-MuiTableCell-root": {
                padding: "10px 0px 10px 16px !important",
                fontSize: '12px'
            }
        }}>
            <TableCell align={"left"}>-</TableCell>
            <TableCell align={"left"}>
                <span style={{ fontSize: 12, color: "#0000008A" }}>{t<string>('providers.package')}</span>
            </TableCell>
            <TableCell align={"left"}>
                <span style={{ fontSize: 12, color: "#0000008A" }}>{price.provider}</span>
            </TableCell>
            <TableCell align={"left"}>
                <span style={{ fontSize: 12, color: "#0000008A" }}>
                    {
                        t<string>(
                            'cart-material.cart-construction-package-name',
                            { name: stackNumber }
                        ) + ' - ' + (type === 'terrestrial' ? t<string>('cart-material.terrestrial') : t<string>('cart-material.flight'))
                    }
                </span>
            </TableCell>
            <TableCell align={"left"}>-</TableCell>
            <TableCell align={"left"}>-</TableCell>
            {
                (finance_charges.length === 0 || !HasPermission(permissions, "view_accountingbook", user)) &&
                <>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#0000008A" }}>{new Intl.NumberFormat(language).format(type === 'terrestrial' ? price.purchase_price_terrestrial.toFixed(2) : price.purchase_price_flight.toFixed(2))}{type === 'terrestrial' ? price.currency_buy_terrestrial.symbol : price.currency_buy_flight.symbol}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#0000008A" }}>{type === 'terrestrial' ? price.factor_used_terrestrial : price.factor_used_flight}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#0000008A" }}>{new Intl.NumberFormat(language).format(type === 'terrestrial' ? price.converted_price_terrestrial.toFixed(2) : price.converted_price_flight.toFixed(2))}{type === 'terrestrial' ? price.currency_sell_terrestrial.symbol : price.currency_sell_flight.symbol}</span>
                    </TableCell>
                </>
            }
            {
                finance_charges.length !== 0 && HasPermission(permissions, "view_accountingbook", user) &&
                <>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#0000008A" }}>{new Intl.NumberFormat(language).format(price.origin_charge)}{type === 'terrestrial' ? price.currency_buy_terrestrial.symbol : price.currency_buy_flight.symbol}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#0000008A" }}>{type === 'terrestrial' ? parseFloat(price.factor_used_terrestrial).toFixed(2) : parseFloat(price.factor_used_flight).toFixed(2)}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#0000008A" }}>{new Intl.NumberFormat(language).format(price.charge)}{type === 'terrestrial' ? price.currency_sell_terrestrial.symbol : price.currency_sell_flight.symbol}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#0000008A" }}>{new Intl.NumberFormat(language).format(price.purchase)}{type === 'terrestrial' ? price.currency_buy_terrestrial.symbol : price.currency_buy_flight.symbol}</span>
                    </TableCell>
                </>
            }
            <TableCell align={"left"}>
                <span style={{ fontSize: 12, color: "#0000008A" }}>{type === 'terrestrial' ? new Intl.NumberFormat(language).format(price.selling_price_terrestrial.toFixed(2)) : new Intl.NumberFormat(language).format(price.selling_price_flight.toFixed(2))}{type === 'terrestrial' ? price.currency_sell_terrestrial.symbol : price.currency_sell_flight.symbol}</span>
            </TableCell>
            <TableCell align={"left"}></TableCell>
        </TableRow>
    );
}
