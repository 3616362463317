//Dependencies
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
//Core
import { Alert, AlertTitle, Box, Button, Grid, Stack } from '@mui/material';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

import DOMPurify from "dompurify";

//Common
import { CartCancellationRules } from './CartCancellationRules';
import { isProductSamePackage } from './utils/isProductSamePackage';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import { AppState } from '../../Reducers/Reducers';

type Props = {
    stackNumber: number | null,
    stackInfoId: number | null
}

export function CartStackedCancellationRules(props: Props): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const data_trip = useSelector((state: AppState) => state.trip.data_trip);
    const [edit, setEdit] = useState(false);
    const [noRefund, setNoRefund] = useState<boolean>(false);
    const [cancellationRules, setCancellationRules] = useState<string | null>(null);
    const info = useMemo(() => {
        return data_trip?.stack_info?.find((item) => {
            return isProductSamePackage(
                {
                    stack_number: item.stack_number,
                    stack_info_id: item.is_custom && !props.stackInfoId ?
                        props.stackInfoId :
                        item.id
                },
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            );
        });
    }, [
        props.stackNumber,
        props.stackInfoId,
        data_trip
    ]);

    const onEdit = () => {
        setEdit(true);
    };

    const onNoRefundChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        if (event.target.value === 'true') {
            setCancellationRules(t('cart-material.not-cancellable'));
        } else {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/get_cancellation_text/`
            }).then(response => {
                setCancellationRules(response.data.cancellation_text);
            }).catch((error) => {
                console.error(error);
            });
        }
        setNoRefund(event.target.value === 'true');
    };

    const onCancellationRulesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCancellationRules(event.target.value);
    };

    const onValidateManualCancellationRules = () => {
        const { headers } = CheckBeforeRequest();
        const stackInfo = (data_trip?.stack_info ?? []).filter((item) => {
            return !isProductSamePackage(
                {
                    stack_number: item.stack_number,
                    stack_info_id: item.is_custom && !props.stackInfoId ?
                        props.stackInfoId :
                        item.id
                },
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            );
        });
        const correspondingInfo = data_trip?.stack_info?.find((item) => {
            return isProductSamePackage(
                {
                    stack_number: item.stack_number,
                    stack_info_id: item.is_custom && !props.stackInfoId ?
                        props.stackInfoId :
                        item.id
                },
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            );
        });
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/`,
            data: {
                stack_info: (stackInfo as unknown[]).concat([
                    {
                        id: correspondingInfo?.id,
                        stack_number: props.stackNumber,
                        no_refund: noRefund,
                        cancel_condition_text: cancellationRules ?? ''
                    }
                ])
            }
        }).then(response => {
            dispatch({ type: 'TRIP_SET_DATA_TRIP', payload: { data_trip: response.data } });
            setEdit(false);
        }).catch(error => {
            console.log(error);
        });
    };

    const onCancelManualCancellationRules = () => {
        setNoRefund(!!info?.no_refund);
        setCancellationRules(info?.cancel_condition_text ?? '');
        setEdit(false);
    };

    useEffect(() => {
        if (info) {
            setCancellationRules(info.cancel_condition_text);
        }
    }, [info]);

    return (
        <CartCancellationRules
            type="package"
            id={null}
            open={edit}
            text={info?.cancel_condition_text ?? ''}
            moved_with_recalc_cancel_condition_text={false}
            noRefund={info?.no_refund ?? false}
            isCustom={false}
            onConfirmCancelConditionRecalc={() => null}
            onToggle={() => setEdit((state) => !state)}
            onValidate={onValidateManualCancellationRules}
        />
    );
}

const useStyles = makeStyles({
    updateButton: {
        border: '1px solid'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelButton: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    }
});
